import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { DashboardLayout } from './components/DashboardLayout/DashboardLayout';
import { ShaftView } from '../shaft/views/ShaftView';
import { HeadView } from '../head/views/HeadView';
import { GripView } from '../grip/views/GripView';
import { SwingView } from '../swing/views/SwingView';
import { ShaftConstantView } from '../shaftConstant/views/ShaftConstantView';
import { UsersView } from '../user/views/UsersView/UsersView';
import { AccountView } from '../auth/views/AccountView/AccountView';
import {
  ACCOUNT,
  SHAFTS,
  HEADS,
  GRIPS,
  SWINGS,
  USERS,
  SHAFT_CONSTANTS,
  SESSIONS,
} from './const/routes';
import { SessionsView } from '../session/views/SessionsView';

export const App: FC = () => (
  <DashboardLayout>
    <Switch>
      <Route path={SHAFTS} component={ShaftView} exact />
      <Route path={HEADS} component={HeadView} exact />
      <Route path={GRIPS} component={GripView} exact />
      <Route path={SWINGS} component={SwingView} exact />
      <Route path={SESSIONS} component={SessionsView} exact />
      <Route path={SHAFT_CONSTANTS} component={ShaftConstantView} exact />
      <Route path={USERS} component={UsersView} exact />
      <Route path={ACCOUNT} component={AccountView} />
    </Switch>
  </DashboardLayout>
);
