import { createAsyncThunk } from '@reduxjs/toolkit';
import { Grips, App } from '@caddyshack/common';
import withError from '../../app/utils/with-thunk-error';

import {
  fetchGrips as fetchGripsRequest,
  exportGrips as exportGripsRequest,
  deleteGrips as deleteGripsRequest,
  editGrip as editGripRequest,
  importGripsFile as importGripsFileRequest,
} from '../api/grip.api';
import { openSuccessNotification } from '../../app/store/notifications/notifications.slice';
import {
  mapGripModelToGripDto,
  mapGripSearchQueryDto,
} from '../api/grip.mapper';
import { IGripModel } from '../model/Grip';

export const fetchGrips = createAsyncThunk(
  'grips/fetchGrips',
  withError(
    async (
      searchQuery: Grips.SearchQuery
    ): Promise<App.Page<Grips.GripDto[]>> => {
      return fetchGripsRequest(mapGripSearchQueryDto(searchQuery));
    }
  )
);

export const exportGrips = createAsyncThunk(
  'grips/export',
  withError(
    async (): Promise<void> => {
      return exportGripsRequest();
    }
  )
);

export const deleteGrips = createAsyncThunk(
  'grips/delete',
  withError(
    async (ids: string[], { dispatch, getState }): Promise<string> => {
      const response = await deleteGripsRequest(ids);
      const currentFilters = getState().grip.grips.data.filters;
      dispatch(openSuccessNotification('Grips has been deleted'));
      dispatch(fetchGrips(currentFilters));

      return response;
    }
  )
);

export const editGrip = createAsyncThunk(
  'grips/edit',
  withError(
    async (grip: IGripModel, { dispatch }): Promise<Grips.GripDto> => {
      const response = await editGripRequest(mapGripModelToGripDto(grip));
      dispatch(openSuccessNotification('Grip has been updated'));

      return response;
    }
  )
);

export const importGripsFile = createAsyncThunk(
  'grips/import',
  withError(
    async (file: File, { dispatch, getState }): Promise<void> => {
      const currentFilters = getState().grip.grips.data.filters;
      const formData = new FormData();
      formData.append('file', file);
      const data = await importGripsFileRequest(formData);

      dispatch(
        openSuccessNotification(
          `${data.created} grips imported and ${data.updated} grips updated`
        )
      );
      dispatch(fetchGrips(currentFilters));
    }
  )
);
