import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ShaftConstant } from '@caddyshack/common';

import { AppDispatch } from '../../../app/store';
import {
  fetchShaftConstants,
  deleteShaftConstants,
  editShaftConstant,
} from '../../store/shaftConstant.actions';
import {
  selectIsFetchingShaftConstantsPending,
  selectShaftConstantsPagination,
  selectShaftConstantsFilters,
  selectMappedShaftConstants,
} from '../../store/shaftConstant.selector';
import { ShaftConstantsTable } from '../../components/ShaftConstantsTable/ShaftConstantsTable';

export const ShaftConstantsTableContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const shaftConstants = useSelector(selectMappedShaftConstants);
  const shaftConstantsPagination = useSelector(selectShaftConstantsPagination);
  const shaftConstantsFilters = useSelector(selectShaftConstantsFilters);
  const isPending = useSelector(selectIsFetchingShaftConstantsPending);

  useEffect(() => {
    dispatch(
      fetchShaftConstants({
        pageSize: 50,
        page: 0,
      })
    );
  }, [dispatch]);

  const fetchShaftConstantsWithQuery = (
    searchQuery: ShaftConstant.SearchQuery
  ) => {
    dispatch(
      fetchShaftConstants({
        ...shaftConstantsFilters,
        ...searchQuery,
      })
    );
  };

  const onDelete = (ids: string[]) => dispatch(deleteShaftConstants(ids));

  const onUpdateShaftConstant = (id: string, value: number) =>
    dispatch(editShaftConstant({ id, value }));

  return (
    <ShaftConstantsTable
      shaftConstants={shaftConstants}
      isPending={isPending}
      pagination={shaftConstantsPagination}
      sorting={shaftConstantsFilters?.sort}
      filter={shaftConstantsFilters?.name}
      onQueryChange={fetchShaftConstantsWithQuery}
      onDelete={onDelete}
      onUpdateShaftConstant={onUpdateShaftConstant}
    />
  );
};
