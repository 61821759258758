import React, { FC } from 'react';

import { StyledContainer } from './HeadsToolbar.style';
import TableSearchInput from '../../../app/components/Table/TableSearchInput/TableSearchInput';
import {
  TableToolbarButton,
  TableToolbarButtonsWrapper,
} from '../../../app/components/Table/Table.style';
import { Uploader } from '../../../app/components/Uploader/Uploader';

interface IHeadsToolbar {
  onSearch: (searchTerm: string) => void;
  onExport: () => void;
  onImport: (file: File) => void;
  isExportPending: boolean;
  isPending: boolean;
  filter?: string;
}

const HeadsToolbar: FC<IHeadsToolbar> = ({
  onSearch,
  onExport,
  isExportPending,
  isPending,
  filter,
  onImport,
}) => {
  return (
    <StyledContainer>
      <TableSearchInput
        onSearch={onSearch}
        label="Search heads"
        isPending={isPending}
        filter={filter}
        tooltipLabel="You can search by model, manufacturer and SKU"
      />
      <TableToolbarButtonsWrapper>
        <TableToolbarButton
          color="primary"
          variant="contained"
          onClick={onExport}
          pending={isExportPending}
        >
          Export All
        </TableToolbarButton>
        <Uploader onChange={onImport} isPending={isPending} />
      </TableToolbarButtonsWrapper>
    </StyledContainer>
  );
};

export default HeadsToolbar;
