import { createAsyncThunk } from '@reduxjs/toolkit';
import { ShaftConstant, App } from '@caddyshack/common';
import withError from '../../app/utils/with-thunk-error';
import { openSuccessNotification } from '../../app/store/notifications/notifications.slice';

import {
  fetchShaftConstant as fetchShaftConstantRequest,
  deleteShaftConstants as deleteShaftConstantsRequest,
  editShaftConstant as editShaftConstantRequest,
} from '../api/shaftConstant';

export const fetchShaftConstants = createAsyncThunk(
  'shaftConstants/fetchShaftConstants',
  withError(
    async (
      searchQuery?: ShaftConstant.SearchQuery
    ): Promise<App.Page<ShaftConstant.ShaftConstantDto[]>> => {
      return fetchShaftConstantRequest(searchQuery);
    }
  )
);

export const deleteShaftConstants = createAsyncThunk(
  'shaftConstants/delete',
  withError(
    async (ids: string[], { dispatch, getState }): Promise<string> => {
      const response = await deleteShaftConstantsRequest(ids);
      const currentFilters = getState().shaftConstant.shaftConstants.data
        .filters;
      dispatch(openSuccessNotification('Shaft constants have been deleted'));
      dispatch(fetchShaftConstants(currentFilters));

      return response;
    }
  )
);

export const editShaftConstant = createAsyncThunk(
  'shaftConstants/edit',
  withError(
    async (
      payload: ShaftConstant.UpdateShaftConstantDto,
      { dispatch, getState }
    ) => {
      const response = await editShaftConstantRequest(payload);
      const currentFilters = getState().shaftConstant.shaftConstants.data
        .filters;

      dispatch(openSuccessNotification('Shaft constant has been updated'));
      dispatch(fetchShaftConstants(currentFilters));

      return response;
    }
  )
);
