import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar, SnackbarKey } from 'notistack';
import { removeNotification } from '../../store/notifications/notifications.slice';
import { INotification } from '../../models/Notification';
import { selectNotificationsState } from '../../store/notifications/notifications.selector';

let displayed: SnackbarKey[] = [];

export const ConnectedNotifications: FC = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectNotificationsState);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  useEffect(() => {
    notifications.forEach(({ key, message, options = {} }: INotification) => {
      if (displayed.includes(key)) return;

      enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (node, myKey) => {
          dispatch(removeNotification(myKey));
          removeDisplayed(myKey);
        },
      });

      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};
