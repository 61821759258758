import React, { FC } from 'react';

import TableSearchInput from '../../../app/components/Table/TableSearchInput/TableSearchInput';

import { StyledContainer } from './ShaftConstantsToolbar.style';

interface IShaftConstantsToolbar {
  onSearch: (searchTerm: string) => void;
  isPending: boolean;
  filter?: string;
}

const ShaftConstantsToolbar: FC<IShaftConstantsToolbar> = ({
  onSearch,
  isPending,
  filter,
}) => {
  return (
    <StyledContainer>
      <TableSearchInput
        onSearch={onSearch}
        label="Search shaft constants"
        isPending={isPending}
        filter={filter}
        tooltipLabel="You can search constants by name"
      />
    </StyledContainer>
  );
};

export default ShaftConstantsToolbar;
