import styled from 'styled-components';
import {
  DialogActions,
  DialogContent,
  FormLabel,
  FormControlLabel,
  FormControl,
  Typography,
  FormGroup,
} from '@material-ui/core';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledFormControl = styled(FormControl)`
  margin-top: ${({ theme }) => `${theme.spacing(6)}px`};
  width: 140px;
`;

export const StyledDateFormControl = styled(StyledFormControl)`
  margin-top: ${({ theme }) => `${theme.spacing(3)}px`};
  display: flex;
  flex: 1;
`;

export const StyledFormGroup = styled(FormGroup)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledFormLabel = styled(FormLabel)`
  color: ${({ theme }) => theme.palette.text.primary};
  text-transform: uppercase;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 14px;
`;

export const StyledDateFormLabel = styled(StyledFormLabel)`
  margin-bottom: 20px;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  color: ${({ theme }) => theme.palette.text.tertiary};

  .MuiFormControlLabel-label {
    font-size: 14px;
  }
`;

export const StyledModalContent = styled(DialogContent)`
  padding: ${({ theme }) => `${theme.spacing(3)}px`};
  padding-top: 0;
`;

export const StyledModalActions = styled(DialogActions)`
  padding: ${({ theme }) => `${theme.spacing(3)}px`};
`;

export const StyledTypography = styled(Typography)`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.tertiary};
  margin-left: 20px;
  margin-right: 20px;
`;
