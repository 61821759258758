import * as Yup from 'yup';

export const phoneNumberFormat = '(###) ###-####';
export const phoneNumberFormatRegex = /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/;

const phoneMessages = {
  notMatched: `Phone number is not valid. Required format: ${phoneNumberFormat}`,
};

export const profileFormValidationSchema = Yup.object().shape({
  phone: Yup.string().matches(phoneNumberFormatRegex, phoneMessages.notMatched),
});
