import { Grips, App } from '@caddyshack/common';
import { GripDto } from '@caddyshack/common/dist/grips';
import FileSaver from 'file-saver';
import { authApiClient } from '../../app/api/authApi.client';

export const fetchGrips = async (
  searchQuery?: Grips.SearchQuery
): Promise<App.Page<Grips.GripDto[]>> => {
  try {
    const response = await authApiClient.post('/grips/search', searchQuery);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const exportGrips = async (): Promise<void> => {
  try {
    const { data } = await authApiClient.get('/grips/export', {
      responseType: 'arraybuffer',
    });

    const xlsxData = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    FileSaver.saveAs(xlsxData, 'grips.xlsx');
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteGrips = async (ids: string[]): Promise<string> => {
  try {
    const { data } = await authApiClient.post('/grips/delete', {
      ids,
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const editGrip = async (grip: GripDto): Promise<GripDto> => {
  try {
    const response = await authApiClient.put(`/grips/${grip.id}`, grip);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const importGripsFile = async (
  file: FormData
): Promise<Grips.ImportGripsDto> => {
  try {
    const { data } = await authApiClient.post('/grips/upload', file);
    return data;
  } catch (error) {
    throw error;
  }
};
