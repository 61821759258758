import { createSelector } from '@reduxjs/toolkit';
import { App, Session } from '@caddyshack/common';
import moment from 'moment';
import { RootState } from '../../app/store';

import { AsyncState } from '../../app/models/AsyncState';
import { AsyncStatus } from '../../app/enums/AsyncStatus';
import { IPage } from '../../app/models/Pagination';
import { SessionState } from './session.store';
import { ISessionModel } from '../model/Session';

export const selectSelf = (state: RootState): RootState => state;

export const selectSessionState = createSelector(
  selectSelf,
  (state): SessionState => state.session
);

export const selectSessionsState = createSelector(
  selectSessionState,
  (session): AsyncState<IPage<Session.SessionDto[], Session.SearchQuery>> =>
    session.sessions
);

export const selectSessionsStatus = createSelector(
  selectSessionsState,
  (sessions): AsyncStatus => sessions.status
);

export const selectIsFetchingSessionsPending = createSelector(
  selectSessionsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectSessionsData = createSelector(
  selectSessionsState,
  (sessionsPage): IPage<Session.SessionDto[], Session.SearchQuery> =>
    sessionsPage.data
);

export const selectSessions = createSelector(
  selectSessionsData,
  (sessionsData): Session.SessionDto[] => sessionsData.content
);

export const selectMappedSessions = createSelector(
  selectSessions,
  (sessionsContent): ISessionModel[] =>
    sessionsContent.map((session) => ({
      ...session,
      updatedDate: moment(session.date_updated).format('M/D/YYYY'),
    }))
);

export const selectSessionsPagination = createSelector(
  selectSessionsData,
  (sessionsData): App.Pagination => sessionsData.pagination
);

export const selectSessionsFilters = createSelector(
  selectSessionsData,
  (sessionsData): Session.SearchQuery | undefined => sessionsData.filters
);

export const selectDeleteSessionsState = createSelector(
  selectSessionState,
  (session): AsyncState<null> => session.deleteSessions
);

export const selectDeleteSessionsStatus = createSelector(
  selectDeleteSessionsState,
  (deleteSessions): AsyncStatus => deleteSessions.status
);

export const selectIsDeleteSessionsPending = createSelector(
  selectDeleteSessionsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectExportSessionsState = createSelector(
  selectSessionState,
  (session) => session.exportSessions
);

export const selectExportSessionsStatus = createSelector(
  selectExportSessionsState,
  (exportSessions) => exportSessions.status
);

export const selectIsExportSessionsStatusPending = createSelector(
  selectExportSessionsStatus,
  (status) => status === AsyncStatus.Pending
);
