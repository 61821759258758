import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from '@material-ui/core';

import {
  StyledCard,
  StyledIcon,
  StyledTypography,
} from './ForgotPasswordSuccess.style';
import { LOGIN } from '../../../app/const/routes';

export const ForgotPasswordSuccess: FC = () => (
  <StyledCard>
    <Box mb={2}>
      <StyledIcon color="primary" />
      <Typography color="textPrimary" variant="h2">
        Email sent
      </Typography>
      <Typography color="textSecondary" gutterBottom variant="body1">
        Reset password link has been sent
      </Typography>
    </Box>
    <Typography color="textSecondary" gutterBottom variant="body1">
      Email with the reset password link has been sent. Use that link to reset
      your password.
    </Typography>
    <StyledTypography color="textSecondary" variant="body1">
      <Link component={RouterLink} to={LOGIN}>
        Back to Sign in
      </Link>
    </StyledTypography>
  </StyledCard>
);
