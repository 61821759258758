import { Heads, App } from '@caddyshack/common';
import FileSaver from 'file-saver';
import { authApiClient } from '../../app/api/authApi.client';

export const fetchHeads = async (
  searchQuery?: Heads.SearchQuery
): Promise<App.Page<Heads.HeadDto[]>> => {
  try {
    const response = await authApiClient.post('/heads/search', searchQuery);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const exportHeads = async (): Promise<void> => {
  try {
    const { data } = await authApiClient.get('/heads/export');
    const csvData = new Blob([data], {
      type: 'text/csv',
    });

    FileSaver.saveAs(csvData, 'heads.csv');

    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteHeads = async (ids: string[]): Promise<string> => {
  try {
    const { data } = await authApiClient.post('/heads/delete', {
      ids,
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const editHead = async (head: Heads.HeadDto): Promise<Heads.HeadDto> => {
  try {
    const response = await authApiClient.put(`/heads/${head.id}`, head);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const importHeadsFile = async (
  file: FormData
): Promise<Heads.ImportHeadsDto> => {
  try {
    const { data } = await authApiClient.post('/heads/upload', file);
    return data;
  } catch (error) {
    throw error;
  }
};
