import { ShaftConstant } from '@caddyshack/common';
import { AsyncState } from '../../app/models/AsyncState';
import { AsyncStatus } from '../../app/enums/AsyncStatus';
import { IPage } from '../../app/models/Pagination';

export interface ShaftConstantState {
  shaftConstants: AsyncState<
    IPage<ShaftConstant.ShaftConstantDto[], ShaftConstant.SearchQuery>
  >;
}

export const shaftConstantState: ShaftConstantState = {
  shaftConstants: {
    status: AsyncStatus.Void,
    data: {
      content: [],
      pagination: {
        page: 0,
        pageSize: 50,
        totalElements: 0,
      },
      filters: {},
    },
  },
};
