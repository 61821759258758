import * as yup from 'yup';

export const headDetailsModalValidation = yup.object().shape({
  id: yup.string().required(),
  date_updated: yup.string(),
  number_sku: yup.string(),
  price: yup.string(),
  oem: yup.string().required(),
  stated_head: yup.string().required(),
  type: yup.string(),
  dexterity: yup.string(),
});
