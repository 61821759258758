import { Auth } from '@caddyshack/common';
import { AsyncState } from '../../app/models/AsyncState';
import { AsyncStatus } from '../../app/enums/AsyncStatus';
import { IPage } from '../../app/models/Pagination';

export interface UserState {
  profile: AsyncState<Auth.UserDto | null>;
  users: AsyncState<IPage<Auth.UserDto[], Auth.UserSearchQuery>>;
  exportUserData: AsyncState<null>;
}

export const userState: UserState = {
  profile: {
    status: AsyncStatus.Void,
    data: null,
  },
  users: {
    status: AsyncStatus.Void,
    data: {
      content: [],
      pagination: {
        page: 0,
        pageSize: 50,
        totalElements: 0,
      },
      filters: {},
    },
  },
  exportUserData: {
    status: AsyncStatus.Void,
    data: null,
  },
};
