import { Column } from 'react-table';

export const shaftConstantsColumns: Column[] = [
  {
    Header: 'Id',
    accessor: 'id',
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Value',
    accessor: 'value',
  },
  {
    Header: 'Actions',
  },
];
