import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Hidden, IconButton, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { Logo } from '../Logo/Logo';
import { TopBarMenu } from '../TopBarMenu/TopBarMenu';
import { ACCOUNT, GRIPS } from '../../../const/routes';
import { AppDispatch } from '../../../store';
import { selectProfileData } from '../../../../auth/store/auth.selector';
import { logout } from '../../../../auth/store/auth.actions';

interface IConnectedTopBar {
  onMobileNavOpen: () => void;
}

export const ConnectedTopBar: FC<IConnectedTopBar> = ({
  onMobileNavOpen,
  ...rest
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const profileData = useSelector(selectProfileData);

  const handleOnLogoutClick = () => dispatch(logout());

  const handleOnAccountClick = () => dispatch(push(ACCOUNT));

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to={GRIPS}>
          <Logo />
        </RouterLink>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <Box flexGrow={1} />
        <TopBarMenu
          email={profileData?.email}
          onLogoutClick={handleOnLogoutClick}
          onAccountClick={handleOnAccountClick}
        />
      </Toolbar>
    </AppBar>
  );
};
