import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import {
  FullHeightContainer,
  StyledPage,
} from '../../app/components/DashboardLayout/DashboardLayout.style';
import { ShaftsTableContainer } from '../containers/ShaftsTableContainer/ShaftsTableContainer';
import { PageHeader } from '../../app/components/DashboardLayout/PageHeader/PageHeader';

export const ShaftView: FC = () => {
  return (
    <StyledPage title="Shafts">
      <FullHeightContainer maxWidth={false}>
        <Box mb={3}>
          <PageHeader title="Shafts" />
        </Box>
        <Box
          flexDirection="column"
          height="100%"
          justifyContent="center"
          flex={1}
        >
          <ShaftsTableContainer />
        </Box>
      </FullHeightContainer>
    </StyledPage>
  );
};
