import {
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import React, { PropsWithChildren, ReactElement, useMemo } from 'react';
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  TableOptions,
} from 'react-table';
import { App } from '@caddyshack/common';
import { TableToolbar } from './TableToolbar/TableToolbar';
import selectionHook from './selectionHook';
import {
  InnerTableWrapper,
  LoaderOverlay,
  RelativeWrapper,
  StyledTableContainer,
  StyledTableInnerContainer,
  StyledTablePagination,
  TableWrapper,
  StyledTableHeaderCell,
  StyledTableRow,
} from './Table.style';

export interface ITable<T extends Record<string, unknown>>
  extends TableOptions<T> {
  pagination?: {
    page: number;
    pageSize: number;
    totalElements: number;
  };
  sorting?: {
    id: string;
    desc: boolean;
  };
  onSortChange?: (sort: { key: string; order: App.SortOrder } | null) => void;
  onPageChange?: (index: number) => void;
  Toolbar?: () => ReactElement;
  // eslint-disable-next-line
  SelectedToolbar?: (selectedRows: any[]) => ReactElement;
  actionsCell?: { Header: string; render: (row: T) => ReactElement };
  disabledSorting?: string[];
  loading?: boolean;
  // eslint-disable-next-line
  onRowClick?: (row: any) => void;
}

export function Table<T extends Record<string, unknown>>({
  columns,
  data,
  pagination,
  sorting,
  onPaginationChange,
  onSortChange,
  Toolbar,
  SelectedToolbar,
  actionsCell,
  disabledSorting,
  loading,
  onRowClick,
}: PropsWithChildren<ITable<T>>): ReactElement {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize },
  } = useTable<T>(
    {
      columns,
      data,
      manualPagination: Boolean(onPaginationChange),
      manualSortBy: Boolean(onSortChange),
      initialState: { pageIndex: 0, pageSize: 50 },
      autoResetPage: false,
      autoResetSortBy: false,
      useControlledState: (state) => {
        return useMemo(
          () => ({
            ...state,
            pageIndex: pagination?.page || state.pageIndex,
            pageSize: pagination?.pageSize || state.pageSize,
            sortBy: sorting ? [sorting] : state.sortBy,
          }),
          [state]
        );
      },
    },
    useSortBy,
    usePagination,
    useRowSelect,
    selectionHook
  );

  const isData = data && data.length > 0;

  const handlePaginationChange = (newPagination: {
    page?: number;
    pageSize?: number;
  }): void => {
    if (onPaginationChange) {
      return onPaginationChange(newPagination);
    }

    setPageSize(newPagination?.pageSize || 50);
    return gotoPage(newPagination?.page || 0);
  };

  const handleSortChange = (sort: { key: string; order: App.SortOrder }) => {
    if (onSortChange) {
      const disabledSortingKeys: string[] = disabledSorting || [];
      if (['_selector', ...disabledSortingKeys].includes(sort.key)) {
        return;
      }

      if (sorting && !sorting?.desc && sort.order === 'desc') {
        onSortChange(null);
      } else {
        onSortChange(sort);
      }
    }
  };

  return (
    <RelativeWrapper>
      <TableWrapper>
        <TableToolbar
          selectedRows={selectedFlatRows.map(
            (selectedRow) => selectedRow.original
          )}
          Toolbar={Toolbar}
          SelectedToolbar={SelectedToolbar}
        />
        <InnerTableWrapper>
          {loading && (
            <LoaderOverlay>
              <CircularProgress size={50} />
            </LoaderOverlay>
          )}
          {!isData && !loading && (
            <LoaderOverlay>
              <Typography variant="h3">No Data</Typography>
            </LoaderOverlay>
          )}
          <StyledTableContainer>
            <StyledTableInnerContainer>
              <MuiTable {...getTableProps()} stickyHeader>
                <TableHead>
                  {headerGroups.map((headerGroup) => (
                    <TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <StyledTableHeaderCell
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          {...(onSortChange && {
                            onClick: () =>
                              handleSortChange({
                                key: column.id,
                                order: column.isSortedDesc
                                  ? App.SortOrder.ASC
                                  : App.SortOrder.DESC,
                              }),
                          })}
                        >
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (
                              <TableSortLabel
                                active={column.isSorted}
                                direction={
                                  column.isSortedDesc
                                    ? App.SortOrder.DESC
                                    : App.SortOrder.ASC
                                }
                              />
                            ) : null}
                          </span>
                        </StyledTableHeaderCell>
                      ))}
                    </TableRow>
                  ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <StyledTableRow
                        {...row.getRowProps()}
                        onClick={() =>
                          onRowClick ? onRowClick(row.original) : {}
                        }
                        hover={Boolean(onRowClick)}
                      >
                        {row.cells.map((cell) => {
                          if (
                            actionsCell &&
                            cell.column.Header === actionsCell.Header
                          ) {
                            return (
                              <TableCell {...cell.getCellProps()}>
                                {actionsCell.render(row.original)}
                              </TableCell>
                            );
                          }
                          return (
                            <TableCell {...cell.getCellProps()}>
                              {cell.render('Cell')}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </MuiTable>
            </StyledTableInnerContainer>
          </StyledTableContainer>
          <StyledTablePagination
            colSpan={3}
            count={pagination?.totalElements || data.length}
            onChangePage={(event, newPage) =>
              handlePaginationChange({ page: newPage, pageSize })
            }
            onChangeRowsPerPage={(event) =>
              handlePaginationChange({
                pageSize: Number(event.target.value),
                page: pageIndex,
              })
            }
            rowsPerPage={pagination?.pageSize || pageSize}
            page={pagination?.page || pageIndex}
            rowsPerPageOptions={[50, 100, 200]}
            component="div"
          />
        </InnerTableWrapper>
      </TableWrapper>
    </RelativeWrapper>
  );
}
