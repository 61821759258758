export enum MenuValues {
  Details = 'details',
  RobotData = 'robotData',
}

export enum HeadLocationsValues {
  Center = 'center',
  Center34toe = 'center34toe',
  Center34heel = 'center34heel',
  Highcenter = 'highcenter',
  Highcenter34toe = 'highcenter34toe',
  Highcenter34heel = 'highcenter34heel',
  Lowcenter = 'lowcenter',
  Lowcenter34toe = 'lowcenter34toe',
  Lowcenter34heel = 'lowcenter34heel',
}

export const HeadLocations = [
  {
    label: 'Center',
    value: HeadLocationsValues.Center,
  },
  {
    label: 'Center 3/4" Toe',
    value: HeadLocationsValues.Center34toe,
  },
  {
    label: 'Center 3/4" Heel',
    value: HeadLocationsValues.Center34heel,
  },
  {
    label: 'High Center',
    value: HeadLocationsValues.Highcenter,
  },
  {
    label: 'High Center 3/4" Toe',
    value: HeadLocationsValues.Highcenter34toe,
  },
  {
    label: 'High Center 3/4" Heel',
    value: HeadLocationsValues.Highcenter34heel,
  },
  {
    label: 'Low Center',
    value: HeadLocationsValues.Lowcenter,
  },
  {
    label: 'Low Center 3/4" Toe',
    value: HeadLocationsValues.Lowcenter34toe,
  },
  {
    label: ' Low Center 3/4" Heel',
    value: HeadLocationsValues.Lowcenter34heel,
  },
];
