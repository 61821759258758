import { createSlice } from '@reduxjs/toolkit';
import { AsyncStatus } from '../../app/enums/AsyncStatus';
import { sessionState } from './session.store';
import {
  deleteSessions,
  exportSessions,
  fetchSessions,
} from './session.actions';

const slice = createSlice({
  name: 'session',
  initialState: sessionState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSessions.pending, (state, { meta: { arg } }) => ({
      ...state,
      sessions: {
        ...state.sessions,
        status: AsyncStatus.Pending,
        data: {
          ...state.sessions.data,
          filters: arg,
        },
      },
    }));
    builder.addCase(fetchSessions.fulfilled, (state, { payload }) => {
      if (payload) {
        const { pagination, content } = payload;
        return {
          ...state,
          sessions: {
            status: AsyncStatus.Fulfilled,
            data: {
              ...state.sessions.data,
              content,
              pagination,
            },
          },
        };
      }

      return state;
    });
    builder.addCase(fetchSessions.rejected, (state) => ({
      ...state,
      sessions: {
        ...state.sessions,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(deleteSessions.pending, (state) => ({
      ...state,
      deleteSessions: {
        ...state.deleteSessions,
        status: AsyncStatus.Pending,
      },
    }));

    builder.addCase(deleteSessions.fulfilled, (state) => ({
      ...state,
      deleteSessions: {
        ...state.deleteSessions,
        status: AsyncStatus.Fulfilled,
      },
    }));

    builder.addCase(deleteSessions.rejected, (state) => ({
      ...state,
      deleteSessions: {
        ...state.deleteSessions,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(exportSessions.pending, (state) => ({
      ...state,
      exportSessions: {
        ...state.exportSessions,
        status: AsyncStatus.Pending,
      },
    }));

    builder.addCase(exportSessions.fulfilled, (state) => ({
      ...state,
      exportSessions: {
        ...state.exportSessions,
        status: AsyncStatus.Fulfilled,
      },
    }));

    builder.addCase(exportSessions.rejected, (state) => ({
      ...state,
      exportSessions: {
        ...state.exportSessions,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export default slice.reducer;
