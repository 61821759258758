import React, { FC, useState } from 'react';
import { Grips, App } from '@caddyshack/common';
import GripsToolbar from '../GripsToolbar/GripsToolbar';
import { Table } from '../../../app/components/Table/Table';
import { GripsDetailsModal } from '../GripsDetailsModal/GripsDetailsModal';

import { StyledCard } from './GripsTable.style';
import { TableToolbarButton } from '../../../app/components/Table/Table.style';
import { gripsColumns } from './GripsTable.columns';
import { IGripModel } from '../../model/Grip';

interface IGripsTable {
  grips: IGripModel[];
  isPending: boolean;
  pagination: App.Pagination;
  sorting?: {
    key: string;
    order: App.SortOrder;
  };
  filter?: string;
  onQueryChange: (searchQuery: Grips.SearchQuery) => void;
  onExport: () => void;
  isExportPending: boolean;
  onDelete: (ids: string[]) => void;
  onEdit: (grip: IGripModel) => void;
  onImport: (file: File) => void;
}

export const GripsTable: FC<IGripsTable> = ({
  grips,
  isPending,
  pagination,
  sorting,
  filter,
  onQueryChange,
  onExport,
  isExportPending,
  onDelete,
  onEdit,
  onImport,
}: IGripsTable) => {
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedGripDetails, setSelectedGripDetails] = useState(grips[0]);

  const handleModalOpen = (row: IGripModel) => {
    setSelectedGripDetails(row);
    setDetailsModalOpen(true);
  };
  const handleModalClose = () => {
    setDetailsModalOpen(false);
  };

  const Toolbar = () => (
    <GripsToolbar
      onSearch={(searchTerm) =>
        onQueryChange({ searchFilter: searchTerm, page: 0 })
      }
      onExport={onExport}
      onImport={onImport}
      isExportPending={isExportPending}
      isPending={isPending}
      filter={filter}
    />
  );

  const SelectedToolbar = (selectedGrips: IGripModel[]) => (
    <TableToolbarButton
      color="primary"
      disabled={isPending}
      variant="contained"
      onClick={() =>
        onDelete(
          selectedGrips.map((selectedGrip: IGripModel) => selectedGrip.id)
        )
      }
    >
      {`Delete ${selectedGrips.length} ${
        selectedGrips.length === 1 ? 'grip' : 'grips'
      }`}
    </TableToolbarButton>
  );

  const tableSorting = sorting
    ? {
        id: sorting.key,
        desc: sorting.order === 'desc',
      }
    : undefined;

  return (
    <StyledCard>
      <Table
        loading={isPending}
        columns={gripsColumns}
        data={grips}
        pagination={pagination}
        sorting={tableSorting}
        onPaginationChange={onQueryChange}
        onSortChange={(sort) =>
          sort ? onQueryChange({ sort }) : onQueryChange({ sort: undefined })
        }
        Toolbar={Toolbar}
        SelectedToolbar={SelectedToolbar}
        disabledSorting={['Actions']}
        onRowClick={(row: IGripModel) => handleModalOpen(row)}
      />
      {selectedGripDetails && (
        <GripsDetailsModal
          onClose={handleModalClose}
          open={detailsModalOpen}
          grip={selectedGripDetails}
          onEdit={onEdit}
        />
      )}
    </StyledCard>
  );
};
