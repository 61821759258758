import { Shafts, App } from '@caddyshack/common';
import FileSaver from 'file-saver';
import { authApiClient } from '../../app/api/authApi.client';

export const fetchShafts = async (
  searchQuery?: Shafts.SearchQuery
): Promise<App.Page<Shafts.ShaftDto[]>> => {
  try {
    const response = await authApiClient.post('/shafts/search', searchQuery);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const exportShafts = async (): Promise<void> => {
  try {
    const { data } = await authApiClient.get('/shafts/export');
    const csvData = new Blob([data], {
      type: 'text/csv',
    });

    FileSaver.saveAs(csvData, 'shafts.csv');

    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteShafts = async (ids: string[]): Promise<string> => {
  try {
    const { data } = await authApiClient.post('/shafts/delete', {
      ids,
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const editShaft = async (
  shaft: Shafts.ShaftDto
): Promise<Shafts.ShaftDto> => {
  try {
    const response = await authApiClient.put(`/shafts/${shaft.id}`, shaft);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const importShaftsFile = async (
  file: FormData
): Promise<Shafts.ImportShaftsDto> => {
  try {
    const { data } = await authApiClient.post('/shafts/upload', file);
    return data;
  } catch (error) {
    throw error;
  }
};
