import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../app/store';
import { IRegisterDataModel } from '../../models/Auth';
import { RegisterForm } from '../../components/RegisterForm/RegisterForm';
import { register } from '../../store/auth.actions';
import { selectIsRegisterStatusPending } from '../../store/auth.selector';

export const RegisterFormContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isRegisterPending = useSelector(selectIsRegisterStatusPending);

  const onSubmit = (registerData: IRegisterDataModel) =>
    dispatch(register(registerData));

  return <RegisterForm onSubmit={onSubmit} pending={isRegisterPending} />;
};
