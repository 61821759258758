import { Auth } from '@caddyshack/common';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../app/store';
import { SecurityForm } from '../../components/SecurityForm/SecurityForm';
import { changePassword } from '../../store/auth.actions';
import { selectIsChangePasswordStatusPending } from '../../store/auth.selector';

export const SecurityFormContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isPending = useSelector(selectIsChangePasswordStatusPending);

  const onSubmit = (formValues: Auth.ChangePasswordDto) =>
    dispatch(changePassword(formValues));

  return <SecurityForm onSubmit={onSubmit} pending={isPending} />;
};
