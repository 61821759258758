import React, { FC, useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { Box, IconButton } from '@material-ui/core';
import { Formik } from 'formik';
import { Modal } from '../../../app/components/Modal/Modal';
import { ModalDetailsBox } from '../../../app/components/Modal/ModalDetailsBox/ModalDetailsBox';
import { ModalDetailsBoxForm } from '../../../app/components/Modal/ModalDetailsBoxForm/ModalDetailsBoxForm';
import { gripDetailsModalValidation } from './GripsDetailsModal.validation';

import {
  StyledModalContent,
  StyledDialogTitleContainer,
  StyledBoxLeft,
  StyledBoxRight,
  StyledManufacturerTitle,
  StyledTitle,
  StyledForm,
} from './GripsDetailsModal.style';
import { IGripModel } from '../../model/Grip';

export interface IGripsDetailsModal {
  onClose: () => void;
  open: boolean;
  grip: IGripModel;
  onEdit: (grip: IGripModel) => void;
}

export const GripsDetailsModal: FC<IGripsDetailsModal> = ({
  onClose,
  open,
  grip,
  onEdit,
}: IGripsDetailsModal) => {
  const [isEditMode, setIsEditMode] = useState(false);
  // eslint-disable-next-line
  const formRef = useRef<any>();

  const handleClose = () => {
    onClose();
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleCancel = () => {
    setIsEditMode(false);
  };

  const handleSave = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleFormSubmit = (values: IGripModel) => {
    onEdit(values);
    setIsEditMode(false);
    onClose();
  };

  const ModalHeader = () => (
    <StyledDialogTitleContainer disableTypography>
      <Box>
        <StyledManufacturerTitle variant="h6">
          {grip?.make}
        </StyledManufacturerTitle>
        <StyledTitle variant="h4">{grip?.model}</StyledTitle>
      </Box>
      {isEditMode ? (
        <Box>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            style={{ marginRight: 15 }}
          >
            SAVE
          </Button>

          <Button onClick={handleCancel} color="primary" variant="outlined">
            Cancel
          </Button>
        </Box>
      ) : (
        <Box>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </StyledDialogTitleContainer>
  );

  const structureInformation = [
    { label: 'ID', value: grip.id, key: 'id', disabled: true },
    {
      label: 'Date Updated',
      value: grip.dateUpdated,
      key: 'dateUpdated',
      disabled: true,
    },
  ];

  const shopifyInformation = [
    { label: 'SKU', value: grip.sku, key: 'sku', disabled: true },
    { label: 'Price', value: grip.price, key: 'price' },
  ];

  const technicalInformation = [
    {
      label: 'Manufacturer',
      value: grip.make,
      key: 'make',
      requiredLabel: true,
    },
    { label: 'Model', value: grip.model, key: 'model', requiredLabel: true },
    { label: 'Mass (g)', value: grip.mass_g, key: 'mass_g' },
    { label: 'Description', value: grip.description, key: 'description' },
  ];

  const ModalContent = () => {
    return (
      <StyledModalContent>
        {isEditMode ? (
          <Formik
            initialValues={grip}
            validationSchema={gripDetailsModalValidation}
            onSubmit={handleFormSubmit}
            innerRef={formRef}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              ...rest
            }) => (
              <StyledForm onSubmit={handleSubmit} noValidate>
                <StyledBoxLeft>
                  <ModalDetailsBoxForm
                    rows={structureInformation}
                    title="Structure Information"
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    values={values}
                    {...rest}
                  />

                  <ModalDetailsBoxForm
                    rows={shopifyInformation}
                    title="Shopify Information"
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    values={values}
                    {...rest}
                  />
                </StyledBoxLeft>
                <StyledBoxRight>
                  <ModalDetailsBoxForm
                    rows={technicalInformation}
                    title="Technical Information"
                    scrollable
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    values={values}
                    {...rest}
                  />
                </StyledBoxRight>
              </StyledForm>
            )}
          </Formik>
        ) : (
          <>
            <StyledBoxLeft>
              <ModalDetailsBox
                rows={structureInformation}
                title="Structure Information"
              />

              <ModalDetailsBox
                rows={shopifyInformation}
                title="Shopify Information"
              />
            </StyledBoxLeft>
            <StyledBoxRight>
              <ModalDetailsBox
                rows={technicalInformation}
                title="Technical Information"
                scrollable
              />
            </StyledBoxRight>
          </>
        )}
      </StyledModalContent>
    );
  };

  return (
    <Modal
      Content={ModalContent}
      Header={ModalHeader}
      onClose={handleClose}
      open={open}
    />
  );
};
