import React, { FC } from 'react';
import { Swing, App } from '@caddyshack/common';
import { Download as DownloadIcon } from 'react-feather';
import { Table } from '../../../app/components/Table/Table';
import SwingsToolbar from '../SwingsToolbar/SwingsToolbar';

import { StyledCard, StyledLink } from './SwingsTable.style';
import { swingsColumns } from './SwingsTable.columns';
import { ISwingModel } from '../../model/Swing';
import { TableToolbarButton } from '../../../app/components/Table/Table.style';

interface ISwingTable {
  swings: ISwingModel[];
  isPending: boolean;
  isExportPending: boolean;
  pagination: App.Pagination;
  sorting?: {
    key: string;
    order: App.SortOrder;
  };
  filter?: string;
  onQueryChange: (searchQuery: Swing.SearchQuery) => void;
  onDelete: (ids: string[]) => void;
  onExport: (exportFilters: Swing.ExportSwingsFiltersDto) => void;
  download: (swingId: string) => void;
}

export const SwingsTable: FC<ISwingTable> = ({
  swings,
  isPending,
  isExportPending,
  pagination,
  sorting,
  filter,
  onQueryChange,
  onDelete,
  onExport,
  download,
}: ISwingTable) => {
  const tableSorting = sorting
    ? {
        id: sorting.key,
        desc: sorting.order === 'desc',
      }
    : undefined;

  const handleDownload = (swingId: string) => {
    download(swingId);
  };

  const actionsCell = {
    Header: 'Actions',
    render: (row: unknown) => {
      const swingModelRow: ISwingModel = row as ISwingModel;

      return (
        <StyledLink onClick={() => handleDownload(swingModelRow.id)}>
          <DownloadIcon />
        </StyledLink>
      );
    },
  };

  const SelectedToolbar = (selectedSwings: ISwingModel[]) => (
    <TableToolbarButton
      color="primary"
      disabled={isPending}
      variant="contained"
      onClick={() =>
        onDelete(
          selectedSwings.map((selectedSwift: ISwingModel) => selectedSwift.id)
        )
      }
    >
      {`Delete ${selectedSwings.length} ${
        selectedSwings.length === 1 ? 'swing' : 'swings'
      }`}
    </TableToolbarButton>
  );

  const Toolbar = () => (
    <SwingsToolbar
      onSearch={(searchTerm) =>
        onQueryChange({ uploadedByEmail: searchTerm, page: 0 })
      }
      onExport={onExport}
      isPending={isPending}
      isExportPending={isExportPending}
      filter={filter}
    />
  );

  return (
    <StyledCard>
      <Table
        loading={isPending}
        columns={swingsColumns}
        data={swings}
        pagination={pagination}
        sorting={tableSorting}
        onPaginationChange={onQueryChange}
        onSortChange={(sort) =>
          sort ? onQueryChange({ sort }) : onQueryChange({ sort: undefined })
        }
        Toolbar={Toolbar}
        SelectedToolbar={SelectedToolbar}
        disabledSorting={['Actions']}
        actionsCell={actionsCell}
      />
    </StyledCard>
  );
};
