import React, { FC } from 'react';
import { ShaftConstant, App } from '@caddyshack/common';
import { Edit as EditIcon } from 'react-feather';
import { Table } from '../../../app/components/Table/Table';
import { TableToolbarButton } from '../../../app/components/Table/Table.style';
import ShaftConstantsToolbar from '../ShaftConstantsToolbar/ShaftConstantsToolbar';
import EditShaftConstantDialog from '../EditShaftConstantDialog/EditShaftConstantDialog';
import { StyledCard, StyledLink } from './ShaftConstantsTable.style';
import { shaftConstantsColumns } from './ShaftConstantsTable.columns';
import { IShaftConstantModel } from '../../model/ShaftConstant';

interface IShaftConstantTable {
  shaftConstants: IShaftConstantModel[];
  isPending: boolean;
  pagination: App.Pagination;
  sorting?: {
    key: string;
    order: App.SortOrder;
  };
  filter?: string;
  onQueryChange: (searchQuery: ShaftConstant.SearchQuery) => void;
  onDelete: (ids: string[]) => void;
  onUpdateShaftConstant: (id: string, value: number) => void;
}

export const ShaftConstantsTable: FC<IShaftConstantTable> = ({
  shaftConstants,
  isPending,
  pagination,
  sorting,
  filter,
  onQueryChange,
  onDelete,
  onUpdateShaftConstant,
}: IShaftConstantTable) => {
  const [editDialogOpen, setEditDialogOpen] = React.useState(false);
  const [
    selectedRow,
    setSelectedRow,
  ] = React.useState<IShaftConstantModel | null>(null);

  const handleEditClick = (shaftConstantModelRow: IShaftConstantModel) => {
    setSelectedRow(shaftConstantModelRow);
    setEditDialogOpen(true);
  };

  const handleDialogClose = (
    id: string | undefined,
    value: number | undefined
  ) => {
    setEditDialogOpen(false);
    setSelectedRow(null);

    if (value && id) {
      onUpdateShaftConstant(id, value);
    }
  };

  const tableSorting = sorting
    ? {
        id: sorting.key,
        desc: sorting.order === 'desc',
      }
    : undefined;

  const actionsCell = {
    Header: 'Actions',
    render: (row: unknown) => {
      const shaftConstantModelRow: IShaftConstantModel = row as IShaftConstantModel;
      return (
        <StyledLink>
          <EditIcon
            onClick={() => handleEditClick(shaftConstantModelRow)}
            size={20}
          />
        </StyledLink>
      );
    },
  };

  const SelectedToolbar = (selectedShaftConstants: IShaftConstantModel[]) => (
    <TableToolbarButton
      color="primary"
      disabled={isPending}
      variant="contained"
      onClick={() =>
        onDelete(
          selectedShaftConstants.map(
            (selectedSwift: IShaftConstantModel) => selectedSwift.id
          )
        )
      }
    >
      {`Delete ${selectedShaftConstants.length} ${
        selectedShaftConstants.length === 1
          ? 'shaft constant'
          : 'shaft constants'
      }`}
    </TableToolbarButton>
  );

  const Toolbar = () => (
    <ShaftConstantsToolbar
      onSearch={(searchTerm) => onQueryChange({ name: searchTerm, page: 0 })}
      isPending={isPending}
      filter={filter}
    />
  );

  return (
    <StyledCard>
      <Table
        loading={isPending}
        columns={shaftConstantsColumns}
        data={shaftConstants}
        pagination={pagination}
        sorting={tableSorting}
        onPaginationChange={onQueryChange}
        onSortChange={(sort) =>
          sort ? onQueryChange({ sort }) : onQueryChange({ sort: undefined })
        }
        Toolbar={Toolbar}
        SelectedToolbar={SelectedToolbar}
        disabledSorting={['Actions']}
        actionsCell={actionsCell}
      />
      <EditShaftConstantDialog
        id="edit-shaft-constant"
        keepMounted={false}
        open={editDialogOpen}
        selectedRowValue={selectedRow?.value}
        selectedRowName={selectedRow?.name}
        onClose={(value) => handleDialogClose(selectedRow?.id, value)}
      />
    </StyledCard>
  );
};
