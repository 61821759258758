import React, { FC } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Button, makeStyles } from '@material-ui/core';
import { Icon } from 'react-feather';

import { StyledListItem, StyledIconContainer } from './NavItem.style';

const useStyles = makeStyles((theme) => ({
  button: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}));

interface INavItem {
  href: string;
  icon: Icon;
  title: string;
  key: string;
}

export const NavItem: FC<INavItem> = ({
  href,
  icon: IconComp,
  title,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <StyledListItem button disableGutters {...rest}>
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href}
      >
        {IconComp && (
          <StyledIconContainer>
            <IconComp size="20" />
          </StyledIconContainer>
        )}
        <span>{title}</span>
      </Button>
    </StyledListItem>
  );
};
