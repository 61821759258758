import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import {
  FullHeightContainer,
  StyledPage,
} from '../../app/components/DashboardLayout/DashboardLayout.style';
import { SessionsTableContainer } from '../containers/SessionsTableContainer/SessionsTableContainer';
import { PageHeader } from '../../app/components/DashboardLayout/PageHeader/PageHeader';

export const SessionsView: FC = () => {
  return (
    <StyledPage title="Sessions">
      <FullHeightContainer maxWidth={false}>
        <Box mb={3}>
          <PageHeader title="Sessions" />
        </Box>
        <Box
          flexDirection="column"
          height="100%"
          justifyContent="center"
          flex={1}
        >
          <SessionsTableContainer />
        </Box>
      </FullHeightContainer>
    </StyledPage>
  );
};
