import styled from 'styled-components';
import { Box, Card, Typography } from '@material-ui/core';

import { ReactComponent as Logo } from '../../../../assets/logoSymbol.svg';

export const StyledCard = styled(Card)`
  padding: ${({ theme }) => `${theme.spacing(3)}px`};
`;

export const StyledTypography = styled(Typography)`
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
`;

export const StyledBox = styled(Box)`
  position: relative;
`;

export const StyledLogo = styled(Logo)`
  width: 200px;
  height: 140px;
  margin-bottom: 50px;
`;
