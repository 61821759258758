import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from '@material-ui/core';

import {
  StyledCard,
  StyledIcon,
  StyledTypography,
} from './RegisterSuccess.style';
import { LOGIN } from '../../../app/const/routes';

export const RegisterSuccess: FC = () => (
  <StyledCard>
    <Box mb={2}>
      <StyledIcon color="primary" />
      <Typography color="textPrimary" variant="h2">
        Email sent
      </Typography>
      <Typography color="textSecondary" gutterBottom variant="body1">
        A registration confirmation link has been sent
      </Typography>
    </Box>
    <Typography color="textSecondary" gutterBottom variant="body1">
      Check your email and click the link to the complete the registration
      process.
    </Typography>
    <StyledTypography color="textSecondary" variant="body1">
      <Link component={RouterLink} to={LOGIN}>
        Back to Sign in
      </Link>
    </StyledTypography>
  </StyledCard>
);
