import React, { FC } from 'react';
import { Box, Container } from '@material-ui/core';

import { StyledPage } from '../../../app/components/DashboardLayout/DashboardLayout.style';
import { RegisterFormContainer } from '../../containers/RegisterFormContainer/RegisterFormContainer';

export const RegisterView: FC = () => {
  return (
    <StyledPage title="Register">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <RegisterFormContainer />
        </Container>
      </Box>
    </StyledPage>
  );
};
