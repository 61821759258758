import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Shafts } from '@caddyshack/common';

import { AppDispatch } from '../../../app/store';
import {
  fetchShafts,
  exportShafts,
  deleteShafts,
  editShaft,
  importShaftsFile,
} from '../../store/shaft.actions';
import {
  selectIsFetchingShaftsPending,
  selectShaftsPagination,
  selectShaftsFilters,
  selectIsExportShaftsStatusPending,
  selectIsDeleteShaftsStatusPending,
  selectMappedShafts,
  selectIsImportShaftsFileStatusPending,
} from '../../store/shaft.selector';
import { ShaftsTable } from '../../components/ShaftsTable/ShaftsTable';
import { IShaftModel } from '../../model/Shaft';

export const ShaftsTableContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const shafts = useSelector(selectMappedShafts);
  const shaftsPagination = useSelector(selectShaftsPagination);
  const shaftsFilters = useSelector(selectShaftsFilters);
  const isPending = useSelector(selectIsFetchingShaftsPending);
  const isExportPending = useSelector(selectIsExportShaftsStatusPending);
  const isDeletePending = useSelector(selectIsDeleteShaftsStatusPending);
  const isImportShaftsFilePending = useSelector(
    selectIsImportShaftsFileStatusPending
  );

  useEffect(() => {
    dispatch(
      fetchShafts({
        pageSize: 50,
        page: 0,
      })
    );
  }, [dispatch]);

  const fetchShaftsWithQuery = (searchQuery: Shafts.SearchQuery) => {
    dispatch(
      fetchShafts({
        ...shaftsFilters,
        ...searchQuery,
      })
    );
  };

  const onExport = () => dispatch(exportShafts(''));

  const onDelete = (ids: string[]) => dispatch(deleteShafts(ids));
  const onEdit = (shaft: IShaftModel) => dispatch(editShaft(shaft));

  const onImport = (file: File) => dispatch(importShaftsFile(file));

  return (
    <ShaftsTable
      shafts={shafts}
      isPending={isPending || isDeletePending || isImportShaftsFilePending}
      pagination={shaftsPagination}
      sorting={shaftsFilters?.sort}
      filter={shaftsFilters?.searchFilter}
      onQueryChange={fetchShaftsWithQuery}
      onExport={onExport}
      isExportPending={isExportPending}
      onDelete={onDelete}
      onEdit={onEdit}
      onImport={onImport}
    />
  );
};
