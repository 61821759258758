import { Heads } from '@caddyshack/common';
import { AsyncState } from '../../app/models/AsyncState';
import { AsyncStatus } from '../../app/enums/AsyncStatus';
import { IPage } from '../../app/models/Pagination';

export interface HeadState {
  heads: AsyncState<IPage<Heads.HeadDto[], Heads.SearchQuery>>;
  exportHeads: AsyncState<null>;
  deleteHeads: AsyncState<null>;
  importHeadsFile: AsyncState<null>;
}

export const headState: HeadState = {
  heads: {
    status: AsyncStatus.Void,
    data: {
      content: [],
      pagination: {
        page: 0,
        pageSize: 50,
        totalElements: 0,
      },
      filters: {},
    },
  },
  exportHeads: {
    status: AsyncStatus.Void,
    data: null,
  },
  deleteHeads: {
    status: AsyncStatus.Void,
    data: null,
  },
  importHeadsFile: {
    status: AsyncStatus.Void,
    data: null,
  },
};
