import React, { FC, useState, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Box,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { loginFormValidationSchema } from './LoginForm.validation';
import { Button } from '../../../app/components/Button/Button';
import { ILoginCredentialsModel } from '../../models/Auth';

import {
  StyledBox,
  StyledCard,
  StyledLogo,
  StyledTypography,
} from './LoginForm.style';
import { FORGOT_PASSWORD, REGISTER } from '../../../app/const/routes';
import { GoogleLogin } from '../GoogleLogin/GoogleLogin';
import { ReactAppleLogin } from '../AppleLogin/AppleLogin';

export const { REACT_APP_APPLE_CLIENT_ID } = process.env;

interface ILoginForm {
  onSubmit: (formValues: ILoginCredentialsModel) => void;
  onGoogleLogin: (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => void;
  pending: boolean;
}

export const LoginForm: FC<ILoginForm> = ({
  onSubmit,
  onGoogleLogin,
  pending,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleClickShowPassword = () =>
    setIsPasswordVisible(!isPasswordVisible);

  const handleMouseDownPassword = (event: MouseEvent) => event.preventDefault();

  return (
    <>
      <StyledBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex="1"
      >
        <StyledLogo />
      </StyledBox>
      <StyledCard>
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          validationSchema={loginFormValidationSchema}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Box mb={2}>
                <Typography color="textPrimary" variant="h2">
                  Sign in
                </Typography>
                <Typography color="textSecondary" gutterBottom variant="body1">
                  Sign in on the internal platform
                </Typography>
              </Box>
              <TextField
                disabled={pending}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email address"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
              <TextField
                disabled={pending}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type={isPasswordVisible ? 'text' : 'password'}
                value={values.password}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      disabled={pending}
                    >
                      {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  ),
                }}
              />
              <Box my={2}>
                <Button
                  pending={pending}
                  disabled={pending}
                  color="primary"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Sign in
                </Button>
              </Box>
              <Box my={2}>
                <GoogleLogin
                  onSuccess={onGoogleLogin}
                  onFailure={onGoogleLogin}
                />
              </Box>
              <Box my={2}>
                <ReactAppleLogin />
              </Box>
              <StyledTypography color="textSecondary" variant="body1">
                <Link component={RouterLink} to={REGISTER}>
                  Sign up
                </Link>
                <Link component={RouterLink} to={FORGOT_PASSWORD}>
                  Forgot password
                </Link>
              </StyledTypography>
            </form>
          )}
        </Formik>
      </StyledCard>
    </>
  );
};
