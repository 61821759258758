import React from 'react';
import { Column } from 'react-table';
import moment from 'moment';
import { Swing } from '@caddyshack/common';
import { ISwingModel } from '../../model/Swing';
import { SwingsTableTooltip } from '../SwingsTableTooltip/SwingsTableTooltip';
import { CellWrapper } from './SwingsTable.style';

export const swingsColumns: Column[] = [
  {
    Header: 'Email',
    id: 'swing.uploadedByEmail',
    Cell: ({ row: { original } }: { row: { original: ISwingModel } }) => (
      <CellWrapper>
        {original.uploadedByEmail}
        <SwingsTableTooltip data={original} />
      </CellWrapper>
    ),
  },
  {
    Header: 'Date',
    accessor: 'swing.uploadedAt',
    Cell: ({ row: { original } }: { row: { original: ISwingModel } }) =>
      moment(original.uploadedAt).format('M/D/YYYY hh:mm:ss A'),
  },
  {
    Header: 'Data Mode',
    accessor: 'session.data_mode',
  },
  {
    Header: 'Algorithm',
    id: 'swing.valid',
    Cell: ({ row: { original } }: { row: { original: ISwingModel } }) =>
      original.valid ? Swing.SwingStatus.Valid : Swing.SwingStatus.Invalid,
  },
  {
    Header: 'Tester',
    id: 'swing.testerValid',
    Cell: ({ row: { original } }: { row: { original: ISwingModel } }) =>
      original.testerValid === null
        ? ''
        : original.testerValid
        ? Swing.SwingStatus.Valid
        : Swing.SwingStatus.Invalid,
  },
  {
    Header: 'Impact Location',
    accessor: 'swing.testerImpactLocation',
    Cell: ({ row: { original } }: { row: { original: ISwingModel } }) =>
      original.testerImpactLocation,
  },
  {
    Header: 'Ball Trajectory',
    accessor: 'swing.testerBallTrajectory',
    Cell: ({ row: { original } }: { row: { original: ISwingModel } }) =>
      original.testerBallTrajectory,
  },
  {
    Header: 'Hand',
    accessor: 'session.hand',
  },
  {
    Header: 'Club Type',
    accessor: 'session.club.type',
  },
  {
    Header: 'Actions',
  },
];
