import { Typography, Paper } from '@material-ui/core';
import { FC } from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';

import styled from 'styled-components';

export const StyledContainer = styled(Paper)`
  margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
`;
export const StyledListItemText = styled(ListItemText)`
  flex: 1;
  .MuiListItemText-primary {
    font-weight: bold;
  }
`;

export const StyledTitle = styled(Typography)`
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
  color: ${({ theme }) => theme.palette.text.tertiary};
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
`;

export const StyledList = styled<
  FC<{ scrollable: boolean | undefined; maxHeight: number }>
>(List)`
  ${({ scrollable, maxHeight }) =>
    scrollable
      ? {
          maxHeight: `${maxHeight}px`,
          overflow: 'auto',
        }
      : null}
`;
