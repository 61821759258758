import { FC } from 'react';
import {
  Typography,
  Paper,
  FormControl,
  List,
  TextField,
} from '@material-ui/core';

import styled from 'styled-components';

export const StyledContainer = styled(Paper)`
  margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
`;
export const StyledListItemText = styled(Typography)`
  flex: 1;
  color: ${({ theme }) => `${theme.palette.text.secondary}`};
  font-size: 14px;
`;

export const StyledTitle = styled(Typography)`
  padding: ${({ theme }) => `${theme.spacing(2)}px`};
  color: ${({ theme }) => theme.palette.text.tertiary};
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
`;

export const StyledList = styled<
  FC<{ scrollable: boolean | undefined; maxHeight: number }>
>(List)`
  ${({ scrollable, maxHeight }) =>
    scrollable
      ? {
          maxHeight: `${maxHeight}px`,
          overflow: 'auto',
        }
      : null}
`;

export const StyledFormControl = styled(FormControl)`
  flex: 1;
`;

export const StyledTextField = styled(TextField)`
  flex: 1;
`;
