import { Column } from 'react-table';

export const gripsColumns: Column[] = [
  {
    Header: 'Updated',
    accessor: 'dateUpdated',
  },
  {
    Header: 'Manufacturer',
    accessor: 'make',
  },
  {
    Header: 'Model',
    accessor: 'model',
  },
  {
    Header: 'Description',
    accessor: 'description',
  },
  {
    Header: 'Mass (g)',
    accessor: 'mass_g',
  },
  {
    Header: 'SKU',
    accessor: 'sku',
  },
  {
    Header: 'Price',
    accessor: 'price',
  },
  {
    Header: 'Popularity',
    accessor: 'popularity',
  },
];
