import React, { MouseEvent } from 'react';
import { Hooks, HeaderProps, CellProps } from 'react-table';
import { Checkbox } from '@material-ui/core';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const selectionHook = (hooks: Hooks<any>): void => {
  hooks.allColumns.push((columns) => [
    {
      id: '_selector',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) => (
        <Checkbox {...getToggleAllRowsSelectedProps()} />
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Cell: ({ row }: CellProps<any>) => (
        <Checkbox
          {...row.getToggleRowSelectedProps()}
          onClick={(e: MouseEvent) => e.stopPropagation()}
        />
      ),
    },
    ...columns,
  ]);
};

export default selectionHook;
