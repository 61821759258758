import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grips } from '@caddyshack/common';

import { AppDispatch } from '../../../app/store';
import {
  fetchGrips,
  exportGrips,
  deleteGrips,
  editGrip,
  importGripsFile,
} from '../../store/grip.actions';
import {
  selectIsFetchingGripsPending,
  selectGripsPagination,
  selectGripsFilters,
  selectIsExportGripsStatusPending,
  selectIsDeleteGripsStatusPending,
  selectMappedGrips,
  selectIsImportGripsFileStatusPending,
} from '../../store/grip.selector';
import { GripsTable } from '../../components/GripsTable/GripsTable';
import { IGripModel } from '../../model/Grip';

export const GripsTableContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const grips = useSelector(selectMappedGrips);
  const gripsPagination = useSelector(selectGripsPagination);
  const gripsFilters = useSelector(selectGripsFilters);
  const isPending = useSelector(selectIsFetchingGripsPending);
  const isExportPending = useSelector(selectIsExportGripsStatusPending);
  const isDeletePending = useSelector(selectIsDeleteGripsStatusPending);
  const isImportGripsFilePending = useSelector(
    selectIsImportGripsFileStatusPending
  );

  useEffect(() => {
    dispatch(
      fetchGrips({
        pageSize: 50,
        page: 0,
      })
    );
  }, [dispatch]);

  const fetchGripsWithQuery = (searchQuery: Grips.SearchQuery) => {
    dispatch(
      fetchGrips({
        ...gripsFilters,
        ...searchQuery,
      })
    );
  };

  const onExport = () => dispatch(exportGrips(''));

  const onDelete = (ids: string[]) => dispatch(deleteGrips(ids));

  const onEdit = (grip: IGripModel) => dispatch(editGrip(grip));

  const onImport = (file: File) => dispatch(importGripsFile(file));

  return (
    <GripsTable
      grips={grips}
      isPending={isPending || isDeletePending || isImportGripsFilePending}
      pagination={gripsPagination}
      sorting={gripsFilters?.sort}
      filter={gripsFilters?.searchFilter}
      onQueryChange={fetchGripsWithQuery}
      onExport={onExport}
      isExportPending={isExportPending}
      onDelete={onDelete}
      onEdit={onEdit}
      onImport={onImport}
    />
  );
};
