import { AsyncState } from '../../app/models/AsyncState';
import { AsyncStatus } from '../../app/enums/AsyncStatus';
import { IProfileModel } from '../models/Auth';

export interface AuthState {
  register: AsyncState<null>;
  login: AsyncState<null>;
  recoverPassword: AsyncState<null>;
  resetPassword: AsyncState<null>;
  changePassword: AsyncState<null>;
  profile: AsyncState<IProfileModel | null>;
  authorization: AsyncState<boolean>;
}

export const authState: AuthState = {
  register: {
    status: AsyncStatus.Void,
    data: null,
  },
  login: {
    status: AsyncStatus.Void,
    data: null,
  },
  recoverPassword: {
    status: AsyncStatus.Void,
    data: null,
  },
  resetPassword: {
    status: AsyncStatus.Void,
    data: null,
  },
  changePassword: {
    status: AsyncStatus.Void,
    data: null,
  },
  profile: {
    status: AsyncStatus.Void,
    data: null,
  },
  authorization: {
    status: AsyncStatus.Void,
    data: false,
  },
};
