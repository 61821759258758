import { Shadows } from '@material-ui/core/styles/shadows';
import createMuiTheme, {
  Theme as MuiTheme,
  ThemeOptions as MuiThemeOptions,
} from '@material-ui/core/styles/createMuiTheme';

import shadows from './shadows';
import typography from './typography';
import {
  BACKGROUND_DARK,
  BACKGROUND_DEFAULT,
  BACKGROUND_PAPER,
  MAIN,
  TEXT_PRIMARY,
  TEXT_SECONDARY,
  TEXT_TERTIARY,
  BACKGROUND_DISABLED,
} from './colors';

type ThemeOptions = MuiThemeOptions;

export type Theme = MuiTheme;
const defaultTheme = createMuiTheme();

const theme = createMuiTheme({
  palette: {
    background: {
      dark: BACKGROUND_DARK,
      default: BACKGROUND_DEFAULT,
      paper: BACKGROUND_PAPER,
    },
    primary: {
      main: MAIN,
    },
    secondary: {
      main: MAIN,
    },
    text: {
      primary: TEXT_PRIMARY,
      secondary: TEXT_SECONDARY,
      tertiary: TEXT_TERTIARY,
    },
  },
  shadows: shadows as Shadows,
  typography,
  overrides: {
    MuiDrawer: {
      paper: {
        width: 210,
        [defaultTheme.breakpoints.up('lg')]: {
          top: 64,
          height: 'calc(100% - 64px)',
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: 8,
      },
    },

    MuiInputBase: {
      root: {
        '&.Mui-disabled': {
          backgroundColor: BACKGROUND_DISABLED,
        },

        '&.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderStyle: 'none',
        },
      },
    },
  },
  breakpoints: {
    brakpoints: { ...defaultTheme.breakpoints },
  },
} as ThemeOptions) as Theme;

export default theme;
