import * as yup from 'yup';

const messages = {
  number: 'Value must be a number',
};

export const gripDetailsModalValidation = yup.object().shape({
  id: yup.string().required(),
  dateUpdated: yup.string(),
  sku: yup.string().nullable(),
  price: yup.number().typeError(messages.number).nullable(),
  make: yup.string().required(),
  model: yup.string().required(),
  description: yup.string().nullable(),
  mass_g: yup.number().typeError(messages.number).nullable(),
});
