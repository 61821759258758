import React, { FC } from 'react';
import { Box, Container } from '@material-ui/core';

import { StyledPage } from '../../../app/components/DashboardLayout/DashboardLayout.style';
import { Unverified } from '../../components/Unverified/Unverified';

export const UnverifiedView: FC = () => (
  <StyledPage title="Unverified">
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      <Container maxWidth="sm">
        <Unverified />
      </Container>
    </Box>
  </StyledPage>
);
