import React, { FC } from 'react';
import AppleLogin from 'react-apple-login';

import { StyledButton, StyledAppleLogo } from './AppleLogin.style';

export const {
  REACT_APP_APPLE_CLIENT_ID,
  REACT_APP_APPLE_CLIENT_REDIRECT,
} = process.env;

export const ReactAppleLogin: FC = () => (
  <AppleLogin
    clientId={String(REACT_APP_APPLE_CLIENT_ID)}
    redirectURI={String(REACT_APP_APPLE_CLIENT_REDIRECT)}
    responseType="code"
    responseMode="form_post"
    scope="email"
    render={({ onClick }) => (
      <StyledButton
        variant="contained"
        fullWidth
        size="large"
        onClick={onClick}
      >
        <StyledAppleLogo />
        Sign in with Apple
      </StyledButton>
    )}
  />
);
