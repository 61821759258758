import { Grips } from '@caddyshack/common';
import { IGripModel } from '../model/Grip';

const getSortMappedKey = (key: string) => {
  switch (key) {
    case 'dateUpdated':
      return 'date_updated';
    default:
      return key;
  }
};

export const mapGripSearchQueryDto = (
  searchQuery: Grips.SearchQuery
): Grips.SearchQuery =>
  searchQuery?.sort
    ? {
        ...searchQuery,
        sort: {
          ...searchQuery.sort,
          key: getSortMappedKey(searchQuery.sort.key),
        },
      }
    : searchQuery;

export const mapGripModelToGripDto = (gripModel: IGripModel): Grips.GripDto => {
  type GripsDtoKey = keyof Grips.GripDto;

  return Object.keys(gripModel).reduce((object, key) => {
    const resultKey: GripsDtoKey = getSortMappedKey(key) as GripsDtoKey;
    const gripDtoKey: GripsDtoKey = key as GripsDtoKey;
    const value = gripModel[gripDtoKey];
    if (key !== resultKey) {
      return {
        ...object,
        [resultKey]: value,
      };
    }
    return {
      ...object,
      [key]: value,
    };
  }, {}) as Grips.GripDto;
};
