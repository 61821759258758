import React, { FC } from 'react';
import { Box, Container } from '@material-ui/core';

import { StyledPage } from '../../../app/components/DashboardLayout/DashboardLayout.style';
import { Verified } from '../../components/Verified/Verified';

export const VerifiedView: FC = () => (
  <StyledPage title="Verified">
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      <Container maxWidth="sm">
        <Verified />
      </Container>
    </Box>
  </StyledPage>
);
