import React from 'react';
import styled from 'styled-components';
import { Tooltip, Typography } from '@material-ui/core';
import { Info } from 'react-feather';

export const StyledInfoIcon = styled(Info)`
  margin-left: 10px;
  cursor: pointer;
`;

export const StyledTooltip = styled((props) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    min-width: 200px;
    background-color: ${({ theme }) => theme.palette.background.dark};
    border-radius: 10px;
    color: ${({ theme }) => theme.palette.text.primary};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 10px;
  }
`;

export const TooltipTitle = styled(Typography)`
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const TooltipBody = styled.span`
  display: flex;
  flex-direction: column;
`;

export const TooltipRow = styled.span`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const TooltipLabel = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #666666;
  flex: 1;
`;

export const TooltipValue = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.palette.text.primary};
  flex: 1;
  text-align: left;
`;
