import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import {
  FullHeightContainer,
  StyledPage,
} from '../../app/components/DashboardLayout/DashboardLayout.style';
import { ShaftConstantsTableContainer } from '../containers/ShaftConstantsTableContainer/ShaftConstantsTableContainer';
import { PageHeader } from '../../app/components/DashboardLayout/PageHeader/PageHeader';

export const ShaftConstantView: FC = () => {
  return (
    <StyledPage title="ShaftConstant">
      <FullHeightContainer maxWidth={false}>
        <Box mb={3}>
          <PageHeader title="Shaft Constants" />
        </Box>
        <Box
          flexDirection="column"
          height="100%"
          justifyContent="center"
          flex={1}
        >
          <ShaftConstantsTableContainer />
        </Box>
      </FullHeightContainer>
    </StyledPage>
  );
};
