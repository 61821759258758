import { Heads } from '@caddyshack/common';
import { IHeadModel } from '../model/Head';

const getSortMappedKey = (key: string) => {
  switch (key) {
    case 'dateUpdated':
      return 'date_updated';
    default:
      return key;
  }
};

export const mapHeadSearchQueryDto = (
  searchQuery: Heads.SearchQuery
): Heads.SearchQuery =>
  searchQuery?.sort
    ? {
        ...searchQuery,
        sort: {
          ...searchQuery.sort,
          key: getSortMappedKey(searchQuery.sort.key),
        },
      }
    : searchQuery;

export const mapHeadModelToHeadDto = (headModel: IHeadModel): Heads.HeadDto => {
  type HeadsDtoKey = keyof Heads.HeadDto;

  return Object.keys(headModel).reduce((object, key) => {
    const resultKey: HeadsDtoKey = getSortMappedKey(key) as HeadsDtoKey;
    const headDtoKey: HeadsDtoKey = key as HeadsDtoKey;
    const value = headModel[headDtoKey];

    if (key !== resultKey) {
      const mappedValue = value;

      return {
        ...object,
        [resultKey]: mappedValue,
      };
    }
    return {
      ...object,

      [key]: value,
    };
  }, {}) as Heads.HeadDto;
};
