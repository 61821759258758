import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Heads } from '@caddyshack/common';

import { AppDispatch } from '../../../app/store';
import {
  fetchHeads,
  exportHeads,
  deleteHeads,
  editHead,
  importHeadsFile,
} from '../../store/head.actions';
import {
  selectIsFetchingHeadsPending,
  selectHeadsPagination,
  selectHeadsFilters,
  selectIsExportHeadsStatusPending,
  selectIsDeleteHeadsStatusPending,
  selectMappedHeads,
  selectIsImportHeadsFileStatusPending,
} from '../../store/head.selector';
import { HeadsTable } from '../../components/HeadsTable/HeadsTable';
import { IHeadModel } from '../../model/Head';

export const HeadsTableContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const heads = useSelector(selectMappedHeads);
  const headsPagination = useSelector(selectHeadsPagination);
  const headsFilters = useSelector(selectHeadsFilters);
  const isPending = useSelector(selectIsFetchingHeadsPending);
  const isExportPending = useSelector(selectIsExportHeadsStatusPending);
  const isDeletePending = useSelector(selectIsDeleteHeadsStatusPending);
  const isImportHeadsFilePending = useSelector(
    selectIsImportHeadsFileStatusPending
  );

  useEffect(() => {
    dispatch(
      fetchHeads({
        pageSize: 50,
        page: 0,
      })
    );
  }, [dispatch]);

  const fetchHeadsWithQuery = (searchQuery: Heads.SearchQuery) => {
    dispatch(
      fetchHeads({
        ...headsFilters,
        ...searchQuery,
      })
    );
  };

  const onExport = () => dispatch(exportHeads(''));

  const onDelete = (ids: string[]) => dispatch(deleteHeads(ids));

  const onEdit = (head: IHeadModel) => dispatch(editHead(head));

  const onImport = (file: File) => dispatch(importHeadsFile(file));

  return (
    <HeadsTable
      heads={heads}
      isPending={isPending || isDeletePending || isImportHeadsFilePending}
      pagination={headsPagination}
      sorting={headsFilters?.sort}
      filter={headsFilters?.searchFilter}
      onQueryChange={fetchHeadsWithQuery}
      onExport={onExport}
      isExportPending={isExportPending}
      onDelete={onDelete}
      onEdit={onEdit}
      onImport={onImport}
    />
  );
};
