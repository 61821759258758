import React, { FC, MouseEvent, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Box,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import PasswordStrengthMeter from '../../../app/components/PasswordStrengthMeter/PasswordStrengthMeter';
import { registerFormValidationSchema } from './RegisterForm.validation';
import { Button } from '../../../app/components/Button/Button';
import { IRegisterDataModel } from '../../models/Auth';
import { StyledCard } from './RegisterForm.style';
import { LOGIN } from '../../../app/const/routes';

interface IRegisterForm {
  onSubmit: (formValues: IRegisterDataModel) => void;
  pending: boolean;
}

export const RegisterForm: FC<IRegisterForm> = ({ onSubmit, pending }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false);

  const handleClickShowPassword = () =>
    setIsPasswordVisible(!isPasswordVisible);

  const handleClickShowRepeatPassword = () =>
    setIsRepeatPasswordVisible(!isRepeatPasswordVisible);

  const handleMouseDownPassword = (event: MouseEvent) => event.preventDefault();

  return (
    <StyledCard>
      <Formik
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          password: '',
          repeatPassword: '',
        }}
        validationSchema={registerFormValidationSchema}
        onSubmit={({ email, firstName, lastName, password }) =>
          onSubmit({ email, firstName, lastName, password })
        }
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Box mb={2}>
              <Typography color="textPrimary" variant="h2">
                Create account
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body1">
                Use your email to create new account
              </Typography>
            </Box>
            <TextField
              disabled={pending}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              disabled={pending}
              error={Boolean(touched.firstName && errors.firstName)}
              fullWidth
              helperText={touched.firstName && errors.firstName}
              label="First name"
              margin="normal"
              name="firstName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.firstName}
              variant="outlined"
            />
            <TextField
              disabled={pending}
              error={Boolean(touched.lastName && errors.lastName)}
              fullWidth
              helperText={touched.lastName && errors.lastName}
              label="Last name"
              margin="normal"
              name="lastName"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.lastName}
              variant="outlined"
            />
            <TextField
              disabled={pending}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type={isPasswordVisible ? 'text' : 'password'}
              value={values.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    disabled={pending}
                  >
                    {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
            <PasswordStrengthMeter
              password={values.password}
              error={errors.password}
            />
            <TextField
              disabled={pending}
              error={Boolean(touched.repeatPassword && errors.repeatPassword)}
              fullWidth
              helperText={touched.repeatPassword && errors.repeatPassword}
              label="Repeat password"
              margin="normal"
              name="repeatPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              type={isRepeatPasswordVisible ? 'text' : 'password'}
              value={values.repeatPassword}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle repeat password visibility"
                    onClick={handleClickShowRepeatPassword}
                    onMouseDown={handleMouseDownPassword}
                    disabled={pending}
                  >
                    {isRepeatPasswordVisible ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                ),
              }}
            />
            <Box my={2}>
              <Button
                color="primary"
                disabled={pending}
                pending={pending}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign up now
              </Button>
            </Box>
            <Typography color="textSecondary" variant="body1">
              <Link component={RouterLink} to={LOGIN}>
                Sign in
              </Link>
            </Typography>
          </form>
        )}
      </Formik>
    </StyledCard>
  );
};
