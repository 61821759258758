import styled from 'styled-components';
import { Menu, Typography } from '@material-ui/core';

export const StyledMenu = styled(Menu)`
  .MuiList-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const StyledTypography = styled(Typography)`
  margin-left: 5px;
`;

export const IconWrapper = styled.span`
  margin-right: 10px;
  display: inline-flex;
`;
