import React, { FC } from 'react';

import TableSearchInput from '../../../app/components/Table/TableSearchInput/TableSearchInput';

import { StyledContainer } from './UsersToolbar.style';

interface IUsersToolbar {
  onSearch: (searchTerm: string) => void;
  isPending: boolean;
  filter?: string;
}

const UsersToolbar: FC<IUsersToolbar> = ({ onSearch, isPending, filter }) => {
  return (
    <StyledContainer>
      <TableSearchInput
        onSearch={onSearch}
        label="Search users"
        isPending={isPending}
        filter={filter}
        tooltipLabel="You can search users by email"
      />
    </StyledContainer>
  );
};

export default UsersToolbar;
