import React, { ReactElement, FC } from 'react';
import { StyledDialog } from './Modal.style';

export interface IModal {
  Content: () => ReactElement;
  Header: () => ReactElement;
  onClose: () => void;
  open: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

export const Modal: FC<IModal> = ({
  onClose,
  open,
  Header,
  Content,
  maxWidth,
}: IModal) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <StyledDialog
      onClose={handleClose}
      open={open}
      fullWidth
      maxWidth={maxWidth || 'lg'}
    >
      {Header()}
      {Content()}
    </StyledDialog>
  );
};
