import { createAsyncThunk } from '@reduxjs/toolkit';
import { Session, App } from '@caddyshack/common';
import withError from '../../app/utils/with-thunk-error';
import { openSuccessNotification } from '../../app/store/notifications/notifications.slice';

import {
  fetchSessions as fetchSessionsRequest,
  deleteSessions as deleteSessionsRequest,
  exportSessions as exportSessionsRequest,
} from '../api/session';

export const fetchSessions = createAsyncThunk(
  'sessions/fetch',
  withError(
    async (
      searchQuery?: Session.SearchQuery
    ): Promise<App.Page<Session.SessionDto[]>> => {
      return fetchSessionsRequest(searchQuery);
    }
  )
);

export const deleteSessions = createAsyncThunk(
  'sessions/delete',
  withError(
    async (ids: number[], { dispatch, getState }): Promise<string> => {
      const response = await deleteSessionsRequest(ids);
      const currentFilters = getState().session.sessions.data.filters;
      dispatch(openSuccessNotification('Sessions have been deleted'));
      dispatch(fetchSessions(currentFilters));

      return response;
    }
  )
);

export const exportSessions = createAsyncThunk(
  'sessions/exportSessions',
  withError(
    async (
      exportFilters: Session.ExportSessionsFiltersDto,
      { dispatch }
    ): Promise<void> => {
      const response = await exportSessionsRequest(exportFilters);
      dispatch(openSuccessNotification('Sessions have been exported'));

      return response;
    }
  )
);
