import { Checkbox, FormGroup } from '@material-ui/core';
import { Formik } from 'formik';
import React, { FC } from 'react';
import { Swing } from '@caddyshack/common';
import moment from 'moment';
import { Button } from '../../../app/components/Button/Button';
import {
  Row,
  StyledDateFormControl,
  StyledDateFormLabel,
  StyledForm,
  StyledFormControl,
  StyledFormControlLabel,
  StyledFormGroup,
  StyledFormLabel,
  StyledModalActions,
  StyledModalContent,
  StyledTypography,
} from './ExportDataModalForm.style';
import { DatePicker } from '../../../app/components/DatePicker/DatePicker';

interface IExportDataModalForm {
  onSubmit: (exportFilters: Swing.ExportSwingsFiltersDto) => void;
  isPending: boolean;
}

export const ExportDataModalForm: FC<IExportDataModalForm> = ({
  onSubmit,
  isPending,
}) => {
  return (
    <Formik
      initialValues={{
        dateFrom: null,
        dateTo: null,
        dataModeTraining: false,
        dataModeViewer: false,
        dataModeUser: false,
        algorithmValid: false,
        algorithmInvalid: false,
        testerValid: false,
        testerInvalid: false,
        handRight: false,
        handLeft: false,
        genderMale: false,
        genderFemale: false,
        skillLevelBeginner: false,
        skillLevelIntermediate: false,
        skillLevelAdvanced: false,
        driver: false,
        putter: false,
        pull: false,
        fade: false,
        straight: false,
        draw: false,
        push: false,
      }}
      onSubmit={(values) =>
        onSubmit({
          ...values,
          dateFrom: values.dateFrom ? moment(values.dateFrom).format() : null,
          dateTo: values.dateTo ? moment(values.dateTo).format() : null,
        })
      }
    >
      {({ setFieldValue, handleBlur, handleChange, handleSubmit, values }) => (
        <>
          <StyledModalContent>
            <StyledForm noValidate>
              <Row>
                <StyledDateFormControl>
                  <StyledDateFormLabel>Date</StyledDateFormLabel>
                  <StyledFormGroup>
                    <DatePicker
                      value={values.dateFrom}
                      onChange={(date) => setFieldValue('dateFrom', date)}
                      label="mm/dd/yyyy"
                      variant="outlined"
                      disabled={isPending}
                      size="small"
                      name="dateFrom"
                      maxDate={values.dateTo || undefined}
                    />
                    <StyledTypography>to</StyledTypography>
                    <DatePicker
                      value={values.dateTo}
                      onChange={(date) => setFieldValue('dateTo', date)}
                      label="mm/dd/yyyy"
                      variant="outlined"
                      disabled={isPending}
                      size="small"
                      name="dateTo"
                      minDate={values.dateFrom || undefined}
                    />
                  </StyledFormGroup>
                </StyledDateFormControl>
              </Row>
              <Row>
                <StyledFormControl>
                  <StyledFormLabel>Data Mode</StyledFormLabel>
                  <FormGroup>
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.dataModeTraining}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="dataModeTraining"
                        />
                      }
                      label="Training"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.dataModeViewer}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="dataModeViewer"
                        />
                      }
                      label="Viewer"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.dataModeUser}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="dataModeUser"
                        />
                      }
                      label="User"
                    />
                  </FormGroup>
                </StyledFormControl>
                <StyledFormControl>
                  <StyledFormLabel>Algorithm</StyledFormLabel>
                  <FormGroup>
                    <StyledFormControlLabel
                      control={
                        <Checkbox
                          checked={values.algorithmValid}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="algorithmValid"
                        />
                      }
                      label="Valid"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.algorithmInvalid}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="algorithmInvalid"
                        />
                      }
                      label="Invalid"
                    />
                  </FormGroup>
                </StyledFormControl>
                <StyledFormControl>
                  <StyledFormLabel>Tester</StyledFormLabel>
                  <FormGroup>
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.testerValid}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="testerValid"
                        />
                      }
                      label="Valid"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.testerInvalid}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="testerInvalid"
                        />
                      }
                      label="Invalid"
                    />
                  </FormGroup>
                </StyledFormControl>
              </Row>
              <Row>
                <StyledFormControl>
                  <StyledFormLabel>Hand</StyledFormLabel>
                  <FormGroup>
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.handRight}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="handRight"
                        />
                      }
                      label="Right"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.handLeft}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="handLeft"
                        />
                      }
                      label="Left"
                    />
                  </FormGroup>
                </StyledFormControl>
                <StyledFormControl>
                  <StyledFormLabel>Gender</StyledFormLabel>
                  <FormGroup>
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.genderFemale}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="genderFemale"
                        />
                      }
                      label="Female"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.genderMale}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="genderMale"
                        />
                      }
                      label="Male"
                    />
                  </FormGroup>
                </StyledFormControl>
                <StyledFormControl>
                  <StyledFormLabel>Skill Level</StyledFormLabel>
                  <FormGroup>
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.skillLevelBeginner}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="skillLevelBeginner"
                        />
                      }
                      label="Beginner"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.skillLevelIntermediate}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="skillLevelIntermediate"
                        />
                      }
                      label="Intermediate"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.skillLevelAdvanced}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="skillLevelAdvanced"
                        />
                      }
                      label="Advanced"
                    />
                  </FormGroup>
                </StyledFormControl>
              </Row>
              <Row>
                <StyledFormControl>
                  <StyledFormLabel>Club Type</StyledFormLabel>
                  <FormGroup>
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.driver}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="driver"
                        />
                      }
                      label="Driver"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.putter}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="putter"
                        />
                      }
                      label="Putter"
                    />
                  </FormGroup>
                </StyledFormControl>
                <StyledFormControl>
                  <StyledFormLabel>Ball Trajectory</StyledFormLabel>
                  <FormGroup>
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.pull}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="pull"
                        />
                      }
                      label="Pull"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.fade}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="fade"
                        />
                      }
                      label="Fade"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.straight}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="straight"
                        />
                      }
                      label="Straight"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.draw}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="draw"
                        />
                      }
                      label="Draw"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.push}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="push"
                        />
                      }
                      label="Push"
                    />
                  </FormGroup>
                </StyledFormControl>
                <StyledFormControl />
              </Row>
            </StyledForm>
          </StyledModalContent>
          <StyledModalActions>
            <Button
              pending={isPending}
              disabled={isPending}
              color="primary"
              variant="contained"
              onClick={() => handleSubmit()}
            >
              Export
            </Button>
          </StyledModalActions>
        </>
      )}
    </Formik>
  );
};
