import React, { FC } from 'react';

import { StyledContainer } from './GripsToolbar.style';
import TableSearchInput from '../../../app/components/Table/TableSearchInput/TableSearchInput';

import {
  TableToolbarButton,
  TableToolbarButtonsWrapper,
} from '../../../app/components/Table/Table.style';
import { Uploader } from '../../../app/components/Uploader/Uploader';

interface IGripsToolbar {
  onSearch: (searchTerm: string) => void;
  onExport: () => void;
  onImport: (file: File) => void;
  isExportPending: boolean;
  isPending: boolean;
  filter?: string;
}

const GripsToolbar: FC<IGripsToolbar> = ({
  onSearch,
  onExport,
  onImport,
  isExportPending,
  isPending,
  filter,
}) => {
  return (
    <StyledContainer>
      <TableSearchInput
        onSearch={onSearch}
        label="Search grips"
        isPending={isPending}
        filter={filter}
        tooltipLabel="You can search by model, manufacturer and SKU"
      />
      <TableToolbarButtonsWrapper>
        <TableToolbarButton
          color="primary"
          variant="contained"
          onClick={onExport}
          pending={isExportPending}
          disabled={isExportPending || isPending}
        >
          Export All
        </TableToolbarButton>
        <Uploader
          onChange={onImport}
          isPending={isPending}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        />
      </TableToolbarButtonsWrapper>
    </StyledContainer>
  );
};

export default GripsToolbar;
