import { createSlice } from '@reduxjs/toolkit';
import { shaftConstantState } from './shaftConstant.store';
import {
  fetchShaftConstants,
  deleteShaftConstants,
  editShaftConstant,
} from './shaftConstant.actions';
import { AsyncStatus } from '../../app/enums/AsyncStatus';

const slice = createSlice({
  name: 'shaftConstant',
  initialState: shaftConstantState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchShaftConstants.pending,
      (state, { meta: { arg } }) => ({
        ...state,
        shaftConstants: {
          ...state.shaftConstants,
          status: AsyncStatus.Pending,
          data: {
            ...state.shaftConstants.data,
            filters: arg,
          },
        },
      })
    );
    builder.addCase(fetchShaftConstants.fulfilled, (state, { payload }) => {
      if (payload) {
        const { pagination, content } = payload;
        return {
          ...state,
          shaftConstants: {
            status: AsyncStatus.Fulfilled,
            data: {
              ...state.shaftConstants.data,
              content,
              pagination,
            },
          },
        };
      }

      return state;
    });
    builder.addCase(fetchShaftConstants.rejected, (state) => ({
      ...state,
      shaftConstants: {
        ...state.shaftConstants,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(deleteShaftConstants.pending, (state) => ({
      ...state,
      shaftConstants: {
        ...state.shaftConstants,
        status: AsyncStatus.Pending,
      },
    }));

    builder.addCase(deleteShaftConstants.fulfilled, (state) => ({
      ...state,
      shaftConstants: {
        ...state.shaftConstants,
        status: AsyncStatus.Fulfilled,
      },
    }));

    builder.addCase(deleteShaftConstants.rejected, (state) => ({
      ...state,
      shaftConstants: {
        ...state.shaftConstants,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(editShaftConstant.pending, (state) => ({
      ...state,
      shaftConstants: {
        ...state.shaftConstants,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editShaftConstant.fulfilled, (state) => {
      return {
        ...state,
        shaftConstants: {
          ...state.shaftConstants,
          status: AsyncStatus.Fulfilled,
        },
      };
    });

    builder.addCase(editShaftConstant.rejected, (state) => ({
      ...state,
      shaftConstants: {
        ...state.shaftConstants,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export default slice.reducer;
