import styled from 'styled-components';
import { TextField, TextFieldProps } from '@material-ui/core';
import { Button, IButton } from '../../Button/Button';

export const StyledSearchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TableToolbarSearchButton = styled(Button)<IButton>`
  padding: 8px 18px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export const TableToolbarSearchInput = styled(TextField)<TextFieldProps>`
  min-width: 250px;

  .MuiOutlinedInput-root {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  ${({ theme }) => theme.breakpoints.down('xs')} {
    min-width: 100px;
  }
`;
