import { Session } from '@caddyshack/common';
import React, { FC } from 'react';

import TableSearchInput from '../../../app/components/Table/TableSearchInput/TableSearchInput';
import { StyledContainer } from './SessionsToolbar.style';
import { ExportDataModal } from '../ExportDataModal/ExportDataModal';
import { ISessionModel } from '../../model/Session';

interface ISessionsToolbar {
  onSearch: (searchTerm: string) => void;
  onExport: (exportFilters: Session.ExportSessionsFiltersDto) => void;
  isPending: boolean;
  isExportPending: boolean;
  filter?: string;
  sessions: ISessionModel[];
}

const SessionsToolbar: FC<ISessionsToolbar> = ({
  onSearch,
  isPending,
  filter,
  onExport,
  isExportPending,
  sessions,
}) => {
  return (
    <StyledContainer>
      <TableSearchInput
        onSearch={onSearch}
        label="Search sessions"
        isPending={isPending}
        filter={filter}
        tooltipLabel="You can search sessions by email"
      />
      <ExportDataModal
        onSubmit={onExport}
        isPending={isExportPending}
        sessions={sessions}
      />
    </StyledContainer>
  );
};

export default SessionsToolbar;
