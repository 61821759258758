import React, { FC } from 'react';
import { Tabs } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { selectRouterPathname } from '../../store/router/router.selector';
import { AppDispatch } from '../../store';

export const ConnectedTabs: FC = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>();
  const pathname = useSelector(selectRouterPathname);

  return (
    <Tabs
      value={pathname}
      onChange={(e, value) => dispatch(push(value))}
      aria-label="account tabs"
    >
      {children}
    </Tabs>
  );
};
