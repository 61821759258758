import React, { FC } from 'react';
import {
  StyledInfoIcon,
  StyledTooltip,
  TooltipBody,
  TooltipLabel,
  TooltipRow,
  TooltipTitle,
  TooltipValue,
} from './SwingsTableTooltip.style';
import { ISwingModel } from '../../model/Swing';

interface ISwingsTableTooltip {
  data: ISwingModel;
}

export const SwingsTableTooltip: FC<ISwingsTableTooltip> = ({ data }) => (
  <StyledTooltip
    placement="top"
    title={
      <>
        <TooltipTitle>DEMOGRAPHICS</TooltipTitle>
        <TooltipBody>
          <TooltipRow>
            <TooltipLabel>Gender</TooltipLabel>
            <TooltipValue>{data.session.gender}</TooltipValue>
          </TooltipRow>
          <TooltipRow>
            <TooltipLabel>Skill Level</TooltipLabel>
            <TooltipValue>{data.session.skill}</TooltipValue>
          </TooltipRow>
          <TooltipRow>
            <TooltipLabel>Height</TooltipLabel>
            <TooltipValue>{data.session.height} m</TooltipValue>
          </TooltipRow>
        </TooltipBody>
      </>
    }
  >
    <StyledInfoIcon />
  </StyledTooltip>
);
