import { createSelector } from '@reduxjs/toolkit';
import { RouterState } from 'connected-react-router';
import { Location } from 'history';
import { RootState } from '../index';

export const selectSelf = (state: RootState): RootState => state;

export const selectRouterState = createSelector(
  selectSelf,
  (state: RootState): RouterState => state.router
);

export const selectRouterLocation = createSelector(
  selectRouterState,
  (router: RouterState): Location => router.location
);

export const selectRouterPathname = createSelector(
  selectRouterLocation,
  (location: Location): string => location.pathname
);
