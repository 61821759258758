import { createSelector } from '@reduxjs/toolkit';
import { Grips, App } from '@caddyshack/common';
import moment from 'moment';
import { RootState } from '../../app/store';

import { GripState } from './grip.store';
import { AsyncState } from '../../app/models/AsyncState';
import { AsyncStatus } from '../../app/enums/AsyncStatus';
import { IPage } from '../../app/models/Pagination';
import { IGripModel } from '../model/Grip';

export const selectSelf = (state: RootState): RootState => state;

export const selectGripState = createSelector(
  selectSelf,
  (state: RootState): GripState => state.grip
);

export const selectGripsState = createSelector(
  selectGripState,
  (grip: GripState): AsyncState<IPage<Grips.GripDto[], Grips.SearchQuery>> =>
    grip.grips
);

export const selectGripsStatus = createSelector(
  selectGripsState,
  (
    fetchGrips: AsyncState<IPage<Grips.GripDto[], Grips.SearchQuery>>
  ): AsyncStatus => fetchGrips.status
);

export const selectIsFetchingGripsPending = createSelector(
  selectGripsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectGripsData = createSelector(
  selectGripsState,
  (
    gripPage: AsyncState<IPage<Grips.GripDto[], Grips.SearchQuery>>
  ): IPage<Grips.GripDto[], Grips.SearchQuery> => gripPage.data
);

export const selectGrips = createSelector(
  selectGripsData,
  (gripsData: IPage<Grips.GripDto[], Grips.SearchQuery>): Grips.GripDto[] =>
    gripsData.content
);

export const selectMappedGrips = createSelector(
  selectGrips,
  (gripsData: Grips.GripDto[]): IGripModel[] =>
    gripsData.map((grip) => ({
      ...grip,
      dateUpdated: moment(grip.date_updated).format('M/D/YYYY h:mm:ss A'),
    }))
);

export const selectGripsPagination = createSelector(
  selectGripsData,
  (gripsData: IPage<Grips.GripDto[], Grips.SearchQuery>): App.Pagination =>
    gripsData.pagination
);

export const selectGripsFilters = createSelector(
  selectGripsData,
  (
    gripsData: IPage<Grips.GripDto[], Grips.SearchQuery>
  ): Grips.SearchQuery | undefined => gripsData.filters
);

export const selectExportGripsState = createSelector(
  selectGripState,
  (grip: GripState): AsyncState<null> => grip.exportGrips
);

export const selectExportGripsStatus = createSelector(
  selectExportGripsState,
  (exportGrips: AsyncState<null>): AsyncStatus => exportGrips.status
);

export const selectIsExportGripsStatusPending = createSelector(
  selectExportGripsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectDeleteGripsState = createSelector(
  selectGripState,
  (grip: GripState): AsyncState<null> => grip.deleteGrips
);

export const selectDeleteGripsStatus = createSelector(
  selectDeleteGripsState,
  (deleteGrips: AsyncState<null>): AsyncStatus => deleteGrips.status
);

export const selectIsDeleteGripsStatusPending = createSelector(
  selectDeleteGripsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectImportGripsFileState = createSelector(
  selectGripState,
  (shaft: GripState): AsyncState<null> => shaft.importGripsFile
);

export const selectImportGripsFileStatus = createSelector(
  selectImportGripsFileState,
  (importGripsFile: AsyncState<null>): AsyncStatus => importGripsFile.status
);

export const selectIsImportGripsFileStatusPending = createSelector(
  selectImportGripsFileStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
