import React, { FC, MouseEvent } from 'react';
import { Divider, Hidden, MenuItem, ButtonBase } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { Settings as SettingsIcon, LogOut as LogOutIcon } from 'react-feather';

import { IconWrapper, StyledMenu, StyledTypography } from './TopBarMenu.style';

interface ITopBarMenu {
  onLogoutClick: () => void;
  onAccountClick: () => void;
  email?: string;
}

export const TopBarMenu: FC<ITopBarMenu> = ({
  onLogoutClick,
  onAccountClick,
  email,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnLogoutClick = () => {
    handleClose();
    onLogoutClick();
  };

  const handleOnAccountClick = () => {
    handleClose();
    onAccountClick();
  };

  return (
    <>
      <ButtonBase
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
      >
        <AccountCircle />
        {email && (
          <Hidden mdDown>
            <StyledTypography>{email}</StyledTypography>
          </Hidden>
        )}
      </ButtonBase>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{
          vertical: -35,
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleOnAccountClick}>
          <IconWrapper>
            <SettingsIcon size={18} />
          </IconWrapper>
          Account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleOnLogoutClick}>
          <IconWrapper>
            <LogOutIcon size={18} />
          </IconWrapper>
          Logout
        </MenuItem>
      </StyledMenu>
    </>
  );
};
