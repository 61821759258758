import { createSlice } from '@reduxjs/toolkit';
import { shaftState } from './shaft.store';
import {
  fetchShafts,
  exportShafts,
  deleteShafts,
  editShaft,
  importShaftsFile,
} from './shaft.actions';
import { AsyncStatus } from '../../app/enums/AsyncStatus';

const slice = createSlice({
  name: 'shaft',
  initialState: shaftState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchShafts.pending, (state, { meta: { arg } }) => ({
      ...state,
      shafts: {
        ...state.shafts,
        status: AsyncStatus.Pending,
        data: {
          ...state.shafts.data,
          filters: arg,
        },
      },
    }));
    builder.addCase(fetchShafts.fulfilled, (state, { payload }) => {
      if (payload) {
        const { pagination, content } = payload;
        return {
          ...state,
          shafts: {
            status: AsyncStatus.Fulfilled,
            data: {
              ...state.shafts.data,
              content,
              pagination,
            },
          },
        };
      }

      return state;
    });
    builder.addCase(fetchShafts.rejected, (state) => ({
      ...state,
      shafts: {
        ...state.shafts,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(exportShafts.pending, (state) => ({
      ...state,
      exportShafts: {
        ...state.exportShafts,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(exportShafts.fulfilled, (state) => ({
      ...state,
      exportShafts: {
        ...state.exportShafts,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(exportShafts.rejected, (state) => ({
      ...state,
      exportShafts: {
        ...state.exportShafts,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(deleteShafts.pending, (state) => ({
      ...state,
      deleteShafts: {
        ...state.deleteShafts,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(deleteShafts.fulfilled, (state) => ({
      ...state,
      deleteShafts: {
        ...state.deleteShafts,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(deleteShafts.rejected, (state) => ({
      ...state,
      deleteShafts: {
        ...state.deleteShafts,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(importShaftsFile.pending, (state) => ({
      ...state,
      importShaftsFile: {
        ...state.importShaftsFile,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(importShaftsFile.fulfilled, (state) => ({
      ...state,
      importShaftsFile: {
        ...state.importShaftsFile,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(importShaftsFile.rejected, (state) => ({
      ...state,
      importShaftsFile: {
        ...state.importShaftsFile,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(editShaft.pending, (state) => ({
      ...state,
      shafts: {
        ...state.shafts,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editShaft.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          shafts: {
            ...state.shafts,
            status: AsyncStatus.Fulfilled,
            data: {
              ...state.shafts.data,
              content: state.shafts.data.content.map((shaft) =>
                shaft.id === payload.id ? payload : shaft
              ),
            },
          },
        };
      }

      return state;
    });
    builder.addCase(editShaft.rejected, (state) => ({
      ...state,
      shafts: {
        ...state.shafts,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export default slice.reducer;
