import React, { FC } from 'react';
import {
  TextField,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { Formik } from 'formik';
import { editShaftConstantValidationSchema } from './EditShaftConstantDialog.validation';

export interface IEditShaftConstantDialog {
  id: string;
  keepMounted: boolean;
  open: boolean;
  selectedRowValue: number | undefined;
  selectedRowName: string | undefined;
  onClose: (value?: number) => void;
}

const EditShaftConstantDialog: FC<IEditShaftConstantDialog> = ({
  onClose,
  open,
  selectedRowValue,
  selectedRowName,
  ...other
}: IEditShaftConstantDialog) => {
  const handleCancel = () => {
    onClose();
  };

  const onSubmit = (value: number) => {
    onClose(value);
  };

  return (
    <Dialog disableBackdropClick maxWidth="sm" fullWidth open={open} {...other}>
      <Formik
        initialValues={{
          shaftConstant: selectedRowValue || 0,
        }}
        validationSchema={editShaftConstantValidationSchema}
        onSubmit={({ shaftConstant }) => onSubmit(shaftConstant)}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>
              <Typography>
                <strong>{selectedRowName}</strong>
              </Typography>
            </DialogTitle>
            <DialogContent dividers>
              <TextField
                fullWidth
                value={values.shaftConstant}
                error={Boolean(touched.shaftConstant && errors.shaftConstant)}
                helperText={touched.shaftConstant && errors.shaftConstant}
                name="shaftConstant"
                type="number"
                label="Shaft constant value"
                variant="outlined"
                size="small"
                margin="normal"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel} color="primary" variant="outlined">
                Cancel
              </Button>
              <Button type="submit" color="primary" variant="contained">
                Ok
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};
export default EditShaftConstantDialog;
