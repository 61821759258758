import { Column } from 'react-table';
import { IUserModel } from '../../model/User';

export const usersColumns: Column[] = [
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'First name',
    accessor: 'firstName',
  },
  {
    Header: 'Last name',
    accessor: 'lastName',
  },
  {
    Header: 'Phone number',
    accessor: 'phone',
  },
  {
    Header: 'Roles',
    accessor: 'roles',
    Cell: ({ row: { original } }: { row: { original: IUserModel } }) =>
      original.rolesString,
  },
  {
    Header: 'Actions',
  },
];
