import React, { FC, useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { Box, IconButton } from '@material-ui/core';
import { Formik } from 'formik';
import { Modal } from '../../../app/components/Modal/Modal';
import { ModalDetailsBox } from '../../../app/components/Modal/ModalDetailsBox/ModalDetailsBox';
import { ModalDetailsBoxForm } from '../../../app/components/Modal/ModalDetailsBoxForm/ModalDetailsBoxForm';
import { shaftsDetailsModalValidation } from './ShaftsDetailsModal.validation';
import { IShaftModel } from '../../model/Shaft';

import {
  StyledModalContent,
  StyledDialogTitleContainer,
  StyledBoxLeft,
  StyledBoxRight,
  StyledManufacturerTitle,
  StyledTitle,
  StyledForm,
} from './ShaftsDetailsModal.style';

import {
  boolDropdownOptions,
  rangeDropdownOptions,
  typesDropdownOptions,
} from './ShaftsDetailsModal.data';

export interface IShaftsDetailsModal {
  onClose: () => void;
  open: boolean;
  shaft: IShaftModel;
  onEdit: (shaft: IShaftModel) => void;
}

export const ShaftsDetailsModal: FC<IShaftsDetailsModal> = ({
  onClose,
  open,
  shaft,
  onEdit,
}: IShaftsDetailsModal) => {
  const [isEditMode, setIsEditMode] = useState(false);
  // eslint-disable-next-line
  const formRef = useRef<any>();

  const handleClose = () => {
    onClose();
  };
  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleCancel = () => {
    setIsEditMode(false);
  };

  const handleSave = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleFormSubmit = (values: IShaftModel) => {
    onEdit(values);
    setIsEditMode(false);
    onClose();
  };

  const ModalHeader = () => (
    <StyledDialogTitleContainer disableTypography>
      <Box>
        <StyledManufacturerTitle variant="h6">
          {shaft?.manufacturername}
        </StyledManufacturerTitle>
        <StyledTitle variant="h4">{shaft?.modelname}</StyledTitle>
      </Box>
      {isEditMode ? (
        <Box>
          <Button
            onClick={handleSave}
            color="primary"
            variant="contained"
            style={{ marginRight: 15 }}
          >
            SAVE
          </Button>

          <Button onClick={handleCancel} color="primary" variant="outlined">
            Cancel
          </Button>
        </Box>
      ) : (
        <Box>
          <IconButton onClick={handleEdit}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </StyledDialogTitleContainer>
  );

  const structureInformation = [
    { label: 'ID', value: shaft.id, key: 'id', disabled: true },
    {
      label: 'Date Updated',
      value: shaft.dateUpdated,
      key: 'dateUpdated',
      disabled: true,
    },
  ];

  const shopifyInformation = [
    { label: 'SKU', value: shaft.sku, key: 'sku', disabled: true },
    { label: 'Price', value: shaft.price, key: 'price' },
  ];

  const technicalInformation = [
    {
      label: 'Model',
      value: shaft.modelname,
      key: 'modelname',
      requiredLabel: true,
    },
    {
      label: 'Manufacturer',
      value: shaft.manufacturername,
      key: 'manufacturername',
      requiredLabel: true,
    },
    { label: 'Flex', value: shaft.flexname, key: 'flexname' },
    {
      label: 'Type String',
      value: shaft.type_string,
      key: 'type_string',
      dropdownOptions: typesDropdownOptions,
    },
    {
      label: 'OEM Aftermarket',
      value: shaft.oem_aftermarket ? 'Yes' : 'No',
      key: 'oem_aftermarket',
      dropdownOptions: boolDropdownOptions,
    },
    { label: 'Length (inches)', value: shaft.length_in, key: 'length_in' },
    { label: 'Butt Od (inches)', value: shaft.butt_od_in, key: 'butt_od_in' },
    { label: 'Tip Od (inches)', value: shaft.tip_od_in, key: 'tip_od_in' },
    {
      label: 'Parallel Length (inches)',
      value: shaft.parallel_length_in,
      key: 'parallel_length_in',
    },
    { label: 'Mass (g)', value: shaft.massG, key: 'massG' },
    {
      label: 'Frequency CPM 5in Clamp',
      value: shaft.frequency_cpm_5in_clamp,
      key: 'frequency_cpm_5in_clamp',
    },
    { label: 'Torque (deg)', value: shaft.torque_deg, key: 'torque_deg' },
    {
      label: 'Balance Point From Tip',
      value: shaft.balance_point_from_tip,
      key: 'balance_point_from_tip',
    },
    { label: 'Butt Ei', value: shaft.butt_ei, key: 'butt_ei' },
    { label: 'Mid Ei', value: shaft.mid_ei, key: 'mid_ei' },
    { label: 'Tip Ei', value: shaft.tip_ei, key: 'tip_ei' },
    { label: 'S3 Launch', value: shaft.s3_launch_deg, key: 's3_launch_deg' },
    {
      label: 'S3 Launch Rel',
      value: shaft.s3_launch_rel,
      key: 's3_launch_rel',
      dropdownOptions: rangeDropdownOptions,
    },
    { label: 'Min Build Fm', value: shaft.min_build_fm, key: 'min_build_fm' },
    { label: 'Max Build Fm', value: shaft.max_build_fm, key: 'max_build_fm' },
    {
      label: 'Total Adjustments',
      value: shaft.total_adjustments,
      key: 'total_adjustments',
    },
    {
      label: 'Min Fitting Fm',
      value: shaft.min_fitting_fm,
      key: 'min_fitting_fm',
    },
    {
      label: 'Max Fitting Fm',
      value: shaft.max_fitting_fm,
      key: 'max_fitting_fm',
    },
    { label: 'Wedge Step', value: shaft.wedge_step, key: 'wedge_step' },
    {
      label: 'Current',
      value: shaft.iscurrent ? 'Yes' : 'No',
      key: 'iscurrent',
      dropdownOptions: boolDropdownOptions,
    },
    {
      label: 'Graphite',
      value: shaft.isgraphite ? 'Yes' : 'No',
      key: 'isgraphite',
      dropdownOptions: boolDropdownOptions,
    },
    {
      label: 'Parallel Tip',
      value: shaft.isparallel ? 'Yes' : 'No',
      key: 'isparallel',
      dropdownOptions: boolDropdownOptions,
    },

    { label: 'Man Spin', value: shaft.man_spin_test, key: 'man_spin_test' },
    {
      label: 'Man Launch',
      value: shaft.man_launch_test,
      key: 'man_launch_test',
    },
    { label: 'Basic Speed', value: shaft.basic_speed, key: 'basic_speed' },
    {
      label: 'Max Tipping (inches)',
      value: shaft.max_tipping_in,
      key: 'max_tipping_in',
    },
    {
      label: 'CPM Per Inch Tipping',
      value: shaft.cpmperintipping,
      key: 'cpmperintipping',
    },
  ];

  const ModalContent = () => {
    return (
      <StyledModalContent>
        {isEditMode ? (
          <Formik
            initialValues={shaft}
            validationSchema={shaftsDetailsModalValidation}
            onSubmit={handleFormSubmit}
            innerRef={formRef}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              ...rest
            }) => (
              <StyledForm onSubmit={handleSubmit} noValidate>
                <StyledBoxLeft>
                  <ModalDetailsBoxForm
                    rows={structureInformation}
                    title="Structure Information"
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    values={values}
                    {...rest}
                  />

                  <ModalDetailsBoxForm
                    rows={shopifyInformation}
                    title="Shopify Information"
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    values={values}
                    {...rest}
                  />
                </StyledBoxLeft>
                <StyledBoxRight>
                  <ModalDetailsBoxForm
                    rows={technicalInformation}
                    title="Technical Information"
                    scrollable
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    values={values}
                    {...rest}
                  />
                </StyledBoxRight>
              </StyledForm>
            )}
          </Formik>
        ) : (
          <>
            <StyledBoxLeft>
              <ModalDetailsBox
                rows={structureInformation}
                title="Structure Information"
              />

              <ModalDetailsBox
                rows={shopifyInformation}
                title="Shopify Information"
              />
            </StyledBoxLeft>
            <StyledBoxRight>
              <ModalDetailsBox
                rows={technicalInformation}
                title="Technical Information"
                scrollable
              />
            </StyledBoxRight>
          </>
        )}
      </StyledModalContent>
    );
  };

  return (
    <Modal
      Content={ModalContent}
      Header={ModalHeader}
      onClose={handleClose}
      open={open}
    />
  );
};
