import React, { FC, useState } from 'react';
import { Heads, App } from '@caddyshack/common';
import HeadsToolbar from '../HeadsToolbar/HeadsToolbar';
import { Table } from '../../../app/components/Table/Table';
import { HeadsDetailsModal } from '../HeadsDetailsModal/HeadsDetailsModal';

import { StyledCard } from './HeadsTable.style';
import { TableToolbarButton } from '../../../app/components/Table/Table.style';
import { headsColumns } from './HeadsTable.columns';
import { IHeadModel } from '../../model/Head';

interface IHeadTable {
  heads: IHeadModel[];
  isPending: boolean;
  pagination: App.Pagination;
  sorting?: {
    key: string;
    order: App.SortOrder;
  };
  filter?: string;
  onQueryChange: (searchQuery: Heads.SearchQuery) => void;
  onExport: () => void;
  isExportPending: boolean;
  onDelete: (ids: string[]) => void;
  onEdit: (head: IHeadModel) => void;
  onImport: (file: File) => void;
}

export const HeadsTable: FC<IHeadTable> = ({
  heads,
  isPending,
  pagination,
  sorting,
  filter,
  onQueryChange,
  onExport,
  isExportPending,
  onDelete,
  onEdit,
  onImport,
}: IHeadTable) => {
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedHeadDetails, setSelectedHeadDetails] = useState(heads[0]);

  const handleModalOpen = (row: IHeadModel) => {
    setSelectedHeadDetails(row);
    setDetailsModalOpen(true);
  };
  const handleModalClose = () => {
    setDetailsModalOpen(false);
  };

  const Toolbar = () => (
    <HeadsToolbar
      onSearch={(searchTerm) =>
        onQueryChange({ searchFilter: searchTerm, page: 0 })
      }
      onExport={onExport}
      onImport={onImport}
      isExportPending={isExportPending}
      isPending={isPending}
      filter={filter}
    />
  );

  const SelectedToolbar = (selectedHeads: IHeadModel[]) => (
    <TableToolbarButton
      color="primary"
      disabled={isPending}
      variant="contained"
      onClick={() =>
        onDelete(
          selectedHeads.map((selectedHead: IHeadModel) => selectedHead.id)
        )
      }
    >
      {`Delete ${selectedHeads.length} ${
        selectedHeads.length === 1 ? 'head' : 'heads'
      }`}
    </TableToolbarButton>
  );

  const tableSorting = sorting
    ? {
        id: sorting.key,
        desc: sorting.order === 'desc',
      }
    : undefined;

  return (
    <StyledCard>
      <Table
        loading={isPending}
        columns={headsColumns}
        data={heads}
        pagination={pagination}
        sorting={tableSorting}
        onPaginationChange={onQueryChange}
        onSortChange={(sort) =>
          sort ? onQueryChange({ sort }) : onQueryChange({ sort: undefined })
        }
        Toolbar={Toolbar}
        SelectedToolbar={SelectedToolbar}
        disabledSorting={['Actions']}
        onRowClick={(row: IHeadModel) => handleModalOpen(row)}
      />
      {selectedHeadDetails && (
        <HeadsDetailsModal
          onClose={handleModalClose}
          open={detailsModalOpen}
          head={selectedHeadDetails}
          onEdit={onEdit}
        />
      )}
    </StyledCard>
  );
};
