import React, { FC } from 'react';
import { Box, Container } from '@material-ui/core';

import { StyledPage } from '../../../app/components/DashboardLayout/DashboardLayout.style';
import { LoginFormContainer } from '../../containers/LoginFormContainer/LoginFormContainer';

export const LoginView: FC = () => {
  return (
    <StyledPage title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <LoginFormContainer />
        </Container>
      </Box>
    </StyledPage>
  );
};
