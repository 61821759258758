import { createSelector } from '@reduxjs/toolkit';
import { Shafts, App } from '@caddyshack/common';
import moment from 'moment';
import { RootState } from '../../app/store';

import { ShaftState } from './shaft.store';
import { AsyncState } from '../../app/models/AsyncState';
import { AsyncStatus } from '../../app/enums/AsyncStatus';
import { IPage } from '../../app/models/Pagination';
import { IShaftModel } from '../model/Shaft';

export const selectSelf = (state: RootState): RootState => state;

export const selectShaftState = createSelector(
  selectSelf,
  (state: RootState): ShaftState => state.shaft
);

export const selectShaftsState = createSelector(
  selectShaftState,
  (
    shaft: ShaftState
  ): AsyncState<IPage<Shafts.ShaftDto[], Shafts.SearchQuery>> => shaft.shafts
);

export const selectShaftsStatus = createSelector(
  selectShaftsState,
  (
    fetchShafts: AsyncState<IPage<Shafts.ShaftDto[], Shafts.SearchQuery>>
  ): AsyncStatus => fetchShafts.status
);

export const selectIsFetchingShaftsPending = createSelector(
  selectShaftsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectShaftsData = createSelector(
  selectShaftsState,
  (
    shaftPage: AsyncState<IPage<Shafts.ShaftDto[], Shafts.SearchQuery>>
  ): IPage<Shafts.ShaftDto[], Shafts.SearchQuery> => shaftPage.data
);

export const selectShafts = createSelector(
  selectShaftsData,
  (
    shaftsData: IPage<Shafts.ShaftDto[], Shafts.SearchQuery>
  ): Shafts.ShaftDto[] => shaftsData.content
);

export const selectMappedShafts = createSelector(
  selectShafts,
  (shaftsData: Shafts.ShaftDto[]): IShaftModel[] =>
    shaftsData.map((shaft) => ({
      ...shaft,
      dateUpdated: moment(shaft.date_updated).format('M/D/YYYY h:mm:ss A'),
      massG: Number(shaft.mass_g).toFixed(2),
    }))
);

export const selectShaftsPagination = createSelector(
  selectShaftsData,
  (shaftsData: IPage<Shafts.ShaftDto[], Shafts.SearchQuery>): App.Pagination =>
    shaftsData.pagination
);

export const selectShaftsFilters = createSelector(
  selectShaftsData,
  (
    shaftsData: IPage<Shafts.ShaftDto[], Shafts.SearchQuery>
  ): Shafts.SearchQuery | undefined => shaftsData.filters
);

export const selectExportShaftsState = createSelector(
  selectShaftState,
  (shaft: ShaftState): AsyncState<null> => shaft.exportShafts
);

export const selectExportShaftsStatus = createSelector(
  selectExportShaftsState,
  (exportShafts: AsyncState<null>): AsyncStatus => exportShafts.status
);

export const selectIsExportShaftsStatusPending = createSelector(
  selectExportShaftsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectDeleteShaftsState = createSelector(
  selectShaftState,
  (shaft: ShaftState): AsyncState<null> => shaft.deleteShafts
);

export const selectDeleteShaftsStatus = createSelector(
  selectDeleteShaftsState,
  (deleteShafts: AsyncState<null>): AsyncStatus => deleteShafts.status
);

export const selectIsDeleteShaftsStatusPending = createSelector(
  selectDeleteShaftsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectImportShaftsFileState = createSelector(
  selectShaftState,
  (shaft: ShaftState): AsyncState<null> => shaft.importShaftsFile
);

export const selectImportShaftsFileStatus = createSelector(
  selectImportShaftsFileState,
  (importShaftsFile: AsyncState<null>): AsyncStatus => importShaftsFile.status
);

export const selectIsImportShaftsFileStatusPending = createSelector(
  selectImportShaftsFileStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
