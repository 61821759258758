import React, { FC } from 'react';
import { Box, Card, CardHeader, Divider } from '@material-ui/core';

import { SecurityFormContainer } from '../../containers/SecurityFormContainer/SecurityFormContainer';

export const SecurityView: FC = () => (
  <Box
    display="flex"
    flexDirection="column"
    height="100%"
    justifyContent="center"
  >
    <Card>
      <CardHeader title="Security" />
      <Divider />
      <SecurityFormContainer />
    </Card>
  </Box>
);
