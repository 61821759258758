import React, { FC } from 'react';
import { Box } from '@material-ui/core';
import {
  FullHeightContainer,
  StyledPage,
} from '../../../app/components/DashboardLayout/DashboardLayout.style';
import { PageHeader } from '../../../app/components/DashboardLayout/PageHeader/PageHeader';
import { UsersTableContainer } from '../../containers/UsersTableContainer/UsersTableContainer';

export const UsersView: FC = () => {
  return (
    <StyledPage title="Users">
      <FullHeightContainer maxWidth={false}>
        <Box mb={3}>
          <PageHeader title="Users" />
        </Box>
        <Box
          flexDirection="column"
          height="100%"
          justifyContent="center"
          flex={1}
        >
          <UsersTableContainer />
        </Box>
      </FullHeightContainer>
    </StyledPage>
  );
};
