import React, { FC } from 'react';
import { Formik } from 'formik';
import { CardContent, TextField, Box, Divider, Grid } from '@material-ui/core';
import { Auth } from '@caddyshack/common';
import NumberFormat from 'react-number-format';
import {
  profileFormValidationSchema,
  phoneNumberFormat,
} from './ProfileForm.validation';
import { Button } from '../../../app/components/Button/Button';

interface IProfileForm {
  // eslint-disable-next-line
  onSubmit: (formValues: any) => void;
  pending: boolean;
  userProfileData: Auth.UserDto | null;
}

export const ProfileForm: FC<IProfileForm> = ({
  onSubmit,
  pending,
  userProfileData,
}) => (
  <Formik
    initialValues={{
      firstName: userProfileData?.firstName || '',
      lastName: userProfileData?.lastName || '',
      phone: userProfileData?.phone || '',
      email: userProfileData?.email || '',
    }}
    validationSchema={profileFormValidationSchema}
    onSubmit={({ firstName, lastName, phone }) => {
      onSubmit({
        firstName,
        lastName,
        phone,
      });
    }}
    enableReinitialize
  >
    {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
      <form onSubmit={handleSubmit} noValidate>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                disabled={pending}
                error={Boolean(touched.firstName && errors.firstName)}
                fullWidth
                helperText={touched.firstName && errors.firstName}
                label="First name"
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                disabled={pending}
                error={Boolean(touched.lastName && errors.lastName)}
                fullWidth
                helperText={touched.lastName && errors.lastName}
                label="Last name"
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                disabled
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email address"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <NumberFormat
                customInput={TextField}
                format={phoneNumberFormat}
                mask="_"
                disabled={pending}
                error={Boolean(touched.phone && errors.phone)}
                fullWidth
                helperText={touched.phone && errors.phone}
                label="Phone number"
                name="phone"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.phone}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="flex-end" padding={2}>
          <Button
            color="primary"
            disabled={pending}
            pending={pending}
            type="submit"
            variant="contained"
          >
            Save changes
          </Button>
        </Box>
      </form>
    )}
  </Formik>
);
