import { Swing } from '@caddyshack/common';
import { AsyncState } from '../../app/models/AsyncState';
import { AsyncStatus } from '../../app/enums/AsyncStatus';
import { IPage } from '../../app/models/Pagination';

export interface SwingState {
  swings: AsyncState<IPage<Swing.SwingDto[], Swing.SearchQuery>>;
  exportSwings: AsyncState<null>;
}

export const swingState: SwingState = {
  swings: {
    status: AsyncStatus.Void,
    data: {
      content: [],
      pagination: {
        page: 0,
        pageSize: 50,
        totalElements: 0,
      },
      filters: {},
    },
  },
  exportSwings: {
    status: AsyncStatus.Void,
    data: null,
  },
};
