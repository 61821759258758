import React, { FC } from 'react';
import { Box, Link, Typography } from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import { StyledCard, StyledIcon, StyledTypography } from './Unverified.style';
import { LOGIN } from '../../../app/const/routes';

export const Unverified: FC = () => (
  <StyledCard>
    <Box mb={2}>
      <StyledIcon color="primary" />
      <Typography color="textPrimary" variant="h2">
        Unverified
      </Typography>
      <Typography color="textSecondary" gutterBottom variant="body1">
        Your account has been not verified.
      </Typography>
    </Box>
    <Typography color="textSecondary" gutterBottom variant="body1">
      Try to use register verification link again or contact with the
      administrator.
    </Typography>
    <StyledTypography color="textSecondary" variant="body1">
      <Link component={RouterLink} to={LOGIN}>
        Back to Sign in
      </Link>
    </StyledTypography>
  </StyledCard>
);
