import { AxiosResponse } from 'axios';
import { openErrorNotification } from '../store/notifications/notifications.slice';
import { IApiError } from '../models/ApiError';

export default <Args, Returned>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payloadCreator: (args: Args, options?: any) => Promise<Returned>
) => {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,consistent-return,@typescript-eslint/no-explicit-any
  return async (args: Args, options?: any) => {
    try {
      return await payloadCreator(args, options);
    } catch (exception) {
      if (exception && exception.response) {
        const { data }: AxiosResponse = exception.response;
        const { messages }: IApiError = data;

        if (messages && messages.length > 0) {
          messages.forEach(({ message }) => {
            options.dispatch(openErrorNotification(message));
          });
        } else {
          options.dispatch(openErrorNotification('Something went wrong'));
        }
      }

      if (exception && exception.response) {
        throw options.rejectWithValue(exception.response.data);
      }

      if (exception) {
        options.dispatch(openErrorNotification('Something went wrong'));
        throw exception;
      }
    }
  };
};
