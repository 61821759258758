import styled from 'styled-components';
import { Typography, DialogTitle } from '@material-ui/core';

export const StyledDialogTitleContainer = styled(DialogTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTitle = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 5px;
`;

export const StyledSubtitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.tertiary};
`;
