import React, { FC } from 'react';

import { Swing } from '@caddyshack/common';
import TableSearchInput from '../../../app/components/Table/TableSearchInput/TableSearchInput';
import { StyledContainer } from './SwingsToolbar.style';
import { ExportDataModal } from '../ExportDataModal/ExportDataModal';

interface ISwingsToolbar {
  onSearch: (searchTerm: string) => void;
  onExport: (exportFilters: Swing.ExportSwingsFiltersDto) => void;
  isPending: boolean;
  isExportPending: boolean;
  filter?: string;
}

const SwingsToolbar: FC<ISwingsToolbar> = ({
  onSearch,
  isPending,
  filter,
  onExport,
  isExportPending,
}) => {
  return (
    <StyledContainer>
      <TableSearchInput
        onSearch={onSearch}
        label="Search swings"
        isPending={isPending}
        filter={filter}
        tooltipLabel="You can search swings by email"
      />
      <ExportDataModal onSubmit={onExport} isPending={isExportPending} />
    </StyledContainer>
  );
};

export default SwingsToolbar;
