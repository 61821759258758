import { SessionTesterDetails } from '@caddyshack/common/dist/session';
import moment from 'moment';
import { Column } from 'react-table';
import { ISessionModel } from '../../model/Session';

export const sessionsColumns: Column[] = [
  {
    Header: 'Email',
    accessor: 'session.email',
    Cell: ({
      row: { original },
    }: {
      row: { original: SessionTesterDetails };
    }) => original.email,
  },
  {
    Header: 'Date',
    accessor: 'session.date_updated',
    Cell: ({ row: { original } }: { row: { original: ISessionModel } }) =>
      moment(original.date_updated).format('M/D/YYYY hh:mm:ss A'),
  },
  {
    Header: 'Data Mode',
    accessor: 'session.data_mode',
    Cell: ({ row: { original } }: { row: { original: ISessionModel } }) =>
      original.data_mode,
  },
  {
    Header: 'Groove Number',
    accessor: 'session.groove_number',
    Cell: ({ row: { original } }: { row: { original: ISessionModel } }) =>
      original.groove_number,
  },
  {
    Header: 'Hand',
    accessor: 'session.hand',
    Cell: ({
      row: { original },
    }: {
      row: { original: SessionTesterDetails };
    }) => original.hand,
  },
  {
    Header: 'Gender',
    accessor: 'session.gender',
    Cell: ({
      row: { original },
    }: {
      row: { original: SessionTesterDetails };
    }) => original.gender,
  },
  {
    Header: 'Skill Level',
    accessor: 'session.skill',
    Cell: ({
      row: { original },
    }: {
      row: { original: SessionTesterDetails };
    }) => original.skill,
  },
  {
    Header: 'Club Type',
    accessor: 'club.type',
  },
];
