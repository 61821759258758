import { Grips } from '@caddyshack/common';
import { AsyncState } from '../../app/models/AsyncState';
import { AsyncStatus } from '../../app/enums/AsyncStatus';
import { IPage } from '../../app/models/Pagination';

export interface GripState {
  grips: AsyncState<IPage<Grips.GripDto[], Grips.SearchQuery>>;
  exportGrips: AsyncState<null>;
  deleteGrips: AsyncState<null>;
  importGripsFile: AsyncState<null>;
}

export const gripState: GripState = {
  grips: {
    status: AsyncStatus.Void,
    data: {
      content: [],
      pagination: {
        page: 0,
        pageSize: 50,
        totalElements: 0,
      },
      filters: {},
    },
  },
  exportGrips: {
    status: AsyncStatus.Void,
    data: null,
  },
  deleteGrips: {
    status: AsyncStatus.Void,
    data: null,
  },
  importGripsFile: {
    status: AsyncStatus.Void,
    data: null,
  },
};
