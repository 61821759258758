import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Formik } from 'formik';
import { Box, Link, TextField, Typography } from '@material-ui/core';

import { forgotPasswordFormValidationSchema } from './ForgotPasswordForm.validation';
import { Button } from '../../../app/components/Button/Button';
import { IRecoverPasswordDataModel } from '../../models/Auth';
import { StyledCard } from './ForgotPasswordForm.style';
import { LOGIN } from '../../../app/const/routes';

interface IForgotPasswordForm {
  onSubmit: (formValues: IRecoverPasswordDataModel) => void;
  pending: boolean;
}

export const ForgotPasswordForm: FC<IForgotPasswordForm> = ({
  onSubmit,
  pending,
}) => {
  return (
    <StyledCard>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={forgotPasswordFormValidationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Box mb={2}>
              <Typography color="textPrimary" variant="h2">
                Forgot password
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body1">
                Submit your email to recover the password
              </Typography>
            </Box>
            <TextField
              disabled={pending}
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email address"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <Box my={2}>
              <Button
                pending={pending}
                disabled={pending}
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Submit email
              </Button>
            </Box>
            <Typography color="textSecondary" variant="body1">
              <Link component={RouterLink} to={LOGIN}>
                Sign in
              </Link>
            </Typography>
          </form>
        )}
      </Formik>
    </StyledCard>
  );
};
