import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography } from '@material-ui/core';

import { StyledCard, StyledIcon, StyledTypography } from './Verified.style';
import { LOGIN } from '../../../app/const/routes';

export const Verified: FC = () => (
  <StyledCard>
    <Box mb={2}>
      <StyledIcon color="primary" />
      <Typography color="textPrimary" variant="h2">
        Verified
      </Typography>
      <Typography color="textSecondary" gutterBottom variant="body1">
        Your account has been verified
      </Typography>
    </Box>
    <Typography color="textSecondary" gutterBottom variant="body1">
      Now you can sign in to the platform with your credentials.
    </Typography>
    <StyledTypography color="textSecondary" variant="body1">
      <Link component={RouterLink} to={LOGIN}>
        Sign in
      </Link>
    </StyledTypography>
  </StyledCard>
);
