import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { App, Session } from '@caddyshack/common';

import { AppDispatch } from '../../../app/store';
import {
  fetchSessions,
  deleteSessions,
  exportSessions,
} from '../../store/session.actions';
import {
  selectIsFetchingSessionsPending,
  selectSessionsPagination,
  selectSessionsFilters,
  selectMappedSessions,
  selectIsDeleteSessionsPending,
  selectIsExportSessionsStatusPending,
} from '../../store/session.selector';
import { SessionsTable } from '../../components/SessionsTable/SessionsTable';

export const SessionsTableContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const sessions = useSelector(selectMappedSessions);
  const sessionsPagination = useSelector(selectSessionsPagination);
  const sessionsFilters = useSelector(selectSessionsFilters);
  const isFetchSessionsPending = useSelector(selectIsFetchingSessionsPending);
  const isDeleteSessionsPending = useSelector(selectIsDeleteSessionsPending);
  const isExportPending = useSelector(selectIsExportSessionsStatusPending);

  useEffect(() => {
    dispatch(
      fetchSessions({
        pageSize: 50,
        page: 0,
        sort: { key: 'session.date_updated', order: App.SortOrder.DESC },
      })
    );
  }, [dispatch]);

  const fetchSwingsWithQuery = (searchQuery: Session.SearchQuery) => {
    dispatch(
      fetchSessions({
        ...sessionsFilters,
        ...searchQuery,
      })
    );
  };

  const onExport = (exportFilters: Session.ExportSessionsFiltersDto) =>
    dispatch(exportSessions(exportFilters));

  const onDelete = (ids: number[]) => dispatch(deleteSessions(ids));

  return (
    <SessionsTable
      sessions={sessions}
      isPending={isFetchSessionsPending || isDeleteSessionsPending}
      isExportPending={isExportPending}
      pagination={sessionsPagination}
      sorting={sessionsFilters?.sort}
      filter={sessionsFilters?.email}
      onQueryChange={fetchSwingsWithQuery}
      onDelete={onDelete}
      onExport={onExport}
    />
  );
};
