import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';

import { useLocation } from 'react-router-dom';
import { AppDispatch } from '../../../app/store';
import { IResetPasswordDataModel } from '../../models/Auth';
import { resetPassword } from '../../store/auth.actions';
import { selectIsResetPasswordStatusPending } from '../../store/auth.selector';
import { ResetPasswordForm } from '../../components/ResetPasswordForm/ResetPasswordForm';

export const ResetPasswordFormContainer: FC = () => {
  const { search } = useLocation();
  const { token }: { token?: string } = parse(search);
  const dispatch = useDispatch<AppDispatch>();
  const isResetPasswordPending = useSelector(
    selectIsResetPasswordStatusPending
  );

  const onSubmit = (resetPasswordData: IResetPasswordDataModel) =>
    dispatch(resetPassword({ ...resetPasswordData, token }));

  return (
    <ResetPasswordForm onSubmit={onSubmit} pending={isResetPasswordPending} />
  );
};
