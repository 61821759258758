import { createSelector } from '@reduxjs/toolkit';
import { Heads, App } from '@caddyshack/common';
import moment from 'moment';
import { RootState } from '../../app/store';
import { HeadState } from './head.store';
import { AsyncState } from '../../app/models/AsyncState';
import { AsyncStatus } from '../../app/enums/AsyncStatus';
import { IPage } from '../../app/models/Pagination';
import { IHeadModel } from '../model/Head';

export const selectSelf = (state: RootState): RootState => state;

export const selectHeadState = createSelector(
  selectSelf,
  (state: RootState): HeadState => state.head
);

export const selectHeadsState = createSelector(
  selectHeadState,
  (head: HeadState): AsyncState<IPage<Heads.HeadDto[], Heads.SearchQuery>> =>
    head.heads
);

export const selectHeadsStatus = createSelector(
  selectHeadsState,
  (
    fetchHeads: AsyncState<IPage<Heads.HeadDto[], Heads.SearchQuery>>
  ): AsyncStatus => fetchHeads.status
);

export const selectIsFetchingHeadsPending = createSelector(
  selectHeadsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectHeadsData = createSelector(
  selectHeadsState,
  (
    headPage: AsyncState<IPage<Heads.HeadDto[], Heads.SearchQuery>>
  ): IPage<Heads.HeadDto[], Heads.SearchQuery> => headPage.data
);

export const selectHeads = createSelector(
  selectHeadsData,
  (headsData: IPage<Heads.HeadDto[], Heads.SearchQuery>): Heads.HeadDto[] =>
    headsData.content
);

export const selectMappedHeads = createSelector(
  selectHeads,
  (headsData: Heads.HeadDto[]): IHeadModel[] =>
    headsData.map((head) => ({
      ...head,
      dateUpdated: moment(head.date_updated).format('M/D/YYYY h:mm:ss A'),
    }))
);

export const selectHeadsPagination = createSelector(
  selectHeadsData,
  (headsData: IPage<Heads.HeadDto[], Heads.SearchQuery>): App.Pagination =>
    headsData.pagination
);

export const selectHeadsFilters = createSelector(
  selectHeadsData,
  (
    headsData: IPage<Heads.HeadDto[], Heads.SearchQuery>
  ): Heads.SearchQuery | undefined => headsData.filters
);

export const selectExportHeadsState = createSelector(
  selectHeadState,
  (head: HeadState): AsyncState<null> => head.exportHeads
);

export const selectExportHeadsStatus = createSelector(
  selectExportHeadsState,
  (exportHeads: AsyncState<null>): AsyncStatus => exportHeads.status
);

export const selectIsExportHeadsStatusPending = createSelector(
  selectExportHeadsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectDeleteHeadsState = createSelector(
  selectHeadState,
  (head: HeadState): AsyncState<null> => head.deleteHeads
);

export const selectDeleteHeadsStatus = createSelector(
  selectDeleteHeadsState,
  (deleteHeads: AsyncState<null>): AsyncStatus => deleteHeads.status
);

export const selectIsDeleteHeadsStatusPending = createSelector(
  selectDeleteHeadsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectImportHeadsFileState = createSelector(
  selectHeadState,
  (shaft: HeadState): AsyncState<null> => shaft.importHeadsFile
);

export const selectImportHeadsFileStatus = createSelector(
  selectImportHeadsFileState,
  (importHeadsFile: AsyncState<null>): AsyncStatus => importHeadsFile.status
);

export const selectIsImportHeadsFileStatusPending = createSelector(
  selectImportHeadsFileStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
