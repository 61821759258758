import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route, Redirect } from 'react-router-dom';
import { StylesProvider, MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';

import './index.css';
import reportWebVitals from './reportWebVitals';
import { store, history } from './module/app/store';
import theme from './module/app/theme';
import { App } from './module/app/App';
import { GlobalStyles } from './module/app/components/DashboardLayout/GlobalStyles';
import { LoginView } from './module/auth/views/LoginView/LoginView';
import { RegisterView } from './module/auth/views/RegisterView/RegisterView';
import { ForgotPasswordView } from './module/auth/views/ForgotPasswordView/ForgotPasswordView';
import { ConnectedPrivateRoute } from './module/app/components/ConnectedPrivateRoute/ConnectedPrivateRoute';
import {
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN,
  PANEL,
  PRIVACY_POLICY,
  REGISTER,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  UNVERIFIED,
  VERIFIED,
  APPLE_LOGIN_REDIRECT,
  REGISTER_SUCCESS,
  GRIPS,
} from './module/app/const/routes';
import { VerifiedView } from './module/auth/views/VerifiedView/VerifiedView';
import { UnverifiedView } from './module/auth/views/UnverifiedView/UnverifiedView';
import { ForgotPasswordSuccessView } from './module/auth/views/ForgotPasswordSuccessView/ForgotPasswordSuccessView';
import { ResetPasswordSuccessView } from './module/auth/views/ResetPasswordSuccessView/ResetPasswordSuccessView';
import { ResetPasswordErrorView } from './module/auth/views/ResetPasswordErrorView/ResetPasswordErrorView';
import { ResetPasswordView } from './module/auth/views/ResetPasswordView/ResetPasswordView';
import { ConnectedNotifications } from './module/app/components/ConnectedNotifications/ConnectedNotifications';
import { PrivacyPolicy } from './module/auth/components/PrivacyPolicy/PrivacyPolicy';
import { AuthorizeAppleContainer } from './module/auth/views/AppleLoginRedirectView/AppleLoginRedirectView';
import { authApiClient } from './module/app/api/authApi.client';
import {
  onFulfilled,
  onRejected,
} from './module/app/api/interceptors/logout.interceptor';
import { RegisterSuccessView } from './module/auth/views/RegisterSuccessView/RegisterSuccessView';

authApiClient.interceptors.response.use(onFulfilled, onRejected);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <React.StrictMode>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <MuiThemeProvider theme={theme}>
              <SnackbarProvider
                autoHideDuration={3000}
                maxSnack={4}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <GlobalStyles />
                <Switch>
                  <Route
                    path={PRIVACY_POLICY}
                    component={PrivacyPolicy}
                    exact
                  />
                  <Route path={LOGIN} component={LoginView} exact />
                  <Route path={REGISTER} component={RegisterView} exact />
                  <Route
                    path={REGISTER_SUCCESS}
                    component={RegisterSuccessView}
                    exact
                  />
                  <Route
                    path={FORGOT_PASSWORD}
                    component={ForgotPasswordView}
                    exact
                  />
                  <Route
                    path={FORGOT_PASSWORD_SUCCESS}
                    component={ForgotPasswordSuccessView}
                    exact
                  />
                  <Route
                    path={RESET_PASSWORD}
                    component={ResetPasswordView}
                    exact
                  />
                  <Route
                    path={RESET_PASSWORD_SUCCESS}
                    component={ResetPasswordSuccessView}
                    exact
                  />
                  <Route
                    path={RESET_PASSWORD_ERROR}
                    component={ResetPasswordErrorView}
                    exact
                  />
                  <Route path={VERIFIED} component={VerifiedView} exact />
                  <Route path={UNVERIFIED} component={UnverifiedView} exact />
                  <Route
                    path={APPLE_LOGIN_REDIRECT}
                    component={AuthorizeAppleContainer}
                    exact
                  />
                  <ConnectedPrivateRoute path={PANEL} component={App} />
                  <Route path="*">
                    <Redirect to={GRIPS} />
                  </Route>
                </Switch>
                <ConnectedNotifications />
              </SnackbarProvider>
            </MuiThemeProvider>
          </StylesProvider>
        </ThemeProvider>
      </React.StrictMode>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
