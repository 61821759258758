import React, { FC, MouseEvent, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Box,
  IconButton,
  Link,
  TextField,
  Typography,
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { resetPasswordFormValidationSchema } from './ResetPasswordForm.validation';
import { Button } from '../../../app/components/Button/Button';
import { IResetPasswordDataModel } from '../../models/Auth';
import { StyledCard } from './ResetPasswordForm.style';
import { LOGIN } from '../../../app/const/routes';
import PasswordStrengthMeter from '../../../app/components/PasswordStrengthMeter/PasswordStrengthMeter';

interface IResetPasswordForm {
  onSubmit: (formValues: IResetPasswordDataModel) => void;
  pending: boolean;
}

export const ResetPasswordForm: FC<IResetPasswordForm> = ({
  onSubmit,
  pending,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false);

  const handleClickShowPassword = () =>
    setIsPasswordVisible(!isPasswordVisible);

  const handleClickShowRepeatPassword = () =>
    setIsRepeatPasswordVisible(!isRepeatPasswordVisible);

  const handleMouseDownPassword = (event: MouseEvent) => event.preventDefault();

  return (
    <StyledCard>
      <Formik
        initialValues={{
          password: '',
          repeatPassword: '',
        }}
        validationSchema={resetPasswordFormValidationSchema}
        onSubmit={({ password }) => onSubmit({ newPassword: password })}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box mb={2}>
              <Typography color="textPrimary" variant="h2">
                Reset password
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body1">
                Enter your new password
              </Typography>
            </Box>
            <TextField
              disabled={pending}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type={isPasswordVisible ? 'text' : 'password'}
              value={values.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    disabled={pending}
                  >
                    {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
            />
            <PasswordStrengthMeter
              password={values.password}
              error={errors.password}
            />
            <TextField
              disabled={pending}
              error={Boolean(touched.repeatPassword && errors.repeatPassword)}
              fullWidth
              helperText={touched.repeatPassword && errors.repeatPassword}
              label="Repeat password"
              margin="normal"
              name="repeatPassword"
              onBlur={handleBlur}
              onChange={handleChange}
              type={isRepeatPasswordVisible ? 'text' : 'password'}
              value={values.repeatPassword}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <IconButton
                    aria-label="toggle repeat password visibility"
                    onClick={handleClickShowRepeatPassword}
                    onMouseDown={handleMouseDownPassword}
                    disabled={pending}
                  >
                    {isRepeatPasswordVisible ? (
                      <Visibility />
                    ) : (
                      <VisibilityOff />
                    )}
                  </IconButton>
                ),
              }}
            />
            <Box my={2}>
              <Button
                color="primary"
                disabled={pending}
                pending={pending}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Reset my password
              </Button>
            </Box>
            <Typography color="textSecondary" variant="body1">
              <Link component={RouterLink} to={LOGIN}>
                Sign in
              </Link>
            </Typography>
          </form>
        )}
      </Formik>
    </StyledCard>
  );
};
