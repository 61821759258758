import React, { FC, useEffect } from 'react';
import { Auth } from '@caddyshack/common';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from '@material-ui/core';
import { pluralize } from '../../../app/utils/pluralize';

const options = Object.values(Auth.Role);

export interface IChangeRolesDialog {
  id: string;
  keepMounted: boolean;
  open: boolean;
  usersAmount: number;
  onClose: (roles?: Auth.Role[]) => void;
  roles: Auth.Role[];
}

const ChangeRolesDialog: FC<IChangeRolesDialog> = ({
  onClose,
  open,
  usersAmount,
  roles,
  ...other
}: IChangeRolesDialog) => {
  const [checkedRoles, setCheckedRoles] = React.useState<Auth.Role[]>(roles);

  useEffect(() => {
    setCheckedRoles(roles);
  }, [roles]);

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(checkedRoles);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newRole: Auth.Role = event.target.value as Auth.Role;

    if (checkedRoles.includes(newRole)) {
      setCheckedRoles(
        checkedRoles.filter((checkedRole) => checkedRole !== newRole)
      );
    } else {
      setCheckedRoles([...checkedRoles, newRole]);
    }
  };

  return (
    <Dialog disableBackdropClick maxWidth="sm" fullWidth open={open} {...other}>
      <DialogTitle>
        <Typography>
          Change user role - <strong>{pluralize(usersAmount, 'user')}</strong>{' '}
          will be affected
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <FormGroup>
          {options.map((option) => (
            <FormControlLabel
              value={option}
              key={option}
              control={
                <Checkbox
                  checked={checkedRoles.includes(option)}
                  onChange={handleChange}
                  name={option}
                />
              }
              label={option}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary" variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={handleOk}
          disabled={!checkedRoles.length}
          color="primary"
          variant="contained"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ChangeRolesDialog;
