import { Auth, App } from '@caddyshack/common';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { openSuccessNotification } from '../../app/store/notifications/notifications.slice';

import withError from '../../app/utils/with-thunk-error';
import {
  fetchUser as fetchUserRequest,
  editUser as editUserRequest,
  fetchUsers as fetchUsersRequest,
  updateUsersRoles as updateUsersRolesRequest,
  exportUserData as exportUserDataRequest,
} from '../api/user.api';

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  withError(
    async (): Promise<Auth.UserDto> => {
      return fetchUserRequest();
    }
  )
);

export const editUser = createAsyncThunk(
  'user/editUser',
  withError(
    async (payload: Auth.EditUserDto, { dispatch }): Promise<void> => {
      await editUserRequest(payload);
      dispatch(fetchUser({}));
      return dispatch(openSuccessNotification('Profile has been updated'));
    }
  )
);

export const fetchUsers = createAsyncThunk(
  'user/fetchUsers',
  withError(
    async (
      searchQuery?: Auth.UserSearchQuery
    ): Promise<App.Page<Auth.UserDto[]>> => {
      return fetchUsersRequest(searchQuery);
    }
  )
);

export const updateUsersRoles = createAsyncThunk(
  'user/updateUsersRoles',
  withError(
    async (
      updateUsersRolesDto: Auth.UpdateUsersRolesDto,
      { dispatch, getState }
    ): Promise<string> => {
      const response = await updateUsersRolesRequest(updateUsersRolesDto);
      const currentFilters = getState().user.users.data.filters;
      dispatch(openSuccessNotification('Roles has been updated'));
      dispatch(fetchUsers(currentFilters));

      return response;
    }
  )
);

export const exportUserData = createAsyncThunk(
  'user/exportUserData',
  withError(
    async (userId: string): Promise<void> => {
      await exportUserDataRequest(userId);
    }
  )
);
