import { Shafts } from '@caddyshack/common';
import { AsyncState } from '../../app/models/AsyncState';
import { AsyncStatus } from '../../app/enums/AsyncStatus';
import { IPage } from '../../app/models/Pagination';

export interface ShaftState {
  shafts: AsyncState<IPage<Shafts.ShaftDto[], Shafts.SearchQuery>>;
  exportShafts: AsyncState<null>;
  deleteShafts: AsyncState<null>;
  importShaftsFile: AsyncState<null>;
}

export const shaftState: ShaftState = {
  shafts: {
    status: AsyncStatus.Void,
    data: {
      content: [],
      pagination: {
        page: 0,
        pageSize: 50,
        totalElements: 0,
      },
      filters: {},
    },
  },
  exportShafts: {
    status: AsyncStatus.Void,
    data: null,
  },
  deleteShafts: {
    status: AsyncStatus.Void,
    data: null,
  },
  importShaftsFile: {
    status: AsyncStatus.Void,
    data: null,
  },
};
