import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { App, Swing } from '@caddyshack/common';

import { AppDispatch } from '../../../app/store';
import {
  fetchSwings,
  deleteSwings,
  exportSwings,
} from '../../store/swing.actions';
import {
  selectIsFetchingSwingsPending,
  selectSwingsPagination,
  selectSwingsFilters,
  selectMappedSwings,
  selectIsExportSwingsStatusPending,
} from '../../store/swing.selector';
import { SwingsTable } from '../../components/SwingsTable/SwingsTable';
import { authApiClient } from '../../../app/api/authApi.client';

export const SwingsTableContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const swings = useSelector(selectMappedSwings);
  const swingsPagination = useSelector(selectSwingsPagination);
  const swingsFilters = useSelector(selectSwingsFilters);
  const isPending = useSelector(selectIsFetchingSwingsPending);
  const isExportPending = useSelector(selectIsExportSwingsStatusPending);

  useEffect(() => {
    dispatch(
      fetchSwings({
        pageSize: 50,
        page: 0,
        sort: { key: 'swing.uploadedAt', order: App.SortOrder.DESC },
      })
    );
  }, [dispatch]);

  const fetchSwingsWithQuery = (searchQuery: Swing.SearchQuery) => {
    dispatch(
      fetchSwings({
        ...swingsFilters,
        ...searchQuery,
      })
    );
  };

  const onExport = (exportFilters: Swing.ExportSwingsFiltersDto) =>
    dispatch(exportSwings(exportFilters));

  const onDelete = (ids: string[]) => dispatch(deleteSwings(ids));

  const download = async (swingId: string) => {
    const res = await authApiClient.get(`/swing/download/${swingId}`);
    window.location.href = res.data;
  };

  return (
    <SwingsTable
      swings={swings}
      isPending={isPending}
      isExportPending={isExportPending}
      pagination={swingsPagination}
      sorting={swingsFilters?.sort}
      filter={swingsFilters?.uploadedByEmail}
      onQueryChange={fetchSwingsWithQuery}
      onDelete={onDelete}
      onExport={onExport}
      download={download}
    />
  );
};
