import styled from 'styled-components';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { Box, Typography, Paper, FormControl } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';

export const StyledModalContent = styled(MuiDialogContent)`
  padding: ${({ theme }) => `${theme.spacing(3)}px`};
  display: flex;
  justify-content: space-between;
`;

export const StyledCloseButton = styled(IconButton)`
  padding: 0;
`;

export const StyledManufacturerTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.tertiary};
  font-weight: 700;
`;

export const StyledTitle = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
`;

export const StyledDialogTitleContainer = styled(MuiDialogTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledBoxLeft = styled(Box)`
  flex: 1;
  margin-right: ${({ theme }) => `${theme.spacing(3)}px`};
`;

export const StyledBoxRight = styled(Box)`
  flex: 1;
`;

export const StyledIconContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const StyledToggleButtonLeft = styled(ToggleButton)`
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  span {
    font-weight: bold;
    text-transform: initial;
  }
`;
export const StyledToggleButtonRight = styled(ToggleButton)`
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;

  span {
    font-weight: bold;
    text-transform: initial;
  }
`;

export const StyledForm = styled.form`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;

export const StyledContainer = styled(Paper)`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  align-items: center;
  margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
`;

export const StyledFormControl = styled(FormControl)`
  flex: 1;
`;
