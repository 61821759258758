import { createAsyncThunk } from '@reduxjs/toolkit';
import { Swing, App } from '@caddyshack/common';
import withError from '../../app/utils/with-thunk-error';
import { openSuccessNotification } from '../../app/store/notifications/notifications.slice';

import {
  fetchSwings as fetchSwingsRequest,
  deleteSwings as deleteSwingsRequest,
  exportSwings as exportSwingsRequest,
} from '../api/swing';

export const fetchSwings = createAsyncThunk(
  'swings/fetchSwings',
  withError(
    async (
      searchQuery?: Swing.SearchQuery
    ): Promise<App.Page<Swing.SwingDto[]>> => {
      return fetchSwingsRequest(searchQuery);
    }
  )
);

export const deleteSwings = createAsyncThunk(
  'swings/deleteSwings',
  withError(
    async (ids: string[], { dispatch, getState }): Promise<string> => {
      const response = await deleteSwingsRequest(ids);
      const currentFilters = getState().swing.swings.data.filters;
      dispatch(openSuccessNotification('Swings have been deleted'));
      dispatch(fetchSwings(currentFilters));

      return response;
    }
  )
);

export const exportSwings = createAsyncThunk(
  'swings/exportSwings',
  withError(
    async (
      exportFilters: Swing.ExportSwingsFiltersDto,
      { dispatch }
    ): Promise<void> => {
      const response = await exportSwingsRequest(exportFilters);
      dispatch(openSuccessNotification('Swings have been exported'));

      return response;
    }
  )
);
