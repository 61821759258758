import { Column } from 'react-table';

export const headsColumns: Column[] = [
  {
    Header: 'Updated',
    accessor: 'dateUpdated',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Manufacturer',
    accessor: 'oem',
  },
  {
    Header: 'Model',
    accessor: 'stated_head',
  },
  {
    Header: 'Dexterity',
    accessor: 'hand_rh_lh',
  },
  {
    Header: 'SKU',
    accessor: 'groove_sku',
  },
  {
    Header: 'Price',
    accessor: 'price',
  },
  {
    Header: 'Popularity',
    accessor: 'popularity',
  },
];
