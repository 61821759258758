import React, { FC } from 'react';
import { Box, Card, CardHeader, Divider } from '@material-ui/core';
import { ProfileFormContainer } from '../../containers/ProfileFormContainer/ProfileFormContainer';

export const ProfileView: FC = () => (
  <Box
    display="flex"
    flexDirection="column"
    height="100%"
    justifyContent="center"
  >
    <Card>
      <CardHeader title="Profile" />
      <Divider />
      <ProfileFormContainer />
    </Card>
  </Box>
);
