import * as Yup from 'yup';

const editShaftConstantMessages = {
  required: 'Value is required',
  max: 'Max value is 100',
  min: 'Min value is 0.001',
};

export const editShaftConstantValidationSchema = Yup.object().shape({
  shaftConstant: Yup.number()
    .max(100, editShaftConstantMessages.max)
    .min(0.001, editShaftConstantMessages.min)
    .required(editShaftConstantMessages.required),
});
