import { createSlice } from '@reduxjs/toolkit';
import { gripState } from './grip.store';
import {
  fetchGrips,
  exportGrips,
  deleteGrips,
  editGrip,
  importGripsFile,
} from './grip.actions';
import { AsyncStatus } from '../../app/enums/AsyncStatus';

const slice = createSlice({
  name: 'grip',
  initialState: gripState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchGrips.pending, (state, { meta: { arg } }) => ({
      ...state,
      grips: {
        ...state.grips,
        status: AsyncStatus.Pending,
        data: {
          ...state.grips.data,
          filters: arg,
        },
      },
    }));
    builder.addCase(fetchGrips.fulfilled, (state, { payload }) => {
      if (payload) {
        const { pagination, content } = payload;
        return {
          ...state,
          grips: {
            status: AsyncStatus.Fulfilled,
            data: {
              ...state.grips.data,
              content,
              pagination,
            },
          },
        };
      }

      return state;
    });
    builder.addCase(fetchGrips.rejected, (state) => ({
      ...state,
      grips: {
        ...state.grips,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(exportGrips.pending, (state) => ({
      ...state,
      exportGrips: {
        ...state.exportGrips,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(exportGrips.fulfilled, (state) => ({
      ...state,
      exportGrips: {
        ...state.exportGrips,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(exportGrips.rejected, (state) => ({
      ...state,
      exportGrips: {
        ...state.exportGrips,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(deleteGrips.pending, (state) => ({
      ...state,
      deleteGrips: {
        ...state.deleteGrips,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(deleteGrips.fulfilled, (state) => ({
      ...state,
      deleteGrips: {
        ...state.deleteGrips,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(deleteGrips.rejected, (state) => ({
      ...state,
      deleteGrips: {
        ...state.deleteGrips,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(importGripsFile.pending, (state) => ({
      ...state,
      importGripsFile: {
        ...state.importGripsFile,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(importGripsFile.fulfilled, (state) => ({
      ...state,
      importGripsFile: {
        ...state.importGripsFile,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(importGripsFile.rejected, (state) => ({
      ...state,
      importGripsFile: {
        ...state.importGripsFile,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(editGrip.pending, (state) => ({
      ...state,
      grips: {
        ...state.grips,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editGrip.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          grips: {
            ...state.grips,
            status: AsyncStatus.Fulfilled,
            data: {
              ...state.grips.data,
              content: state.grips.data.content.map((grip) =>
                grip.id === payload.id ? payload : grip
              ),
            },
          },
        };
      }

      return state;
    });
    builder.addCase(editGrip.rejected, (state) => ({
      ...state,
      grips: {
        ...state.grips,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export default slice.reducer;
