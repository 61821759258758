import React, { FC, useState } from 'react';
import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Swing } from '@caddyshack/common';
import { Button } from '../../../app/components/Button/Button';
import { Modal } from '../../../app/components/Modal/Modal';
import {
  StyledDialogTitleContainer,
  StyledSubtitle,
  StyledTitle,
} from './ExportDataModal.style';
import { ExportDataModalForm } from '../ExportDataModalForm/ExportDataModalForm';

interface IExportDataModal {
  onSubmit: (exportFilters: Swing.ExportSwingsFiltersDto) => void;
  isPending: boolean;
}

export const ExportDataModal: FC<IExportDataModal> = ({
  onSubmit,
  isPending,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => setIsOpen(true);

  const handleCloseModal = () => setIsOpen(false);

  const handleOnSubmit = async (
    exportFilters: Swing.ExportSwingsFiltersDto
  ) => {
    await onSubmit(exportFilters);
    handleCloseModal();
  };

  const Header = () => (
    <StyledDialogTitleContainer disableTypography>
      <Box>
        <StyledTitle variant="h4">Export Data</StyledTitle>
        <StyledSubtitle variant="h5">
          Select which data you would like to export.
        </StyledSubtitle>
      </Box>
      <Box>
        <IconButton aria-label="close" onClick={handleCloseModal}>
          <CloseIcon />
        </IconButton>
      </Box>
    </StyledDialogTitleContainer>
  );

  const Content = () => (
    <ExportDataModalForm onSubmit={handleOnSubmit} isPending={isPending} />
  );

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleOpenModal}>
        Export
      </Button>
      <Modal
        Content={Content}
        Header={Header}
        onClose={handleCloseModal}
        open={isOpen}
        maxWidth="sm"
      />
    </>
  );
};
