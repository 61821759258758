import React, { FC } from 'react';
import { Box, Drawer, Hidden } from '@material-ui/core';
import { Users as UsersIcon, Wind as WindIcon } from 'react-feather';
import { NavItem } from '../NavItem/NavItem';
import { StyledList } from './NavBar.style';
import {
  GRIPS,
  HEADS,
  SHAFTS,
  USERS,
  SWINGS,
  SHAFT_CONSTANTS,
  SESSIONS,
} from '../../../const/routes';
import { ReactComponent as ShaftIcon } from '../../../../../assets/shaft.svg';
import { ReactComponent as GripIcon } from '../../../../../assets/grip.svg';
import { ReactComponent as ClubHeadIcon } from '../../../../../assets/club_head.svg';

const items = [
  {
    href: GRIPS,
    icon: GripIcon,
    title: 'Grips',
  },
  {
    href: SHAFTS,
    icon: ShaftIcon,
    title: 'Shafts',
  },
  {
    href: SHAFT_CONSTANTS,
    icon: ShaftIcon,
    title: 'Shaft Constants',
  },
  {
    href: HEADS,
    icon: ClubHeadIcon,
    title: 'Heads',
  },
  {
    href: SWINGS,
    icon: WindIcon,
    title: 'Swings',
  },
  {
    href: SESSIONS,
    icon: WindIcon,
    title: 'Sessions',
  },
  {
    href: USERS,
    icon: UsersIcon,
    title: 'Users',
  },
];

interface INavBar {
  onMobileClose: () => void;
  openMobile: boolean;
}

export const NavBar: FC<INavBar> = ({ onMobileClose, openMobile = false }) => {
  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box p={2}>
        <StyledList>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </StyledList>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};
