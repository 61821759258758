import styled from 'styled-components';

export const StyledIndicator = styled.div<{ height: number }>`
  display: inline-block;
  width: 20%;
  background-color: ${({ color }) => color};
  height: ${({ height }) => `${height}px`};
  border-radius: 2px;
`;

export const StyledWrapper = styled.div<{ height: number }>`
  line-height: ${({ height }) => `${height}px`};
`;
