import React, { FC } from 'react';
import { Box, Container, Divider, Tab } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';

import { PageHeader } from '../../../app/components/DashboardLayout/PageHeader/PageHeader';
import { ACCOUNT, ACCOUNT_SECURITY } from '../../../app/const/routes';
import { ConnectedTabs } from '../../../app/components/ConnectedTabs/ConnectedTabs';
import { ProfileView } from '../../../user/views/ProfileView/ProfileView';
import { SecurityView } from '../SecurityView/SecurityView';

import { StyledPage } from '../../../app/components/DashboardLayout/DashboardLayout.style';

export const AccountView: FC = () => (
  <StyledPage title="Account">
    <Box display="flex" flexDirection="column" height="100%">
      <Container maxWidth="lg">
        <Box mb={3}>
          <PageHeader title="Account" />
        </Box>
        <ConnectedTabs>
          <Tab label="Profile" value={ACCOUNT} />
          <Tab label="Security" value={ACCOUNT_SECURITY} />
        </ConnectedTabs>
        <Divider />
        <Box mt={3}>
          <Switch>
            <Route path={ACCOUNT} component={ProfileView} exact />
            <Route path={ACCOUNT_SECURITY} component={SecurityView} exact />
          </Switch>
        </Box>
      </Container>
    </Box>
  </StyledPage>
);
