import { Shafts } from '@caddyshack/common';
import { IDropdownOption } from '../../../app/components/Modal/ModalDetailsBoxForm/ModalDetailsBoxForm';

export const boolDropdownOptions: IDropdownOption[] = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const rangeDropdownOptions: IDropdownOption[] = [
  {
    value: Shafts.RangeOptions.High,
    label: 'HIGH',
  },
  {
    value: Shafts.RangeOptions.Low,
    label: 'LOW',
  },
  {
    value: Shafts.RangeOptions.LowMid,
    label: 'LOW/MID',
  },
  {
    value: Shafts.RangeOptions.Mid,
    label: 'MID',
  },
  {
    value: Shafts.RangeOptions.MidHigh,
    label: 'MID/HIGH',
  },
];

export const typesDropdownOptions: IDropdownOption[] = [
  {
    value: Shafts.ShaftTypes.BlankIron,
    label: 'Blank Iron',
  },
  {
    value: Shafts.ShaftTypes.DedicatedIron,
    label: 'Dedicated Iron',
  },
  {
    value: Shafts.ShaftTypes.Fairway,
    label: 'Fairway',
  },
  {
    value: Shafts.ShaftTypes.Hybrid,
    label: 'Hybrid',
  },
  {
    value: Shafts.ShaftTypes.WedgeOnly,
    label: 'Wedge Only',
  },
  {
    value: Shafts.ShaftTypes.Wood,
    label: 'Wood',
  },
  {
    value: Shafts.ShaftTypes.Putter,
    label: 'Putter',
  },
];
