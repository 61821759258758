import { Auth } from '@caddyshack/common';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../app/store';
import { ProfileForm } from '../../components/ProfileForm/ProfileForm';
import { editUser, fetchUser } from '../../store/user.actions';
import {
  selectIsUserProfilePending,
  selectUserProfileData,
} from '../../store/user.selector';

export const ProfileFormContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userProfileData = useSelector(selectUserProfileData);
  const isUserProfilePending = useSelector(selectIsUserProfilePending);

  useEffect(() => {
    dispatch(fetchUser({}));
  }, [dispatch]);

  // eslint-disable-next-line
  const onSubmit = (formValues: Auth.EditUserDto) =>
    dispatch(editUser(formValues));

  return (
    <ProfileForm
      onSubmit={onSubmit}
      pending={isUserProfilePending}
      userProfileData={userProfileData}
    />
  );
};
