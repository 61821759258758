import React, { FC, useState } from 'react';

import { NavBar } from './NavBar/NavBar';

import {
  StyledRoot,
  StyledWrapper,
  StyledContentContainer,
  StyledContent,
} from './DashboardLayout.style';
import { ConnectedTopBar } from './ConnectedTopBar/ConnectedTopBar';

export const DashboardLayout: FC = ({ children }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <StyledRoot>
      <ConnectedTopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <StyledWrapper>
        <StyledContentContainer>
          <StyledContent>{children}</StyledContent>
        </StyledContentContainer>
      </StyledWrapper>
    </StyledRoot>
  );
};
