import { createSelector } from '@reduxjs/toolkit';
import { App, Auth } from '@caddyshack/common';
import { RootState } from '../../app/store';

import { AsyncState } from '../../app/models/AsyncState';
import { AsyncStatus } from '../../app/enums/AsyncStatus';
import { IPage } from '../../app/models/Pagination';
import { UserState } from './user.store';
import { IUserModel } from '../model/User';

export const selectSelf = (state: RootState): RootState => state;

export const selectUserState = createSelector(
  selectSelf,
  (state: RootState): UserState => state.user
);

export const selectUserProfile = createSelector(
  selectUserState,
  (user: UserState): AsyncState<Auth.UserDto | null> => user.profile
);

export const selectUserProfileData = createSelector(
  selectUserProfile,
  (profile: AsyncState<Auth.UserDto | null>): Auth.UserDto | null =>
    profile.data
);

export const selectUserProfileStatus = createSelector(
  selectUserProfile,
  (profile: AsyncState<Auth.UserDto | null>): AsyncStatus => profile.status
);

export const selectIsUserProfilePending = createSelector(
  selectUserProfileStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectAllUsersState = createSelector(
  selectUserState,
  (user: UserState): AsyncState<IPage<Auth.UserDto[], Auth.UserSearchQuery>> =>
    user.users
);

export const selectUsersStatus = createSelector(
  selectAllUsersState,
  (
    fetchUsers: AsyncState<IPage<Auth.UserDto[], Auth.UserSearchQuery>>
  ): AsyncStatus => fetchUsers.status
);

export const selectIsFetchingUsersPending = createSelector(
  selectUsersStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectUsersData = createSelector(
  selectAllUsersState,
  (
    userPage: AsyncState<IPage<Auth.UserDto[], Auth.UserSearchQuery>>
  ): IPage<Auth.UserDto[], Auth.UserSearchQuery> => userPage.data
);

export const selectUsers = createSelector(
  selectUsersData,
  (usersData: IPage<Auth.UserDto[], Auth.UserSearchQuery>): Auth.UserDto[] =>
    usersData.content
);

export const selectMappedUsers = createSelector(
  selectUsers,
  (usersData: Auth.UserDto[]): IUserModel[] =>
    usersData.map((user) => ({
      ...user,
      rolesString: [...user.roles]
        .sort((roleA, roleB) => (roleA <= roleB ? -1 : 1))
        .join(', '),
    }))
);

export const selectUsersPagination = createSelector(
  selectUsersData,
  (usersData: IPage<Auth.UserDto[], Auth.UserSearchQuery>): App.Pagination =>
    usersData.pagination
);

export const selectUsersFilters = createSelector(
  selectUsersData,
  (
    usersData: IPage<Auth.UserDto[], Auth.UserSearchQuery>
  ): Auth.UserSearchQuery | undefined => usersData.filters
);

export const selectExportUserData = createSelector(
  selectUserState,
  (user: UserState): AsyncState<null> => user.exportUserData
);

export const selectExportUserDataStatus = createSelector(
  selectExportUserData,
  (exportUserData: AsyncState<null>): AsyncStatus => exportUserData.status
);

export const selectIsExportUserDataPending = createSelector(
  selectExportUserDataStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);
