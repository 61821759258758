import {
  combineReducers,
  configureStore as configureToolkitStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { createBrowserHistory, History } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import auth from '../../auth/store/auth.slice';
import notifications from './notifications/notifications.slice';
import shaft from '../../shaft/store/shaft.slice';
import user from '../../user/store/user.slice';
import head from '../../head/store/head.slice';
import grip from '../../grip/store/grip.slice';
import swing from '../../swing/store/swing.slice';
import session from '../../session/store/session.slice';
import shaftConstant from '../../shaftConstant/store/shaftConstant.slice';

const configureRootReducer = (newHistory: History) => {
  return combineReducers({
    router: connectRouter(newHistory),
    user,
    notifications,
    auth,
    shaft,
    head,
    grip,
    swing,
    session,
    shaftConstant,
  });
};

const configureMiddleware = (newHistory: History) => {
  return [...getDefaultMiddleware(), routerMiddleware(newHistory)] as const;
};

export const configureStore = (newHistory?: History) => {
  const history = newHistory || createBrowserHistory();

  const store = configureToolkitStore({
    reducer: configureRootReducer(history),
    middleware: configureMiddleware(history),
  });

  return { store, history };
};

export const { store, history } = configureStore();
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
