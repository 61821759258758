import { Auth, App } from '@caddyshack/common/dist/index';
import FileSaver from 'file-saver';
import { authApiClient } from '../../app/api/authApi.client';

export const fetchUser = async (): Promise<Auth.UserDto> => {
  try {
    const { data } = await authApiClient.get('/user');
    return data;
  } catch (error) {
    throw error;
  }
};

export const editUser = async (payload: Auth.EditUserDto): Promise<void> => {
  try {
    return authApiClient.patch('/user', payload);
  } catch (error) {
    throw error;
  }
};

export const fetchUsers = async (
  searchQuery?: Auth.UserSearchQuery
): Promise<App.Page<Auth.UserDto[]>> => {
  try {
    const response = await authApiClient.post('/user/search', searchQuery);
    const usersPage: App.Page<Auth.UserDto[]> = response.data;

    return usersPage;
  } catch (error) {
    throw error;
  }
};

export const updateUsersRoles = async (
  updateUsersRolesDto: Auth.UpdateUsersRolesDto
): Promise<string> => {
  try {
    const { data } = await authApiClient.post(
      '/user/updateRoles',
      updateUsersRolesDto
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const exportUserData = async (userId: string): Promise<void> => {
  try {
    const { data } = await authApiClient.get(`/user/export/${userId}`, {
      responseType: 'arraybuffer',
    });

    const xlsxData = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    FileSaver.saveAs(xlsxData, `${userId}-user-data.xlsx`);
    return data;
  } catch (error) {
    throw error;
  }
};
