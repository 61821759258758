import React, { FC } from 'react';
import {
  GoogleLogin as ReactGoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';

import { StyledButton, StyledGoogleLogo } from './GoogleLogin.style';

export const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;

interface IGoogleLogin {
  onSuccess: (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onFailure: (error: any) => void;
}

export const GoogleLogin: FC<IGoogleLogin> = ({ onSuccess, onFailure }) => (
  <ReactGoogleLogin
    clientId={String(REACT_APP_GOOGLE_CLIENT_ID)}
    onSuccess={onSuccess}
    onFailure={onFailure}
    cookiePolicy="single_host_origin"
    scope="email"
    icon={false}
    render={({ onClick }) => (
      <StyledButton
        variant="contained"
        fullWidth
        size="large"
        onClick={onClick}
      >
        <StyledGoogleLogo />
        Sign in with Google
      </StyledButton>
    )}
  />
);
