import { Auth } from '@caddyshack/common';
import { apiClient } from '../../app/api/api.client';
import { authApiClient } from '../../app/api/authApi.client';
import {
  ILoginCredentialsModel,
  IRecoverPasswordDataModel,
  IRegisterDataModel,
} from '../models/Auth';

export const login = async (
  credentials: ILoginCredentialsModel
): Promise<Auth.LoginResponseDto> => {
  try {
    const { data } = await apiClient.post('/auth/login', credentials);
    return data;
  } catch (error) {
    throw error;
  }
};

export const register = async (
  registerData: IRegisterDataModel
): Promise<void> => {
  try {
    const { data } = await apiClient.post('/auth/register', registerData);
    return data;
  } catch (error) {
    throw error;
  }
};

export const fetchProfile = async (): Promise<Auth.UserDto> => {
  try {
    const { data } = await authApiClient.get('/auth/me');
    return data;
  } catch (error) {
    throw error;
  }
};

export const recoverPassword = async (
  recoverPasswordData: IRecoverPasswordDataModel
): Promise<void> => {
  try {
    const { data } = await apiClient.post(
      '/auth/forgetPassword',
      recoverPasswordData
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const handleGoogleLoginApi = async (
  tokenId: string
): Promise<string> => {
  try {
    const { data } = await apiClient.post('/auth/google/redirect', {
      Authorization: tokenId,
    });
    return data.accessToken;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (
  resetPasswordData: Auth.ChangePasswordDto
): Promise<void> => {
  try {
    const { data } = await apiClient.post(
      '/auth/changePassword',
      resetPasswordData
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const changePassword = async (
  resetPasswordData: Auth.ChangePasswordDto
): Promise<void> => {
  try {
    const { data } = await authApiClient.post(
      '/auth/changePassword',
      resetPasswordData
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const logout = async (token: string | null): Promise<void> => {
  try {
    await apiClient.post('/auth/logout', { token });
  } catch (error) {
    throw error;
  }
};
