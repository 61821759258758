import { createSlice } from '@reduxjs/toolkit';
import { swingState } from './swing.store';
import { fetchSwings, deleteSwings, exportSwings } from './swing.actions';
import { AsyncStatus } from '../../app/enums/AsyncStatus';

const slice = createSlice({
  name: 'swing',
  initialState: swingState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSwings.pending, (state, { meta: { arg } }) => ({
      ...state,
      swings: {
        ...state.swings,
        status: AsyncStatus.Pending,
        data: {
          ...state.swings.data,
          filters: arg,
        },
      },
    }));
    builder.addCase(fetchSwings.fulfilled, (state, { payload }) => {
      if (payload) {
        const { pagination, content } = payload;
        return {
          ...state,
          swings: {
            status: AsyncStatus.Fulfilled,
            data: {
              ...state.swings.data,
              content,
              pagination,
            },
          },
        };
      }

      return state;
    });
    builder.addCase(fetchSwings.rejected, (state) => ({
      ...state,
      swings: {
        ...state.swings,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(deleteSwings.pending, (state) => ({
      ...state,
      swings: {
        ...state.swings,
        status: AsyncStatus.Pending,
      },
    }));

    builder.addCase(deleteSwings.fulfilled, (state) => ({
      ...state,
      swings: {
        ...state.swings,
        status: AsyncStatus.Fulfilled,
      },
    }));

    builder.addCase(deleteSwings.rejected, (state) => ({
      ...state,
      swings: {
        ...state.swings,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(exportSwings.pending, (state) => ({
      ...state,
      exportSwings: {
        ...state.exportSwings,
        status: AsyncStatus.Pending,
      },
    }));

    builder.addCase(exportSwings.fulfilled, (state) => ({
      ...state,
      exportSwings: {
        ...state.exportSwings,
        status: AsyncStatus.Fulfilled,
      },
    }));

    builder.addCase(exportSwings.rejected, (state) => ({
      ...state,
      exportSwings: {
        ...state.exportSwings,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export default slice.reducer;
