import React, { FC } from 'react';
import { Box, Container } from '@material-ui/core';

import { StyledPage } from '../../../app/components/DashboardLayout/DashboardLayout.style';
import { RegisterSuccess } from '../../components/RegisterSuccess/RegisterSuccess';

export const RegisterSuccessView: FC = () => (
  <StyledPage title="Register Success">
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      <Container maxWidth="sm">
        <RegisterSuccess />
      </Container>
    </Box>
  </StyledPage>
);
