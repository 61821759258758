import styled from 'styled-components';
import {
  TableContainer,
  TablePagination,
  TablePaginationProps,
  TableContainerProps,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { Button, IButton } from '../Button/Button';

export const RelativeWrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
`;

export const InnerTableWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  position: relative;
`;

export const StyledTablePagination = styled(TablePagination)<
  TablePaginationProps & { component?: string }
>`
  margin-top: 10px;
`;

export const StyledTableContainer = styled(TableContainer)<TableContainerProps>`
  flex: 1;
  height: 100%;
  position: relative;
`;

export const StyledTableInnerContainer = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const TableToolbarButton = styled(Button)<IButton>`
  padding: 8px 18px;
  margin-right: ${({ theme }) => `${theme.spacing(1)}px`};
  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-top: ${({ theme }) => `${theme.spacing(1)}px`};
  }
`;

export const TableToolbarButtonsWrapper = styled.div`
  display: flex;

  & :last-child {
    margin-right: 0;
  }
`;

export const LoaderOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.6);
`;

export const StyledTableHeaderCell = styled(TableCell)`
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  cursor: pointer;
`;

export const StyledTableRow = styled(TableRow)`
  cursor: ${({ hover }) => (hover ? 'pointer' : 'initial')};
  white-space: nowrap;
`;
