import { createAsyncThunk } from '@reduxjs/toolkit';
import { Heads, App } from '@caddyshack/common';
import withError from '../../app/utils/with-thunk-error';

import {
  fetchHeads as fetchHeadsRequest,
  exportHeads as exportHeadsRequest,
  deleteHeads as deleteHeadsRequest,
  editHead as editHeadRequest,
  importHeadsFile as importHeadsFileRequest,
} from '../api/head.api';
import { openSuccessNotification } from '../../app/store/notifications/notifications.slice';
import {
  mapHeadSearchQueryDto,
  mapHeadModelToHeadDto,
} from '../api/head.mapper';
import { IHeadModel } from '../model/Head';

export const fetchHeads = createAsyncThunk(
  'heads/fetchHeads',
  withError(
    async (
      searchQuery: Heads.SearchQuery
    ): Promise<App.Page<Heads.HeadDto[]>> => {
      return fetchHeadsRequest(mapHeadSearchQueryDto(searchQuery));
    }
  )
);

export const exportHeads = createAsyncThunk(
  'heads/export',
  withError(
    async (): Promise<void> => {
      return exportHeadsRequest();
    }
  )
);

export const deleteHeads = createAsyncThunk(
  'heads/delete',
  withError(
    async (ids: string[], { dispatch, getState }): Promise<string> => {
      const response = await deleteHeadsRequest(ids);
      const currentFilters = getState().head.heads.data.filters;
      dispatch(openSuccessNotification('Heads has been deleted'));
      dispatch(fetchHeads(currentFilters));

      return response;
    }
  )
);

export const editHead = createAsyncThunk(
  'heads/edit',

  withError(
    async (head: IHeadModel, { dispatch }): Promise<Heads.HeadDto> => {
      const response = await editHeadRequest(mapHeadModelToHeadDto(head));
      dispatch(openSuccessNotification('Head has been updated'));
      return response;
    }
  )
);

export const importHeadsFile = createAsyncThunk(
  'heads/import',
  withError(
    async (file: File, { dispatch, getState }): Promise<void> => {
      const currentFilters = getState().head.heads.data.filters;
      const formData = new FormData();
      formData.append('file', file);
      const data = await importHeadsFileRequest(formData);

      dispatch(
        openSuccessNotification(
          `${data.created} heads imported and ${data.updated} heads updated`
        )
      );
      dispatch(fetchHeads(currentFilters));
    }
  )
);
