import styled from 'styled-components';

import { Button } from '../../../app/components/Button/Button';
import { ReactComponent as AppleLogo } from '../../../../assets/apple.svg';

export const StyledButton = styled(Button)`
  background-color: #fff;
`;

export const StyledAppleLogo = styled(AppleLogo)`
  margin-right: 15px;
`;
