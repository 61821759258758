import React, { FC, forwardRef } from 'react';
import { Helmet } from 'react-helmet';

interface IPage {
  title: string;
}

export const Page: FC<IPage> = forwardRef<HTMLInputElement, IPage>(
  ({ children, title = '', ...rest }, ref) => {
    return (
      <div ref={ref} {...rest}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </div>
    );
  }
);
