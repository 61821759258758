import React, { FC, memo } from 'react';
import zxcvbn from 'zxcvbn';
import { StyledIndicator, StyledWrapper } from './PasswordStrengthMeter.style';

interface PasswordStrengthMeterProps {
  password: string;
  userInputs?: string[];
  error?: string;
}

interface ColorScheme {
  levels: string[];
  noLevel: string;
}

interface PasswordMeterSettings {
  colorScheme: ColorScheme;
  height: number;
}

const PasswordStrengthMeter: FC<PasswordStrengthMeterProps> = memo(
  ({ password, userInputs, error }) => {
    const { score } = zxcvbn(password, [...(userInputs || [])]);
    const settings: PasswordMeterSettings = {
      height: 4,
      colorScheme: {
        levels: ['#ff4033', '#fe940d', '#ffd908', '#cbe11d', '#6ecc3a'],
        noLevel: 'lightgrey',
      },
    };
    const level = error && score === 4 ? 3 : score;
    const indicators = [];

    // eslint-disable-next-line
    for (let i = 0; i < 5; i++) {
      const color =
        i <= level
          ? settings.colorScheme.levels[level]
          : settings.colorScheme.noLevel;
      indicators.push(
        <StyledIndicator
          color={color}
          height={settings.height}
          key={`indicator-${i}`}
        />
      );
    }

    return <StyledWrapper height={settings.height}>{indicators}</StyledWrapper>;
  }
);

export default PasswordStrengthMeter;
