import { Swing, App } from '@caddyshack/common';
import FileSaver from 'file-saver';
import { authApiClient } from '../../app/api/authApi.client';

export const fetchSwings = async (
  searchQuery?: Swing.SearchQuery
): Promise<App.Page<Swing.SwingDto[]>> => {
  try {
    const response = await authApiClient.post('/swing/search', searchQuery);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteSwings = async (ids: string[]): Promise<string> => {
  try {
    const { data } = await authApiClient.post('/swing/delete', {
      ids,
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export const exportSwings = async (
  exportFilters: Swing.ExportSwingsFiltersDto
): Promise<void> => {
  try {
    const { data } = await authApiClient.post('/swing/export', exportFilters, {
      responseType: 'arraybuffer',
    });

    const xlsxData = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    FileSaver.saveAs(xlsxData, 'swings-data.xlsx');
    return data;
  } catch (error) {
    throw error;
  }
};
