import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from '../../../app/store';
import { IRecoverPasswordDataModel } from '../../models/Auth';
import { recoverPassword } from '../../store/auth.actions';
import { ForgotPasswordForm } from '../../components/ForgotPasswordForm/ForgotPasswordForm';
import { selectIsRecoveryPasswordStatusPending } from '../../store/auth.selector';

export const ForgotPasswordFormContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isRecoveryPasswordPending = useSelector(
    selectIsRecoveryPasswordStatusPending
  );

  const onSubmit = (recoveryPasswordData: IRecoverPasswordDataModel) =>
    dispatch(recoverPassword(recoveryPasswordData));

  return (
    <ForgotPasswordForm
      onSubmit={onSubmit}
      pending={isRecoveryPasswordPending}
    />
  );
};
