import styled from 'styled-components';
import { Container, ContainerProps } from '@material-ui/core';
import { Page } from './Page/Page';

export const StyledRoot = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.dark};
  overflow: hidden;
`;

export const StyledWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  padding-top: 64px;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-left: 210px;
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
`;

export const StyledContent = styled.div`
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
`;

export const StyledPage = styled(Page)`
  background-color: ${({ theme }) => theme.palette.background.dark};
  height: 100%;
  padding-bottom: ${({ theme }) => `${theme.spacing(3)}px`};
  padding-top: ${({ theme }) => `${theme.spacing(3)}px`};
`;

export const FullHeightContainer = styled(Container)<ContainerProps>`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
