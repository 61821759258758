import React, { FC } from 'react';
import { Session, App } from '@caddyshack/common';
import { Table } from '../../../app/components/Table/Table';
import SessionsToolbar from '../SessionsToolbar/SessionsToolbar';

import { StyledCard } from './SessionsTable.style';
import { sessionsColumns } from './SessionsTable.columns';
import { TableToolbarButton } from '../../../app/components/Table/Table.style';
import { ISessionModel } from '../../model/Session';
import { IPagination } from '../../../app/models/Pagination';

interface ISessionTable {
  sessions: ISessionModel[];
  isPending: boolean;
  isExportPending: boolean;
  pagination: IPagination;
  sorting?: {
    key: string;
    order: App.SortOrder;
  };
  filter?: string;
  onQueryChange: (searchQuery: Session.SearchQuery) => void;
  onDelete: (ids: number[]) => void;
  onExport: (exportFilters: Session.ExportSessionsFiltersDto) => void;
}

export const SessionsTable: FC<ISessionTable> = ({
  sessions,
  isPending,
  pagination,
  sorting,
  filter,
  onQueryChange,
  onDelete,
  onExport,
  isExportPending,
}) => {
  const tableSorting = sorting
    ? {
        id: sorting.key,
        desc: sorting.order === 'desc',
      }
    : undefined;

  const SelectedToolbar = (selectedSessions: ISessionModel[]) => (
    <TableToolbarButton
      color="primary"
      disabled={isPending}
      variant="contained"
      onClick={() =>
        onDelete(selectedSessions.map((session: ISessionModel) => session.id))
      }
    >
      {`Delete ${selectedSessions.length} ${
        selectedSessions.length === 1 ? 'swing' : 'swings'
      }`}
    </TableToolbarButton>
  );

  const Toolbar = () => (
    <SessionsToolbar
      onSearch={(searchTerm) => onQueryChange({ email: searchTerm, page: 0 })}
      onExport={onExport}
      isPending={isPending}
      isExportPending={isExportPending}
      filter={filter}
      sessions={sessions}
    />
  );

  return (
    <StyledCard>
      <Table
        loading={isPending}
        columns={sessionsColumns}
        data={sessions}
        pagination={pagination}
        sorting={tableSorting}
        onPaginationChange={onQueryChange}
        onSortChange={(sort) =>
          sort ? onQueryChange({ sort }) : onQueryChange({ sort: undefined })
        }
        Toolbar={Toolbar}
        SelectedToolbar={SelectedToolbar}
      />
    </StyledCard>
  );
};
