import React, { FC } from 'react';
import { Divider } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';

import {
  StyledContainer,
  StyledListItemText,
  StyledTitle,
  StyledList,
} from './ModalDetailsBox.style';

interface IDetailsRow {
  label: string;
  value: string | boolean | number;
}

export interface IModalDetailsBox {
  rows: IDetailsRow[];
  title: string;
  scrollable?: boolean;
}

export const ModalDetailsBox: FC<IModalDetailsBox> = ({
  rows,
  title,
  scrollable,
}: IModalDetailsBox) => {
  return (
    <StyledContainer elevation={3}>
      <StyledTitle variant="h6">{title}</StyledTitle>
      <StyledList scrollable={scrollable} maxHeight={640}>
        {rows.map((row, i) => (
          <React.Fragment key={row.label}>
            <ListItem>
              <StyledListItemText secondary={row.label} />
              <StyledListItemText primary={row.value || '-'} />
            </ListItem>
            {i < rows.length - 1 ? <Divider /> : null}
          </React.Fragment>
        ))}
      </StyledList>
    </StyledContainer>
  );
};
