import { createAsyncThunk } from '@reduxjs/toolkit';
import { Shafts, App } from '@caddyshack/common';
import withError from '../../app/utils/with-thunk-error';

import {
  fetchShafts as fetchShaftsRequest,
  exportShafts as exportShaftsRequest,
  deleteShafts as deleteShaftsRequest,
  editShaft as editShaftRequest,
  importShaftsFile as importShaftsFileRequest,
} from '../api/shaft.api';
import { openSuccessNotification } from '../../app/store/notifications/notifications.slice';
import {
  mapShaftSearchQueryDto,
  mapShaftModelToShaftDto,
} from '../api/shaft.mapper';
import { IShaftModel } from '../model/Shaft';

export const fetchShafts = createAsyncThunk(
  'shafts/fetchShafts',
  withError(
    async (
      searchQuery: Shafts.SearchQuery
    ): Promise<App.Page<Shafts.ShaftDto[]>> => {
      return fetchShaftsRequest(mapShaftSearchQueryDto(searchQuery));
    }
  )
);

export const exportShafts = createAsyncThunk(
  'shafts/export',
  withError(
    async (): Promise<void> => {
      return exportShaftsRequest();
    }
  )
);

export const deleteShafts = createAsyncThunk(
  'shafts/delete',
  withError(
    async (ids: string[], { dispatch, getState }): Promise<string> => {
      const response = await deleteShaftsRequest(ids);
      // TODO REFACTOR IT
      const currentFilters = getState().shaft.shafts.data.filters;
      dispatch(openSuccessNotification('Shafts has been deleted'));
      dispatch(fetchShafts(currentFilters));

      return response;
    }
  )
);

export const editShaft = createAsyncThunk(
  'shafts/edit',
  withError(
    async (shaft: IShaftModel, { dispatch }): Promise<Shafts.ShaftDto> => {
      const response = await editShaftRequest(mapShaftModelToShaftDto(shaft));
      dispatch(openSuccessNotification('Shaft has been updated'));

      return response;
    }
  )
);

export const importShaftsFile = createAsyncThunk(
  'shafts/import',
  withError(
    async (file: File, { dispatch, getState }): Promise<void> => {
      const currentFilters = getState().shaft.shafts.data.filters;
      const formData = new FormData();
      formData.append('file', file);
      const data = await importShaftsFileRequest(formData);

      dispatch(
        openSuccessNotification(
          `${data.created} shafts imported and ${data.updated} shafts updated`
        )
      );
      dispatch(fetchShafts(currentFilters));
    }
  )
);
