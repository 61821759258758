import { createSelector } from '@reduxjs/toolkit';
import { Swing, App } from '@caddyshack/common';
import moment from 'moment';
import { RootState } from '../../app/store';

import { SwingState } from './swing.store';
import { AsyncState } from '../../app/models/AsyncState';
import { AsyncStatus } from '../../app/enums/AsyncStatus';
import { IPage } from '../../app/models/Pagination';
import { ISwingModel } from '../model/Swing';

export const selectSelf = (state: RootState): RootState => state;

export const selectSwingState = createSelector(
  selectSelf,
  (state: RootState): SwingState => state.swing
);

export const selectSwingsState = createSelector(
  selectSwingState,
  (swing: SwingState): AsyncState<IPage<Swing.SwingDto[], Swing.SearchQuery>> =>
    swing.swings
);

export const selectSwingsStatus = createSelector(
  selectSwingsState,
  (
    fetchSwings: AsyncState<IPage<Swing.SwingDto[], Swing.SearchQuery>>
  ): AsyncStatus => fetchSwings.status
);

export const selectIsFetchingSwingsPending = createSelector(
  selectSwingsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectSwingsData = createSelector(
  selectSwingsState,
  (
    swingPage: AsyncState<IPage<Swing.SwingDto[], Swing.SearchQuery>>
  ): IPage<Swing.SwingDto[], Swing.SearchQuery> => swingPage.data
);

export const selectSwings = createSelector(
  selectSwingsData,
  (swingsData: IPage<Swing.SwingDto[], Swing.SearchQuery>): Swing.SwingDto[] =>
    swingsData.content
);

export const selectMappedSwings = createSelector(
  selectSwings,
  (swingsData: Swing.SwingDto[]): ISwingModel[] =>
    swingsData.map((swing) => ({
      ...swing,
      uploadedDate: moment(swing.uploadedAt).format('M/D/YYYY'),
    }))
);

export const selectSwingsPagination = createSelector(
  selectSwingsData,
  (swingsData: IPage<Swing.SwingDto[], Swing.SearchQuery>): App.Pagination =>
    swingsData.pagination
);

export const selectSwingsFilters = createSelector(
  selectSwingsData,
  (
    swingsData: IPage<Swing.SwingDto[], Swing.SearchQuery>
  ): Swing.SearchQuery | undefined => swingsData.filters
);

export const selectExportSwingsState = createSelector(
  selectSwingState,
  (swing) => swing.exportSwings
);

export const selectExportSwingsStatus = createSelector(
  selectExportSwingsState,
  (exportSwings) => exportSwings.status
);

export const selectIsExportSwingsStatusPending = createSelector(
  selectExportSwingsStatus,
  (status) => status === AsyncStatus.Pending
);
