import React, { FC } from 'react';
import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
} from '@material-ui/core';

export interface IButton extends ButtonProps {
  pending?: boolean;
  component?: React.ElementType;
}

export const Button: FC<IButton> = ({ pending, ...rest }) => {
  return (
    <MuiButton
      {...rest}
      startIcon={
        pending ? <CircularProgress size={16} color="inherit" /> : undefined
      }
    />
  );
};
