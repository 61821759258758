import React, { FC } from 'react';
import {
  Divider,
  ListItem,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';

import { FormikProps } from 'formik';

import {
  StyledContainer,
  StyledListItemText,
  StyledTitle,
  StyledList,
  StyledFormControl,
  StyledTextField,
} from './ModalDetailsBoxForm.style';

export interface IDropdownOption {
  // eslint-disable-next-line
  value: any;
  label: string;
}

interface IDetailsRow {
  label: string;
  value: string | boolean | number;
  key: string;
  disabled?: boolean;
  requiredLabel?: boolean;
  dropdownOptions?: IDropdownOption[];
}

// eslint-disable-next-line
export interface IModalDetailsBox extends FormikProps<any> {
  rows: IDetailsRow[];
  title: string;
  scrollable?: boolean;
}

export const ModalDetailsBoxForm: FC<IModalDetailsBox> = ({
  rows,
  title,
  scrollable,
  errors,
  handleBlur,
  handleChange,
  touched,
  values,
}: IModalDetailsBox) => {
  return (
    <StyledContainer elevation={3}>
      <StyledTitle variant="h6">{title}</StyledTitle>
      <StyledList scrollable={scrollable} maxHeight={640}>
        {rows.map((row, i) => (
          <React.Fragment key={row.label}>
            <ListItem>
              <StyledListItemText>
                {row.label}
                {row.requiredLabel ? (
                  <Typography color="error" component="span">
                    *
                  </Typography>
                ) : null}
              </StyledListItemText>
              {row.dropdownOptions ? (
                <StyledFormControl variant="outlined" size="small">
                  <Select
                    error={Boolean(touched[row.key] && errors[row.key])}
                    disabled={row.disabled}
                    name={row.key}
                    value={values[row.key]}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  >
                    {row.dropdownOptions.map((dropdown) => (
                      <MenuItem value={dropdown.value}>
                        {dropdown.label}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              ) : (
                <StyledTextField
                  error={Boolean(touched[row.key] && errors[row.key])}
                  disabled={row.disabled}
                  fullWidth
                  helperText={touched[row.key] && errors[row.key]}
                  name={row.key}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values[row.key]}
                  variant="outlined"
                  size="small"
                />
              )}
            </ListItem>
            {i < rows.length - 1 ? <Divider /> : null}
          </React.Fragment>
        ))}
      </StyledList>
    </StyledContainer>
  );
};
