import {
  Checkbox,
  FormGroup,
  Input,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Formik } from 'formik';
import React, { ChangeEvent, FC, useState } from 'react';
import { Session } from '@caddyshack/common';
import moment from 'moment';
import { Button } from '../../../app/components/Button/Button';
import {
  Row,
  StyledDateFormControl,
  StyledDateFormLabel,
  StyledForm,
  StyledFormControl,
  StyledFormControlLabel,
  StyledFormGroup,
  StyledFormLabel,
  StyledModalActions,
  StyledModalContent,
  StyledTypography,
} from './ExportDataModalForm.style';
import { DatePicker } from '../../../app/components/DatePicker/DatePicker';
import { ISessionModel } from '../../model/Session';

interface IExportDataModalForm {
  onSubmit: (exportFilters: Session.ExportSessionsFiltersDto) => void;
  isPending: boolean;
  sessions: ISessionModel[];
}

export const ExportDataModalForm: FC<IExportDataModalForm> = ({
  onSubmit,
  isPending,
  sessions,
}) => {
  const [sessionIds, setSessionIds] = useState<number[]>([]);

  const handleSessionChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSessionIds(event.target.value as number[]);
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 200,
      },
    },
  };

  return (
    <Formik
      initialValues={{
        dateFrom: null,
        dateTo: null,
        dataModeTraining: false,
        dataModeViewer: false,
        dataModeUser: false,
        handRight: false,
        handLeft: false,
        genderMale: false,
        genderFemale: false,
        skillLevelBeginner: false,
        skillLevelIntermediate: false,
        skillLevelAdvanced: false,
        driver: false,
        putter: false,
        sessionIds: [],
      }}
      onSubmit={(values) =>
        onSubmit({
          ...values,
          dateFrom: values.dateFrom ? moment(values.dateFrom).format() : null,
          dateTo: values.dateTo ? moment(values.dateTo).format() : null,
          sessionIds,
        })
      }
    >
      {({ setFieldValue, handleBlur, handleChange, handleSubmit, values }) => (
        <>
          <StyledModalContent>
            <StyledForm noValidate>
              <Row>
                <StyledDateFormControl>
                  <StyledDateFormLabel>Date</StyledDateFormLabel>
                  <StyledFormGroup>
                    <DatePicker
                      value={values.dateFrom}
                      onChange={(date) => setFieldValue('dateFrom', date)}
                      label="mm/dd/yyyy"
                      variant="outlined"
                      disabled={isPending}
                      size="small"
                      name="dateFrom"
                      maxDate={values.dateTo || undefined}
                    />
                    <StyledTypography>to</StyledTypography>
                    <DatePicker
                      value={values.dateTo}
                      onChange={(date) => setFieldValue('dateTo', date)}
                      label="mm/dd/yyyy"
                      variant="outlined"
                      disabled={isPending}
                      size="small"
                      name="dateTo"
                      minDate={values.dateFrom || undefined}
                    />
                  </StyledFormGroup>
                </StyledDateFormControl>
              </Row>
              <Row>
                <StyledFormControl>
                  <StyledFormLabel>Data Mode</StyledFormLabel>
                  <FormGroup>
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.dataModeTraining}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="dataModeTraining"
                        />
                      }
                      label="Training"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.dataModeViewer}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="dataModeViewer"
                        />
                      }
                      label="Viewer"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.dataModeUser}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="dataModeUser"
                        />
                      }
                      label="User"
                    />
                  </FormGroup>
                </StyledFormControl>
                <StyledFormControl>
                  <StyledFormLabel>Hand</StyledFormLabel>
                  <FormGroup>
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.handRight}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="handRight"
                        />
                      }
                      label="Right"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.handLeft}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="handLeft"
                        />
                      }
                      label="Left"
                    />
                  </FormGroup>
                </StyledFormControl>
                <StyledFormControl>
                  <StyledFormLabel>Gender</StyledFormLabel>
                  <FormGroup>
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.genderFemale}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="genderFemale"
                        />
                      }
                      label="Female"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.genderMale}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="genderMale"
                        />
                      }
                      label="Male"
                    />
                  </FormGroup>
                </StyledFormControl>
              </Row>
              <Row>
                <StyledFormControl>
                  <StyledFormLabel>Skill Level</StyledFormLabel>
                  <FormGroup>
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.skillLevelBeginner}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="skillLevelBeginner"
                        />
                      }
                      label="Beginner"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.skillLevelIntermediate}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="skillLevelIntermediate"
                        />
                      }
                      label="Intermediate"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.skillLevelAdvanced}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="skillLevelAdvanced"
                        />
                      }
                      label="Advanced"
                    />
                  </FormGroup>
                </StyledFormControl>
                <StyledFormControl>
                  <StyledFormLabel>Club Type</StyledFormLabel>
                  <FormGroup>
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.driver}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="driver"
                        />
                      }
                      label="Driver"
                    />
                    <StyledFormControlLabel
                      disabled={isPending}
                      control={
                        <Checkbox
                          checked={values.putter}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          name="putter"
                        />
                      }
                      label="Putter"
                    />
                  </FormGroup>
                </StyledFormControl>
                <StyledFormControl>
                  <StyledFormLabel>Session IDs</StyledFormLabel>
                  <FormGroup>
                    <Select
                      multiple
                      value={sessionIds}
                      onChange={handleSessionChange}
                      input={<Input />}
                      MenuProps={MenuProps}
                      style={{ width: 100 }}
                    >
                      {sessions.map((session) => (
                        <MenuItem key={session.id} value={session.id}>
                          {session.id}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormGroup>
                </StyledFormControl>
              </Row>
            </StyledForm>
          </StyledModalContent>
          <StyledModalActions>
            <Button
              pending={isPending}
              disabled={isPending}
              color="primary"
              variant="contained"
              onClick={() => handleSubmit()}
            >
              Export
            </Button>
          </StyledModalActions>
        </>
      )}
    </Formik>
  );
};
