export const LOGIN = '/login';
export const REGISTER = '/register';
export const REGISTER_SUCCESS = `${REGISTER}/success`;
export const FORGOT_PASSWORD = '/forgot-password';
export const FORGOT_PASSWORD_SUCCESS = `${FORGOT_PASSWORD}/success`;
export const RESET_PASSWORD = '/reset-password';
export const RESET_PASSWORD_SUCCESS = `${RESET_PASSWORD}/success`;
export const RESET_PASSWORD_ERROR = '/reset-password/error';
export const VERIFIED = '/verified';
export const UNVERIFIED = '/unverified';
export const PRIVACY_POLICY = '/privacy-policy';
export const APPLE_LOGIN_REDIRECT = '/auth/apple';

export const PANEL = '/panel';
export const DASHBOARD = `${PANEL}/dashboard`;
export const UPLOADER = `${PANEL}/uploader`;
export const ACCOUNT = `${PANEL}/account`;
export const ACCOUNT_SECURITY = `${ACCOUNT}/security`;
export const GRIPS = `${PANEL}/grips`;
export const SHAFTS = `${PANEL}/shafts`;
export const HEADS = `${PANEL}/heads`;
export const SWINGS = `${PANEL}/swings`;
export const SESSIONS = `${PANEL}/sessions`;
export const USERS = `${PANEL}/users`;
export const SHAFT_CONSTANTS = `${PANEL}/shaft-constants`;
