import React, { FC, MouseEvent, useState } from 'react';
import { Formik } from 'formik';
import {
  CardContent,
  TextField,
  Box,
  Divider,
  Grid,
  IconButton,
} from '@material-ui/core';

import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Auth } from '@caddyshack/common';
import { securityFormValidation } from './SecurityForm.validation';
import { Button } from '../../../app/components/Button/Button';
import PasswordStrengthMeter from '../../../app/components/PasswordStrengthMeter/PasswordStrengthMeter';

interface ISecurityForm {
  onSubmit: (formValues: Auth.ChangePasswordDto) => void;
  pending: boolean;
}

export const SecurityForm: FC<ISecurityForm> = ({ onSubmit, pending }) => {
  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false);

  const handleClickShowOldPassword = () =>
    setIsOldPasswordVisible(!isOldPasswordVisible);

  const handleClickShowPassword = () =>
    setIsPasswordVisible(!isPasswordVisible);

  const handleClickShowRepeatPassword = () =>
    setIsRepeatPasswordVisible(!isRepeatPasswordVisible);

  const handleMouseDownPassword = (event: MouseEvent) => event.preventDefault();

  return (
    <Formik
      initialValues={{
        oldPassword: '',
        password: '',
        repeatPassword: '',
      }}
      validationSchema={securityFormValidation}
      onSubmit={({ password, oldPassword }) =>
        onSubmit({ oldPassword, newPassword: password })
      }
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  disabled={pending}
                  error={Boolean(touched.oldPassword && errors.oldPassword)}
                  fullWidth
                  helperText={touched.oldPassword && errors.oldPassword}
                  label="Old password"
                  name="oldPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={isOldPasswordVisible ? 'text' : 'password'}
                  value={values.oldPassword}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle old password visibility"
                        onClick={handleClickShowOldPassword}
                        onMouseDown={handleMouseDownPassword}
                        disabled={pending}
                      >
                        {isOldPasswordVisible ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  disabled={pending}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="New password"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={isPasswordVisible ? 'text' : 'password'}
                  value={values.password}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        disabled={pending}
                      >
                        {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                />
                <PasswordStrengthMeter
                  password={values.password}
                  error={errors.password}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  disabled={pending}
                  error={Boolean(
                    touched.repeatPassword && errors.repeatPassword
                  )}
                  fullWidth
                  helperText={touched.repeatPassword && errors.repeatPassword}
                  label="Repeat new password"
                  name="repeatPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type={isRepeatPasswordVisible ? 'text' : 'password'}
                  value={values.repeatPassword}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle repeat password visibility"
                        onClick={handleClickShowRepeatPassword}
                        onMouseDown={handleMouseDownPassword}
                        disabled={pending}
                      >
                        {isRepeatPasswordVisible ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" padding={2}>
            <Button
              color="primary"
              disabled={pending}
              pending={pending}
              type="submit"
              variant="contained"
            >
              Change password
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
