import { createSelector } from '@reduxjs/toolkit';
import { ShaftConstant, App } from '@caddyshack/common';
import { RootState } from '../../app/store';

import { ShaftConstantState } from './shaftConstant.store';
import { AsyncState } from '../../app/models/AsyncState';
import { AsyncStatus } from '../../app/enums/AsyncStatus';
import { IPage } from '../../app/models/Pagination';
import { IShaftConstantModel } from '../model/ShaftConstant';

export const selectSelf = (state: RootState): RootState => state;

export const selectShaftConstantState = createSelector(
  selectSelf,
  (state: RootState): ShaftConstantState => state.shaftConstant
);

export const selectShaftConstantsState = createSelector(
  selectShaftConstantState,
  (
    shaftConstant: ShaftConstantState
  ): AsyncState<
    IPage<ShaftConstant.ShaftConstantDto[], ShaftConstant.SearchQuery>
  > => shaftConstant.shaftConstants
);

export const selectShaftConstantsStatus = createSelector(
  selectShaftConstantsState,
  (
    fetchShaftConstants: AsyncState<
      IPage<ShaftConstant.ShaftConstantDto[], ShaftConstant.SearchQuery>
    >
  ): AsyncStatus => fetchShaftConstants.status
);

export const selectIsFetchingShaftConstantsPending = createSelector(
  selectShaftConstantsStatus,
  (status: AsyncStatus): boolean => status === AsyncStatus.Pending
);

export const selectShaftConstantsData = createSelector(
  selectShaftConstantsState,
  (
    shaftConstantPage: AsyncState<
      IPage<ShaftConstant.ShaftConstantDto[], ShaftConstant.SearchQuery>
    >
  ): IPage<ShaftConstant.ShaftConstantDto[], ShaftConstant.SearchQuery> =>
    shaftConstantPage.data
);

export const selectShaftConstants = createSelector(
  selectShaftConstantsData,
  (
    shaftConstantsData: IPage<
      ShaftConstant.ShaftConstantDto[],
      ShaftConstant.SearchQuery
    >
  ): ShaftConstant.ShaftConstantDto[] => shaftConstantsData.content
);

export const selectMappedShaftConstants = createSelector(
  selectShaftConstants,
  (
    shaftConstantsData: ShaftConstant.ShaftConstantDto[]
  ): IShaftConstantModel[] =>
    shaftConstantsData.map((shaftConstant) => ({
      ...shaftConstant,
    }))
);

export const selectShaftConstantsPagination = createSelector(
  selectShaftConstantsData,
  (
    shaftConstantsData: IPage<
      ShaftConstant.ShaftConstantDto[],
      ShaftConstant.SearchQuery
    >
  ): App.Pagination => shaftConstantsData.pagination
);

export const selectShaftConstantsFilters = createSelector(
  selectShaftConstantsData,
  (
    shaftConstantsData: IPage<
      ShaftConstant.ShaftConstantDto[],
      ShaftConstant.SearchQuery
    >
  ): ShaftConstant.SearchQuery | undefined => shaftConstantsData.filters
);
