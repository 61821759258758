import React, { FC, useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress } from '@material-ui/core';
import { AppDispatch } from '../../store';
import { authorize } from '../../../auth/store/auth.actions';
import {
  selectIsAuthorizationFulfilled,
  selectIsAuthorizationPending,
} from '../../../auth/store/auth.selector';

export const ConnectedPrivateRoute: FC<RouteProps> = ({ ...rest }) => {
  const dispatch = useDispatch<AppDispatch>();
  const isPending = useSelector(selectIsAuthorizationPending);
  const isFulfilled = useSelector(selectIsAuthorizationFulfilled);

  useEffect(() => {
    dispatch(authorize());
  }, [dispatch]);

  if (isPending) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flex="1"
        height="100%"
      >
        <CircularProgress size={50} />
      </Box>
    );
  }

  if (!isPending && isFulfilled) {
    return <Route {...rest} />;
  }

  return null;
};
