import React, { PropsWithChildren, ReactElement } from 'react';
import { Box } from '@material-ui/core';

import { StyledToolbar } from './TableToolbar.style';

interface ITableToolbar<T> {
  selectedRows: T[];
  Toolbar?: () => ReactElement;
  SelectedToolbar?: (selectedRows: T[]) => ReactElement;
}

export function TableToolbar<T>({
  SelectedToolbar,
  Toolbar,
  selectedRows,
}: PropsWithChildren<ITableToolbar<T>>): ReactElement | null {
  const getToolbar = () =>
    Toolbar && !selectedRows.length ? (
      <StyledToolbar>
        <Box display="flex" alignItems="center" width="100%">
          {Toolbar()}
        </Box>
      </StyledToolbar>
    ) : null;

  const getSelectedToolbar = () =>
    SelectedToolbar && selectedRows.length ? (
      <StyledToolbar>
        <Box display="flex" alignItems="center">
          {SelectedToolbar(selectedRows)}
        </Box>
      </StyledToolbar>
    ) : null;

  return (
    <>
      {getToolbar()}
      {getSelectedToolbar()}
    </>
  );
}
