import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { LoginForm } from '../../components/LoginForm/LoginForm';
import { AppDispatch } from '../../../app/store';
import { ILoginCredentialsModel } from '../../models/Auth';
import { login, handleGoogleLogin } from '../../store/auth.actions';
import { selectIsLoginStatusPending } from '../../store/auth.selector';

export const LoginFormContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const isLoginPending = useSelector(selectIsLoginStatusPending);

  const onSubmit = (credentials: ILoginCredentialsModel) =>
    dispatch(login(credentials));

  const onGoogleLogin = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => dispatch(handleGoogleLogin(response));

  return (
    <LoginForm
      onSubmit={onSubmit}
      onGoogleLogin={onGoogleLogin}
      pending={isLoginPending}
    />
  );
};
