import { Column } from 'react-table';

export const shaftsColumns: Column[] = [
  {
    Header: 'Updated',
    accessor: 'dateUpdated',
  },
  {
    Header: 'Groove Number',
    accessor: 'groove_number',
  },
  {
    Header: 'Type',
    accessor: 'type_string',
  },
  {
    Header: 'Manufacturer',
    accessor: 'manufacturername',
  },
  {
    Header: 'Model',
    accessor: 'modelname',
  },
  {
    Header: 'Flex',
    accessor: 'flexname',
  },
  {
    Header: 'Length (in)',
    accessor: 'length_in',
  },
  {
    Header: 'Mass (g)',
    accessor: 'massG',
  },
  {
    Header: 'SKU',
    accessor: 'manufacturer_sku',
  },
  {
    Header: 'Price',
    accessor: 'price',
  },
  {
    Header: 'Popularity',
    accessor: 'popularity',
  },
];
