import { createSlice } from '@reduxjs/toolkit';
import { headState } from './head.store';
import {
  fetchHeads,
  exportHeads,
  deleteHeads,
  editHead,
  importHeadsFile,
} from './head.actions';
import { AsyncStatus } from '../../app/enums/AsyncStatus';

const slice = createSlice({
  name: 'head',
  initialState: headState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHeads.pending, (state, { meta: { arg } }) => ({
      ...state,
      heads: {
        ...state.heads,
        status: AsyncStatus.Pending,
        data: {
          ...state.heads.data,
          filters: arg,
        },
      },
    }));
    builder.addCase(fetchHeads.fulfilled, (state, { payload }) => {
      if (payload) {
        const { pagination, content } = payload;
        return {
          ...state,
          heads: {
            status: AsyncStatus.Fulfilled,
            data: {
              ...state.heads.data,
              content,
              pagination,
            },
          },
        };
      }

      return state;
    });
    builder.addCase(fetchHeads.rejected, (state) => ({
      ...state,
      heads: {
        ...state.heads,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(exportHeads.pending, (state) => ({
      ...state,
      exportHeads: {
        ...state.exportHeads,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(exportHeads.fulfilled, (state) => ({
      ...state,
      exportHeads: {
        ...state.exportHeads,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(exportHeads.rejected, (state) => ({
      ...state,
      exportHeads: {
        ...state.exportHeads,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(deleteHeads.pending, (state) => ({
      ...state,
      deleteHeads: {
        ...state.deleteHeads,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(deleteHeads.fulfilled, (state) => ({
      ...state,
      deleteHeads: {
        ...state.deleteHeads,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(deleteHeads.rejected, (state) => ({
      ...state,
      deleteHeads: {
        ...state.deleteHeads,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(importHeadsFile.pending, (state) => ({
      ...state,
      importHeadsFile: {
        ...state.importHeadsFile,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(importHeadsFile.fulfilled, (state) => ({
      ...state,
      importHeadsFile: {
        ...state.importHeadsFile,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(importHeadsFile.rejected, (state) => ({
      ...state,
      importHeadsFile: {
        ...state.importHeadsFile,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(editHead.pending, (state) => ({
      ...state,
      heads: {
        ...state.heads,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editHead.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          heads: {
            ...state.heads,
            status: AsyncStatus.Fulfilled,
            data: {
              ...state.heads.data,
              content: state.heads.data.content.map((head) =>
                head.id === payload.id ? payload : head
              ),
            },
          },
        };
      }

      return state;
    });
    builder.addCase(editHead.rejected, (state) => ({
      ...state,
      heads: {
        ...state.heads,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export default slice.reducer;
