import { FC, useEffect } from 'react';
import { push } from 'connected-react-router';
import { parse } from 'url';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../app/store';
import { GRIPS } from '../../../app/const/routes';

export const AuthorizeAppleContainer: FC = () => {
  const { search } = useLocation();
  const { token }: { token?: string } = parse(search, true).query;
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    localStorage.setItem('token', `${token}`);
    dispatch(push(GRIPS));
  }, [dispatch, token]);
  return null;
};
