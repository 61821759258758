import { colors } from '@material-ui/core';

export const MAIN = '#212121';

export const BACKGROUND_DARK = '#F4F6F8';
export const BACKGROUND_DEFAULT = colors.common.white;
export const BACKGROUND_PAPER = colors.common.white;
export const BACKGROUND_DISABLED = '#EDEDED';

export const TEXT_PRIMARY = colors.common.black;
export const TEXT_SECONDARY = colors.grey[800];
export const TEXT_TERTIARY = '#666666';
