import * as yup from 'yup';

const messages = {
  number: 'Value must be a number',
};

export const shaftsDetailsModalValidation = yup.object().shape({
  id: yup.string().required(),
  dateUpdated: yup.string(),
  sku: yup.string().nullable(),
  price: yup.number().typeError(messages.number).nullable(),
  manufacturername: yup.string().required(),
  modelname: yup.string().required(),
  type_int: yup.number().typeError(messages.number).nullable(),
  flexname: yup.string().nullable(),
  oem_aftermarket: yup.boolean().nullable(),
  length_in: yup.number().typeError(messages.number).nullable(),
  butt_od_in: yup.number().typeError(messages.number).nullable(),
  tip_od_in: yup.number().typeError(messages.number).nullable(),
  parallel_length_in: yup.number().typeError(messages.number).nullable(),
  massG: yup.number().typeError(messages.number).nullable(),
  frequency_cpm_5in_clamp: yup.number().typeError(messages.number).nullable(),
  torque_deg: yup.number().typeError(messages.number).nullable(),
  balance_point_from_tip: yup.number().typeError(messages.number).nullable(),
  butt_ei: yup.number().typeError(messages.number).nullable(),
  mid_ei: yup.number().typeError(messages.number).nullable(),
  tip_ei: yup.number().typeError(messages.number).nullable(),
  s3_launch_deg: yup.number().typeError(messages.number).nullable(),
  s3_launch_rel: yup.string().nullable(),
  min_build_fm: yup.number().typeError(messages.number).nullable(),
  max_build_fm: yup.number().typeError(messages.number).nullable(),
  total_adjustments: yup.number().typeError(messages.number).nullable(),
  min_fitting_fm: yup.number().typeError(messages.number).nullable(),
  max_fitting_fm: yup.number().typeError(messages.number).nullable(),
  wedge_step: yup.number().typeError(messages.number).nullable(),
  iscurrent: yup.boolean().nullable(),
  isgraphite: yup.boolean().nullable(),
  isparallel: yup.boolean().nullable(),
  man_spin: yup.string().nullable(),
  man_launch: yup.string().nullable(),
  basic_speed: yup.number().typeError(messages.number).nullable(),
  max_tipping_in: yup.number().typeError(messages.number).nullable(),
  cpmperintipping: yup.number().typeError(messages.number).nullable(),
});
