import React, { useState, FC, KeyboardEvent, useEffect } from 'react';

import { IconButton, Tooltip } from '@material-ui/core';
import { X as ClearIcon, Info as InfoIcon } from 'react-feather';

import {
  StyledSearchContainer,
  TableToolbarSearchButton,
  TableToolbarSearchInput,
} from './TableSearchInput.style';

interface ITableSearchInput {
  onSearch: (searchTerm: string) => void;
  label: string;
  isPending: boolean;
  filter?: string;
  tooltipLabel?: string;
}

const TableSearchInput: FC<ITableSearchInput> = ({
  onSearch,
  label,
  isPending,
  filter,
  tooltipLabel,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    setSearchTerm(filter || '');
  }, [filter]);

  const handleClear = () => {
    setSearchTerm('');
    onSearch('');
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSearch(searchTerm);
    }
  };

  return (
    <StyledSearchContainer>
      <TableToolbarSearchInput
        disabled={isPending}
        fullWidth
        value={searchTerm || ''}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        label={label}
        variant="outlined"
        size="small"
        onKeyPress={handleKeyPress}
        InputProps={{
          endAdornment: filter ? (
            <IconButton
              aria-label="clear search"
              onClick={handleClear}
              style={{ marginRight: '-14px' }}
            >
              <ClearIcon size="16" />
            </IconButton>
          ) : (
            tooltipLabel && (
              <Tooltip
                title={tooltipLabel}
                aria-label="add"
                arrow
                placement="top"
              >
                <InfoIcon size="16" style={{ cursor: 'pointer' }} />
              </Tooltip>
            )
          ),
        }}
      />
      <TableToolbarSearchButton
        disabled={isPending}
        color="primary"
        variant="contained"
        onClick={() => onSearch(searchTerm)}
        size="medium"
      >
        Search
      </TableToolbarSearchButton>
    </StyledSearchContainer>
  );
};

export default TableSearchInput;
