import React, { ChangeEvent, FC } from 'react';
import { TableToolbarButton } from '../Table/Table.style';

interface UploaderProps {
  label?: string;
  onChange: (file: File) => void;
  isPending: boolean;
  accept?: string;
}

export const Uploader: FC<UploaderProps> = ({
  onChange,
  isPending,
  label = 'Upload',
  accept,
}) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      onChange(e.target.files[0]);
    }
  };

  return (
    <>
      <input
        key={Date.now()}
        accept={accept}
        style={{ display: 'none' }}
        id="contained-button-file"
        multiple
        type="file"
        onChange={handleOnChange}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="contained-button-file">
        <TableToolbarButton
          variant="contained"
          color="primary"
          component="span"
          disabled={isPending}
        >
          {label}
        </TableToolbarButton>
      </label>
    </>
  );
};
