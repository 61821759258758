import styled from 'styled-components';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { Box, Typography } from '@material-ui/core';

export const StyledModalContent = styled(MuiDialogContent)`
  padding: ${({ theme }) => `${theme.spacing(3)}px`};
  display: flex;
  justify-content: space-between;
`;

export const StyledCloseButton = styled(IconButton)`
  padding: 0;
`;

export const StyledManufacturerTitle = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.tertiary};
  font-weight: 700;
`;

export const StyledTitle = styled(Typography)`
  font-weight: 600;
  font-size: 20px;
`;

export const StyledDialogTitleContainer = styled(MuiDialogTitle)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledBoxLeft = styled(Box)`
  flex: 1;
  margin-right: ${({ theme }) => `${theme.spacing(3)}px`};
`;

export const StyledBoxRight = styled(Box)`
  flex: 1;
`;

export const StyledForm = styled.form`
  display: flex;
  justify-content: space-between;
  flex: 1;
`;
