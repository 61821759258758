import * as Yup from 'yup';
import { Auth } from '@caddyshack/common';

const { PasswordRequirements } = Auth;

const passwordMessages = {
  required: 'Password is required',
  repeatRequired: 'Repeat password is required',
  min: 'Password must have at least 8 characters',
  noMatch: "Password doesn't match",
  max: 'Password must have no more than 32 characters',
  upperCase: 'Password must have at least 1 upper case letter',
  lowerCase: 'Password must have at least 1 lower case letter',
  special: 'Password must have at least 1 special character or number',
};

export const resetPasswordFormValidationSchema = Yup.object().shape({
  password: Yup.string()
    .oneOf([Yup.ref('password'), null], passwordMessages.noMatch)
    .min(PasswordRequirements.min, passwordMessages.min)
    .max(PasswordRequirements.max, passwordMessages.max)
    .matches(PasswordRequirements.upperCase, passwordMessages.upperCase)
    .matches(PasswordRequirements.lowerCase, passwordMessages.lowerCase)
    .matches(PasswordRequirements.special, passwordMessages.special)
    .required(passwordMessages.required),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], passwordMessages.noMatch)
    .min(8, passwordMessages.min)
    .required(passwordMessages.repeatRequired),
});
