import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from '@caddyshack/common';

import { AppDispatch } from '../../../app/store';
import {
  exportUserData,
  fetchUsers,
  updateUsersRoles,
} from '../../store/user.actions';
import {
  selectMappedUsers,
  selectIsFetchingUsersPending,
  selectUsersPagination,
  selectUsersFilters,
} from '../../store/user.selector';
import { UsersTable } from '../../components/UsersTable/UsersTable';

export const UsersTableContainer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const users = useSelector(selectMappedUsers);
  const usersPagination = useSelector(selectUsersPagination);
  const usersFilters = useSelector(selectUsersFilters);
  const isPending = useSelector(selectIsFetchingUsersPending);

  useEffect(() => {
    dispatch(
      fetchUsers({
        pageSize: 50,
        page: 0,
      })
    );
  }, [dispatch]);

  const fetchUsersWithQuery = (searchQuery: Auth.UserSearchQuery) => {
    dispatch(
      fetchUsers({
        ...usersFilters,
        ...searchQuery,
      })
    );
  };

  const onUpdateUserRoles = (updateUsersRolesDto: Auth.UpdateUsersRolesDto) =>
    dispatch(updateUsersRoles(updateUsersRolesDto));

  const onExportUserData = (userId: string) => dispatch(exportUserData(userId));

  return (
    <UsersTable
      users={users}
      isPending={isPending}
      pagination={usersPagination}
      sorting={usersFilters?.sort}
      filter={usersFilters?.email}
      onQueryChange={fetchUsersWithQuery}
      onUpdateUsersRoles={onUpdateUserRoles}
      onExportUserData={onExportUserData}
    />
  );
};
