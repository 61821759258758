import { Session } from '@caddyshack/common';
import { AsyncState } from '../../app/models/AsyncState';
import { AsyncStatus } from '../../app/enums/AsyncStatus';
import { IPage } from '../../app/models/Pagination';

export interface SessionState {
  sessions: AsyncState<IPage<Session.SessionDto[], Session.SearchQuery>>;
  deleteSessions: AsyncState<null>;
  exportSessions: AsyncState<null>;
}

export const sessionState: SessionState = {
  sessions: {
    status: AsyncStatus.Void,
    data: {
      content: [],
      pagination: {
        page: 0,
        pageSize: 50,
        totalElements: 0,
      },
      filters: {},
    },
  },
  deleteSessions: {
    status: AsyncStatus.Void,
    data: null,
  },
  exportSessions: {
    status: AsyncStatus.Void,
    data: null,
  },
};
