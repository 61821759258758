import * as Yup from 'yup';

const emailMessages = {
  email: 'Must be a valid email',
  required: 'Email is required',
};

const passwordMessages = {
  required: 'Password is required',
};

export const loginFormValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email(emailMessages.required)
    .max(255)
    .required(emailMessages.required),
  password: Yup.string().max(255).required(passwordMessages.required),
});
