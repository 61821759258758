import React, { FC } from 'react';
import { Box, Container } from '@material-ui/core';

import { StyledPage } from '../../../app/components/DashboardLayout/DashboardLayout.style';
import { ForgotPasswordSuccess } from '../../components/ForgotPasswordSuccess/ForgotPasswordSuccess';

export const ForgotPasswordSuccessView: FC = () => (
  <StyledPage title="Forgot Password Success">
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      <Container maxWidth="sm">
        <ForgotPasswordSuccess />
      </Container>
    </Box>
  </StyledPage>
);
