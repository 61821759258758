import styled from 'styled-components';
import { Card } from '@material-ui/core';

export const StyledCard = styled(Card)`
  padding: ${({ theme }) => `${theme.spacing(3)}px`};
  padding-bottom: 10px;
  flex: 1;
  height: 100%;
`;

export const StyledLink = styled.div`
  cursor: pointer;
`;
