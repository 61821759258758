import styled from 'styled-components';
import { ListItem } from '@material-ui/core';

export const StyledListItem = styled(ListItem)`
  display: flex;
  padding-top: 0;
  padding-bottom: 0;
`;

export const StyledIconContainer = styled.div`
  margin-right: ${({ theme }) => `${theme.spacing(1)}px`};
  display: flex;
`;
