import React, { FC } from 'react';
import { Auth, App } from '@caddyshack/common';
import { Download as DownloadIcon } from 'react-feather';
import { IconButton } from '@material-ui/core';
import { usersColumns } from './UsersTable.columns';
import { Table } from '../../../app/components/Table/Table';
import { TableToolbarButton } from '../../../app/components/Table/Table.style';
import UsersToolbar from '../UsersTableToolbar/UsersToolbar';
import ChangeRolesDialog from '../ChangeRolesDialog/ChangeRolesDialog';

import { StyledCard } from './UsersTable.style';
import { IUserModel } from '../../model/User';
import { TEXT_PRIMARY } from '../../../app/theme/colors';

interface IUsersTable {
  users: Auth.UserDto[];
  isPending: boolean;
  pagination: App.Pagination;
  sorting?: {
    key: string;
    order: App.SortOrder;
  };
  filter?: string;
  onQueryChange: (searchQuery: Auth.UserSearchQuery) => void;
  onUpdateUsersRoles: (updateUsersRolesDto: Auth.UpdateUsersRolesDto) => void;
  onExportUserData: (userId: string) => void;
}

const getSelectedIds = (selectedUsers: IUserModel[]) => {
  return selectedUsers.map((selectedUser: IUserModel) => selectedUser.id);
};

export const UsersTable: FC<IUsersTable> = ({
  users,
  isPending,
  pagination,
  sorting,
  filter,
  onQueryChange,
  onUpdateUsersRoles,
  onExportUserData,
}: IUsersTable) => {
  const [changeRolesDialogOpen, setChangeRolesDialogOpen] = React.useState(
    false
  );

  const handleClickRolesChangeButton = () => {
    setChangeRolesDialogOpen(true);
  };

  const handleDialogClose = (
    selectedUsers: IUserModel[],
    roles?: Auth.Role[]
  ) => {
    setChangeRolesDialogOpen(false);

    if (roles) {
      onUpdateUsersRoles({
        usersIds: getSelectedIds(selectedUsers),
        roles,
      });
    }
  };

  const Toolbar = () => (
    <UsersToolbar
      onSearch={(searchTerm) => onQueryChange({ email: searchTerm, page: 0 })}
      isPending={isPending}
      filter={filter}
    />
  );

  const SelectedToolbar = (selectedUsers: IUserModel[]) => (
    <>
      <TableToolbarButton
        color="primary"
        variant="contained"
        onClick={handleClickRolesChangeButton}
      >
        Change roles
      </TableToolbarButton>
      <ChangeRolesDialog
        id="user-roles"
        keepMounted
        open={changeRolesDialogOpen}
        usersAmount={selectedUsers.length}
        onClose={(roles) => handleDialogClose(selectedUsers, roles)}
        roles={selectedUsers.length === 1 ? selectedUsers[0].roles : []}
      />
    </>
  );

  const actionsCell = {
    Header: 'Actions',
    render: (row: unknown) => {
      const userRow = row as IUserModel;
      return (
        <IconButton onClick={() => onExportUserData(userRow.id)}>
          <DownloadIcon color={TEXT_PRIMARY} />
        </IconButton>
      );
    },
  };

  const tableSorting = sorting
    ? {
        id: sorting.key,
        desc: sorting.order === 'desc',
      }
    : undefined;

  return (
    <StyledCard>
      <Table
        loading={isPending}
        columns={usersColumns}
        data={users}
        pagination={pagination}
        sorting={tableSorting}
        onPaginationChange={onQueryChange}
        onSortChange={(sort) =>
          sort ? onQueryChange({ sort }) : onQueryChange({ sort: undefined })
        }
        Toolbar={Toolbar}
        SelectedToolbar={SelectedToolbar}
        disabledSorting={['Actions']}
        actionsCell={actionsCell}
      />
    </StyledCard>
  );
};
