import React, { FC, useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { Box, Select, MenuItem, IconButton } from '@material-ui/core';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Formik } from 'formik';
import { ModalDetailsBoxForm } from '../../../app/components/Modal/ModalDetailsBoxForm/ModalDetailsBoxForm';
import { headDetailsModalValidation } from './HeadsDetailsModal.validation';

import { Modal } from '../../../app/components/Modal/Modal';
import { ModalDetailsBox } from '../../../app/components/Modal/ModalDetailsBox/ModalDetailsBox';

import {
  StyledModalContent,
  StyledDialogTitleContainer,
  StyledBoxLeft,
  StyledBoxRight,
  StyledManufacturerTitle,
  StyledTitle,
  StyledIconContainer,
  StyledToggleButtonLeft,
  StyledToggleButtonRight,
  StyledForm,
  StyledContainer,
  StyledFormControl,
} from './HeadsDetailsModal.style';
import { StyledTitle as StyledModalDetailsBoxTitle } from '../../../app/components/Modal/ModalDetailsBox/ModalDetailsBox.style';
import { IHeadModel } from '../../model/Head';
import {
  MenuValues,
  HeadLocationsValues,
  HeadLocations,
} from './HeadDetailsModa.constants';

export interface IHeadsDetailsModal {
  onClose: () => void;
  open: boolean;
  head: IHeadModel;
  onEdit: (head: IHeadModel) => void;
}

export const HeadsDetailsModal: FC<IHeadsDetailsModal> = ({
  onClose,
  open,
  head,
  onEdit,
}: IHeadsDetailsModal) => {
  const [menuValue, setMenuValue] = useState<MenuValues>(MenuValues.Details);
  const [headLocation, setHeadLocation] = useState<HeadLocationsValues>(
    HeadLocationsValues.Center
  );
  const [isEditMode, setIsEditMode] = useState(false);
  // eslint-disable-next-line
  const formRef = useRef<any>();
  const handleMenuToggle = (
    event: React.MouseEvent<HTMLElement>,
    value: MenuValues
  ) => {
    if (value) {
      setMenuValue(value);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const handleCancel = () => {
    setIsEditMode(false);
  };

  const handleSave = () => {
    if (formRef.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleFormSubmit = (values: IHeadModel) => {
    onEdit(values);
    setIsEditMode(false);
    onClose();
  };

  const ModalHeader = () => (
    <StyledDialogTitleContainer disableTypography>
      <Box flex="1">
        <StyledManufacturerTitle variant="h6">
          {head?.oem}
        </StyledManufacturerTitle>
        <StyledTitle variant="h4">{head?.stated_head}</StyledTitle>
      </Box>

      {!isEditMode ? (
        <ToggleButtonGroup
          value={menuValue}
          exclusive
          onChange={handleMenuToggle}
          size="small"
          style={{ marginRight: 45 }}
        >
          <StyledToggleButtonLeft value="details">
            Head Details
          </StyledToggleButtonLeft>
          <StyledToggleButtonRight value="robotData">
            Robot Data
          </StyledToggleButtonRight>
        </ToggleButtonGroup>
      ) : null}

      <StyledIconContainer>
        {isEditMode ? (
          <Box>
            <Button
              onClick={handleSave}
              color="primary"
              variant="contained"
              style={{ marginRight: 15 }}
            >
              SAVE
            </Button>

            <Button onClick={handleCancel} color="primary" variant="outlined">
              Cancel
            </Button>
          </Box>
        ) : (
          <Box>
            {menuValue === MenuValues.Details ? (
              <IconButton onClick={handleEdit}>
                <EditIcon />
              </IconButton>
            ) : null}
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </StyledIconContainer>
    </StyledDialogTitleContainer>
  );

  const structureInformation = [
    { label: 'ID', value: head.id, key: 'id', disabled: true },
    {
      label: 'Date Updated',
      value: head.dateUpdated,
      key: 'dateUpdated',
      disabled: true,
    },
  ];

  const shopifyInformation = [
    { label: 'SKU', value: head.number_sku, key: 'number_sku', disabled: true },
    { label: 'Price', value: head.price, key: 'price' },
  ];

  const technicalInformation = [
    {
      label: 'Model',
      value: head.stated_head,
      key: 'stated_head',
      requiredLabel: true,
    },
    {
      label: 'Manufacturer',
      value: head.oem,
      key: 'oem',
      requiredLabel: true,
    },
    { label: 'Type', value: head.type, key: 'type' },
    { label: 'Dexterity', value: head.hand_rh_lh, key: 'hand_rh_lh' },
  ];

  const HeadDetailsContent = () => {
    return (
      <StyledModalContent>
        {isEditMode ? (
          <Formik
            initialValues={head}
            validationSchema={headDetailsModalValidation}
            onSubmit={handleFormSubmit}
            innerRef={formRef}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              ...rest
            }) => (
              <StyledForm onSubmit={handleSubmit} noValidate>
                <StyledBoxLeft>
                  <ModalDetailsBoxForm
                    rows={structureInformation}
                    title="Structure Information"
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    values={values}
                    {...rest}
                  />

                  <ModalDetailsBoxForm
                    rows={shopifyInformation}
                    title="Shopify Information"
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    values={values}
                    {...rest}
                  />
                </StyledBoxLeft>

                <StyledBoxRight>
                  <ModalDetailsBoxForm
                    rows={technicalInformation}
                    title="Technical Information"
                    scrollable
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    values={values}
                    {...rest}
                  />
                </StyledBoxRight>
              </StyledForm>
            )}
          </Formik>
        ) : (
          <>
            <StyledBoxLeft>
              <ModalDetailsBox
                rows={structureInformation}
                title="Structure Information"
              />

              <ModalDetailsBox
                rows={shopifyInformation}
                title="Shopify Information"
              />
            </StyledBoxLeft>

            <StyledBoxRight>
              <ModalDetailsBox
                rows={technicalInformation}
                title="Technical Information"
                scrollable
              />
            </StyledBoxRight>
          </>
        )}
      </StyledModalContent>
    );
  };

  const clubInformation = [
    { label: 'Shaft', value: '' },
    { label: 'Ball Flight', value: '' },
    { label: 'Measured Loft', value: '' },
    { label: 'Hand', value: '' },
    { label: 'Efficiency Factor', value: '' },
    { label: 'Speed MPH', value: '' },
  ];

  const ballInformation = [
    { label: 'Speed MPH', value: '' },
    { label: 'Launch Degree', value: '' },
    { label: 'Total Spin RPM', value: '' },
    { label: 'Side Spin RPM', value: '' },
    { label: 'Side Tilt Angle', value: '' },
  ];

  const distanceInformation = [
    { label: 'Carry Yards', value: '' },
    { label: 'Total Yards', value: '' },
  ];

  const distanceDropOffInformation = [
    { label: 'Carry Yards', value: '' },
    { label: 'Total Yards', value: '' },
  ];

  const centerAtZeroInformation = [
    { label: 'Offline Yards', value: '' },
    { label: 'Offline Net Yards', value: '' },
  ];

  const pinInformation = [{ label: 'Yards', value: '' }];

  const RobotDetailsContent = () => {
    return (
      <StyledModalContent>
        <StyledBoxLeft>
          <StyledContainer elevation={3}>
            <Box flex="1">
              <StyledModalDetailsBoxTitle variant="h6">
                Select Location
              </StyledModalDetailsBoxTitle>
            </Box>

            <StyledFormControl variant="outlined" size="small">
              <Select
                value={headLocation}
                onChange={(event: React.ChangeEvent<{ value: unknown }>) =>
                  setHeadLocation(event.target.value as HeadLocationsValues)
                }
              >
                {HeadLocations.map((location) => (
                  <MenuItem value={location.value}>{location.label}</MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </StyledContainer>

          <ModalDetailsBox rows={clubInformation} title="CLUB INFORMATION" />

          <ModalDetailsBox rows={ballInformation} title="BALL INFORMATION" />
        </StyledBoxLeft>
        <StyledBoxRight>
          <ModalDetailsBox rows={distanceInformation} title="DISTANCE" />
          <ModalDetailsBox
            rows={distanceDropOffInformation}
            title="DISTANCE DROP OFF"
          />
          <ModalDetailsBox
            rows={centerAtZeroInformation}
            title="CENTER AT ZERO"
          />
          <ModalDetailsBox rows={pinInformation} title="TO PIN" />
        </StyledBoxRight>
      </StyledModalContent>
    );
  };

  return (
    <Modal
      Content={
        menuValue === MenuValues.Details
          ? HeadDetailsContent
          : RobotDetailsContent
      }
      Header={ModalHeader}
      onClose={handleClose}
      open={open}
    />
  );
};
