import { Shafts } from '@caddyshack/common';
import { IShaftModel } from '../model/Shaft';

const getSortMappedKey = (key: string) => {
  switch (key) {
    case 'dateUpdated':
      return 'date_updated';
    case 'massG':
      return 'mass_g';
    default:
      return key;
  }
};

export const mapShaftSearchQueryDto = (
  searchQuery: Shafts.SearchQuery
): Shafts.SearchQuery =>
  searchQuery?.sort
    ? {
        ...searchQuery,
        sort: {
          ...searchQuery.sort,
          key: getSortMappedKey(searchQuery.sort.key),
        },
      }
    : searchQuery;

export const mapShaftModelToShaftDto = (
  shaftModel: IShaftModel
): Shafts.ShaftDto => {
  type ShaftsDtoKey = keyof Shafts.ShaftDto;

  return Object.keys(shaftModel).reduce((object, key) => {
    const resultKey: ShaftsDtoKey = getSortMappedKey(key) as ShaftsDtoKey;
    const shaftDtoKey: ShaftsDtoKey = key as ShaftsDtoKey;
    const value = shaftModel[shaftDtoKey];
    if (key !== resultKey) {
      return {
        ...object,
        [resultKey]: value,
      };
    }
    return {
      ...object,
      [key]: value,
    };
  }, {}) as Shafts.ShaftDto;
};
