import React, { FC } from 'react';
import { Typography } from '@material-ui/core';

interface IPageHeader {
  title: string;
}

export const PageHeader: FC<IPageHeader> = ({ title }) => (
  <div>
    <Typography variant="h3">{title}</Typography>
  </div>
);
