import { ShaftConstant, App } from '@caddyshack/common';
import { authApiClient } from '../../app/api/authApi.client';

export const fetchShaftConstant = async (
  searchQuery?: ShaftConstant.SearchQuery
): Promise<App.Page<ShaftConstant.ShaftConstantDto[]>> => {
  try {
    const response = await authApiClient.post(
      '/shaft-recommendation-constant/search',
      searchQuery
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editShaftConstant = async (
  updateShaftConstantDto: ShaftConstant.UpdateShaftConstantDto
): Promise<string> => {
  try {
    const { data } = await authApiClient.put(
      `/shaft-recommendation-constant/${updateShaftConstantDto.id}`,
      updateShaftConstantDto
    );

    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteShaftConstants = async (ids: string[]): Promise<string> => {
  try {
    const stringIds = ids.map((id) => String(id));

    const { data } = await authApiClient.post(
      '/shaft-recommendation-constant/delete',
      {
        ids: stringIds,
      }
    );

    return data;
  } catch (error) {
    throw error;
  }
};
