import { createSlice } from '@reduxjs/toolkit';

import { AsyncStatus } from '../../app/enums/AsyncStatus';
import { userState } from './user.store';
import {
  editUser,
  exportUserData,
  fetchUser,
  fetchUsers,
  updateUsersRoles,
} from './user.actions';

const slice = createSlice({
  name: 'user',
  initialState: userState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => ({
      ...state,
      profile: {
        ...state.profile,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
      if (payload) {
        return {
          ...state,
          profile: {
            status: AsyncStatus.Fulfilled,
            data: payload,
          },
        };
      }

      return state;
    });
    builder.addCase(fetchUser.rejected, (state) => ({
      ...state,
      profile: {
        ...state.profile,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(editUser.pending, (state) => ({
      ...state,
      profile: {
        ...state.profile,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(editUser.fulfilled, (state) => ({
      ...state,
      profile: {
        ...state.profile,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(editUser.rejected, (state) => ({
      ...state,
      profile: {
        ...state.profile,
        status: AsyncStatus.Rejected,
      },
    }));
    builder.addCase(fetchUsers.pending, (state, { meta: { arg } }) => ({
      ...state,
      users: {
        ...state.users,
        status: AsyncStatus.Pending,
        data: {
          ...state.users.data,
          filters: arg,
        },
      },
    }));
    builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
      if (payload) {
        const { pagination, content } = payload;
        return {
          ...state,
          users: {
            status: AsyncStatus.Fulfilled,
            data: {
              ...state.users.data,
              content,
              pagination,
            },
          },
        };
      }

      return state;
    });
    builder.addCase(fetchUsers.rejected, (state) => ({
      ...state,
      users: {
        ...state.users,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(updateUsersRoles.pending, (state) => ({
      ...state,
      users: {
        ...state.users,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(updateUsersRoles.fulfilled, (state) => ({
      ...state,
      users: {
        ...state.users,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(updateUsersRoles.rejected, (state) => ({
      ...state,
      users: {
        ...state.users,
        status: AsyncStatus.Rejected,
      },
    }));

    builder.addCase(exportUserData.pending, (state) => ({
      ...state,
      exportUserData: {
        ...state.exportUserData,
        status: AsyncStatus.Pending,
      },
    }));
    builder.addCase(exportUserData.fulfilled, (state) => ({
      ...state,
      exportUserData: {
        ...state.exportUserData,
        status: AsyncStatus.Fulfilled,
      },
    }));
    builder.addCase(exportUserData.rejected, (state) => ({
      ...state,
      exportUserData: {
        ...state.exportUserData,
        status: AsyncStatus.Rejected,
      },
    }));
  },
});

export default slice.reducer;
