import React, { FC, useState } from 'react';
import { Shafts, App } from '@caddyshack/common';
import ShaftsToolbar from '../ShaftsToolbar/ShaftsToolbar';
import { Table } from '../../../app/components/Table/Table';
import { ShaftsDetailsModal } from '../ShaftsDetailsModal/ShaftsDetailsModal';

import { StyledCard } from './ShaftsTable.style';
import { TableToolbarButton } from '../../../app/components/Table/Table.style';
import { shaftsColumns } from './ShaftsTable.columns';
import { IShaftModel } from '../../model/Shaft';

interface IShaftTable {
  shafts: IShaftModel[];
  isPending: boolean;
  pagination: App.Pagination;
  sorting?: {
    key: string;
    order: App.SortOrder;
  };
  filter?: string;
  onQueryChange: (searchQuery: Shafts.SearchQuery) => void;
  onExport: () => void;
  isExportPending: boolean;
  onDelete: (ids: string[]) => void;
  onEdit: (shaft: IShaftModel) => void;
  onImport: (file: File) => void;
}

export const ShaftsTable: FC<IShaftTable> = ({
  shafts,
  isPending,
  pagination,
  sorting,
  filter,
  onQueryChange,
  onExport,
  isExportPending,
  onDelete,
  onImport,
  onEdit,
}: IShaftTable) => {
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedShaftDetails, setSelectedShaftDetails] = useState(shafts[0]);

  const handleModalOpen = (row: IShaftModel) => {
    setSelectedShaftDetails(row);
    setDetailsModalOpen(true);
  };
  const handleModalClose = () => {
    setDetailsModalOpen(false);
  };

  const Toolbar = () => (
    <ShaftsToolbar
      onSearch={(searchTerm) =>
        onQueryChange({ searchFilter: searchTerm, page: 0 })
      }
      onExport={onExport}
      onImport={onImport}
      isExportPending={isExportPending}
      isPending={isPending}
      filter={filter}
    />
  );

  const SelectedToolbar = (selectedShafts: IShaftModel[]) => (
    <TableToolbarButton
      color="primary"
      disabled={isPending}
      variant="contained"
      onClick={() =>
        onDelete(
          selectedShafts.map((selectedShaft: IShaftModel) => selectedShaft.id)
        )
      }
    >
      {`Delete ${selectedShafts.length} ${
        selectedShafts.length === 1 ? 'shaft' : 'shafts'
      }`}
    </TableToolbarButton>
  );

  const tableSorting = sorting
    ? {
        id: sorting.key,
        desc: sorting.order === 'desc',
      }
    : undefined;

  return (
    <StyledCard>
      <Table
        loading={isPending}
        columns={shaftsColumns}
        data={shafts}
        pagination={pagination}
        sorting={tableSorting}
        onPaginationChange={onQueryChange}
        onSortChange={(sort) =>
          sort ? onQueryChange({ sort }) : onQueryChange({ sort: undefined })
        }
        Toolbar={Toolbar}
        SelectedToolbar={SelectedToolbar}
        disabledSorting={['Actions']}
        onRowClick={(row: IShaftModel) => handleModalOpen(row)}
      />
      {selectedShaftDetails && (
        <ShaftsDetailsModal
          onClose={handleModalClose}
          open={detailsModalOpen}
          shaft={selectedShaftDetails}
          onEdit={onEdit}
        />
      )}
    </StyledCard>
  );
};
